import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import utils from "../../utils/index";

export default class DeactivateStaff extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      isLoadingBtn: false,

      oldPlant: '',
      newPlant: "",
      showPopUp: false,
    }
  }


  handlClose() {
    let { callBack } = this.props
    callBack(false)
  }

  async deactivate(id, activationType) {
    let { callBack2 } = this.props
    try {
      let object = {
        staffId: id
      }
      let response
      activationType === utils.config.activeAppUser.DEACTIVATE ?
        response = await apiCall.deactivateAppUser(object)
        :
        response = await apiCall.activateAppUser(object)
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        if (response.data.status) {
          this.setState({ isLoadingBtn: false, })
          activationType === utils.config.activeAppUser.DEACTIVATE ?
            toast.success("App User is Deactivated")
            :
            toast.success("App User is Activated")
          callBack2()
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isLoadingBtn: false, })
        }
      }
      else {
        this.setState({
          isLoadingBtn: false,
        })
      }
    }
    catch (e) {
      this.setState({
        isLoadingBtn: false,
      })
      utils.utilFunction.toastCall(e.response)
    }

  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />

    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton
            className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" >
              {
                this.props.activationType === utils.config.activeAppUser.DEACTIVATE ?
                  <p className="forParagraph">Deactivate Staff </p>
                  :
                  <p className="forParagraph">Activate Staff</p>
              }

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>
                <Form.Group as={Row} controlId="formHorizontalImages">
                  <Col md={'12'}>

                    {
                      this.props.activationType === utils.config.activeAppUser.DEACTIVATE ?
                        <h5 className="messageLine">Are you sure you want to deactivate <b style={{ textTransform: "capitalize" }}>{this.props.item.FirstName}</b>?</h5>
                        :
                        <h5 className="messageLine" >Are you sure you want to activate <b style={{ textTransform: "capitalize" }}>{this.props.item.FirstName}</b>?</h5>
                    }

                  </Col>
                </Form.Group>


                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={
                      this.props.activationType === utils.config.activeAppUser.DEACTIVATE ?
                        "Deactivate"
                        :
                        "Activate"
                    }
                    isGradient={true}
                    onBtnClick={() => {
                      this.setState({ isLoadingBtn: true, })
                      if (!this.state.isLoadingBtn) {
                        this.deactivate(this.props.item.Staff_id, this.props.activationType)
                      }
                    }
                    }
                  />
                </div>

              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}