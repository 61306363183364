import React, { Component } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import utils from "../../utils/index";
import './blockAdmin.css';

export default class blockadmin extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      isLoadingBtn: false,
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
    }
  }

  handleClose() {
    let { callBack } = this.props
    callBack(false)
  }

  async blockSubAdmin(item) {
    try {
      let object = {
        adminId: item.Admin_id
      }
      let response = await apiCall.block(object);
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        if (response.data.status) {
          this.handleClose()
          toast.success(utils.strings.successMsg.adminBlocked)
          this.setState({ isLoadingBtn: false })
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem(utils.strings.localStorageItem.token)
          this.setState({ isLoadingBtn: false })
        }
      }
      else {
        this.setState({ isLoadingBtn: false })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
      this.setState({ isLoadingBtn: false })
    }
  }

  async unBlockSubAdmin(item) {
    try {
      let object = {
        adminId: item.Admin_id
      }
      let response = await apiCall.unBlock(object);
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        if (response.data.status) {
          this.handleClose()
          toast.success(utils.strings.successMsg.adminUnblocked)
          this.setState({ isLoadingBtn: false })
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem(utils.strings.localStorageItem.token)
          this.setState({ isLoadingBtn: false })
        }
      }
      else {
        this.setState({ isLoadingBtn: false })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
      this.setState({ isLoadingBtn: false })
    }
  }

  render() {
    let item = this.props.item
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" >
              {
                this.props.type === "BLOCK" ?
                  < p className="forParagraph">{utils.strings.adminString.blockAdmin.blockSubAdmin}</p>
                  :
                  <p className="forParagraph">{utils.strings.adminString.blockAdmin.unblockSubAdmin}</p>
              }
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {
              this.props.type === "BLOCK" ?
                <h5 className="messageLine">{utils.strings.adminString.blockAdmin.blockDes}<b style={{ textTransform: "capitalize" }}>&nbsp;{item.Username}</b>?</h5>
                :
                <h5 className="messageLine">{utils.strings.adminString.blockAdmin.unBlockDes}<b style={{ textTransform: "capitalize" }}>&nbsp;{item.Username}</b>?</h5>
            }
            <Container>
              <div className='d-flex justify-content-end'>
                <myComponent.HeroButton
                  isBtnLoading={this.state.isLoadingBtn}
                  btnText={this.props.type === "BLOCK" ? utils.strings.buttonText.block : "UNBLOCK"}
                  isGradient={true}
                  onBtnClick={() => {
                    this.setState({ isLoadingBtn: true })
                    if (!this.state.isLoadingBtn) {
                      if (this.props.type === "BLOCK")
                        this.blockSubAdmin(item)
                      else
                        this.unBlockSubAdmin(item)
                    }
                  }}
                />
              </div>
            </Container>
          </Modal.Body>
        </Modal >
      </>
    );
  }
}