import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import "../contentStyle.css";
import "../globalStyle.css";
import utils from "../utils/index";
import './pageNotFound.css';

export default class pageNotFound extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        let type = 1;
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            type = 1
        }
        else {
            type = 2
        }
        return (
            <div className='flex-full main-bg'>
                <Image className='bg-image' src={utils.images.PNF} />
                <div className='content-adjust'>
                    <div className='heading-block'>
                        <h1 className='_404-style'>404</h1>
                    </div>
                    <div className='heading-block'>
                        <h3 className='heading-font' >Opps! Page not found</h3>
                    </div>

                    <div className='reason'>
                        <div>
                            <i>Why this happen?</i>
                        </div>
                        <div>
                            <i>This might be because the server is not running or you may have entered an incorrect url</i>
                        </div>
                    </div>

                    <div className='main-navigation-container'>
                        <div className='vertical-dap1' />
                        <h5>Navigate to:</h5>

                        <div className='navigatin-container'>
                            <div className='vertical-dap3' />
                            {
                                type === 1 ?
                                    <div>
                                        <a href="#/">Login</a>
                                    </div>
                                    :
                                    <div>
                                        <div><a href="#/dashboard">Dashboard</a></div>
                                        <div className='vertical-dap4' />
                                        {
                                            localStorage.getItem("adminType") === "SuperAdmin" ?
                                                <>
                                                    <div><a href="#/admin">Admin</a></div>
                                                    <div className='vertical-dap4' />
                                                </>
                                                :
                                                <div />
                                        }
                                        <div><a href="#/manage-employees">Manage Employees</a></div>
                                        <div className='vertical-dap4' />
                                        {/* <div><a href="#/shift-management">Shift Management</a></div>
                                        <div className='vertical-dap4' /> */}
                                        <div><a href="#/manage-app-users">Manage App Users</a></div>
                                        <div className='vertical-dap4' />
                                        <div><a href="#/manage-plant">Manage Plant</a></div>
                                        <div className='vertical-dap4' />
                                        <div><a href="#/manage-shift">Manage Shift</a></div>
                                        <div className='vertical-dap4' />
                                        <div><a href="#/report/department-wise">Reports</a></div>
                                        <div className='vertical-dap4' />
                                        <div><a href="#/profile">Profile</a></div>

                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}