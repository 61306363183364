
const adminType = {
    superAdmin: "SuperAdmin",
    subAdmin: "SubAdmin",
}

const loginStrings = {
    forgotPassword: "Forgot Password?",
}

const forgotPasswordSrings = {
    des: "An OTP will be sent on the provided email",
    forgotPassword: "Forgot password",
}

const dashboardString = {
    emps: "Employess",
    today: "Today:",
    attendanceOverview: "Attendance Overview",
    deptCircularBarColor: {
        large: "green",
        medium: "yellow",
        small: "red",
        default: "black",
        largeX: "#3E98C7",
        textColor: "#07344D",
        trailColor: '#d6d6d6',
        backgroundColor: '#3e98c7',
    },
    deptCircularBarValue: {
        large: 90,
        medium: 70,
        small: 50,
    },

}

const adminString = {
    createSubAdmin: "Create Sub Admin",
    userName: "User Name",
    blockAdmin: {
        blockSubAdmin: "Block Sub Admin",
        unblockSubAdmin: "Unblock Sub Admin",
        blockDes: "Are you sure you want to block",
        unBlockDes: "Are you sure you want to unblock",
    },
    editAdmin: {
        editAdmin: "Edit Admin",
    }
}

const reportString = {
    deptWise: "DEPARTMENT WISE",
    secWise: "SECTION WISE",
    plantWise: "PLANT WISE",
    shiftView: "Shift View",
    catView: "Category View",
    selectDateRange: "Select Date Range:",
}

const standaredCategories = {
    plant: "Plant",
    plantS: "Plants",

    employee: "Employee",
    employeeS: "Employees",

    staff: "Staff",
    staffS: "Staffs",

    appUser: "App User",
    appUserS: "App Users",

    department: "Department",
    departmentS: "Departments",

    extraHour: "Extra Hour",
    extraHourS: "Extra Hours",

    category: "Category",
    CategoryS: "Categories",

    section: "Section",
    sectionS: "Sections",

    status: "Status",

}

const inputLabel = {
    userName: "User Name",
    email: "Email",
    adminType: "Admin Type",
}

const inputFeild = {
    empCode: "Emp Code",
    empName: "Employee Name",
    gender: "Gender",
    selectGender: "Select Gender",
    designation: "Designation",
    selectDesignation: "Select Designation",
    category: "Category",
    selectCategory: "Select Category*",
    plantName: "Plant Name",
    emailId: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    OTP: "OTP",
    userName: "User Name",
}

const errorMsg = {
    uniqueUser: "User already exist",
    appUserUniqueUser: "There is an app user exists with same Email id or Mobile number or Employee code",
    nameError: "Please enter user name",
    phnoError: "Please enter phone number",
    phnoError2: "Phone number lenght must be less than 11 characters",
    passwdError: "Please enter password",
    conpasswdError: "Please confirm password",
    conpasswdError2: "Confirm password doesn't match with the password",
    emailError: "Please enter email",
    emailError2: "Email is invalid",
    passCurrIncorrect: "Incorrect current password",
    noUser: "No user found with email ",
    xlsxInvalid: "Please choose a valid xlsx or xls file",
    selectFile: "Select a file",
    selectValImgFile: "Select a valid image file",
    noDataFound: "No Data Found",
    userBlokedLogin: "No such user found. Kindly enter correct username and password or contact SANSERA admin",
    otpError: "Please enter the OTP",
    otpInvalid: "Invalid OTP",
    proceed: "Proceed",
    selectPlant: "Select Plant",
}

const successMsg = {
    chgSaved: "your change is successfully saved",
    passChange: "Password changed successfully",
    adminUnblocked: "Admin is unblocked",
    adminBlocked: "Admin is blocked",
}

const toastMsg = {
    roleChanged: "Role is changed"
}

const stringMiscellaneous = {
    typeSignedIn: "Signed in as: ",
    hi: "Hi, ",
    noNet: "No Internet Connection",
    filterBy: "Filter By:",
    noData: "No Data",
    name: "Name",
}

const signUpOption = {
    profile: "Profile",
    logout: "Logout",
}

const sessionMsg = {
    sesExpired: "Session expired"
}

const heading = {
    shiftManagement: "Shift Management",
    mangageEmployees: "Mangage Employees",
    manageAppUsers: "Manage App Users",
    reports: "Reports",
    profile: "Profile",
    admin: "Admin",
    dashboard: "Dashboard",
    managePlant: "Manage Plants",
    manageShift: "Manage Shifts"
}

const buttonText = {
    uploadEmpShift: "Upload Employee Shift",
    expXlsx: "Export to Xlsx",
    addAppUser: "Add App User",
    appUsers: "App Users",
    createSubAdmin: "Create SubAdmin",
    login: "Login",
    changePassword: "Change Password",
    reLogin: "Re-Login",
    uploadImage: "Upload Image",
    save: "Save",
    block: "Block",
}

const localStorageItem = {
    token: "token",
    adminType: "adminType",
    adminId: "adminId",
    emailId: "emailId",
    isActive: "isActive",
    lastLoggedIn: "lastLoggedIn",
    plantId: "plantId",
    userName: "userName",
    status: "status",
}

const reLoginPopUpString = {
    permissionChanged: "Permission Changed",
    contStr1: "You have been moved from",
    contStr2: "to",
    line2: "Please re-login to proceed",
}

const strings = {

    adminString,
    reLoginPopUpString,
    errorMsg,
    toastMsg,
    stringMiscellaneous,
    signUpOption,
    sessionMsg,
    heading,
    buttonText,
    standaredCategories,
    dashboardString,
    inputLabel,
    inputFeild,
    successMsg,
    localStorageItem,
    loginStrings,
    adminType,
    forgotPasswordSrings,
    reportString,
}



export default strings;
