import React, { Component } from 'react';
import { Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import apiCall from "../../apiCall";
import validation from '../../common/validation';
import myComponent from "../../components/index";
import "../../globalStyle.css";
import utils from "../../utils/index";
import './createSubAdmin.css';

export default class CreateSubAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subadminname: '',
      password: '',
      email: '',
      plantname: '',
      uploadpic: '',
      errorMsg: {
        subadminname: '',
        email: '',
        password: '',
        uploadpic: '',
        plantname: '',
      },
      plantDataList: [],
      selectedPlant: "null",
      isLoadingBtn: false,
      plantError: '',
      selectedOption: "",
      profilePic: utils.images.placeholderImage,
      profilePicActualImage: "",
      profilePicError: "",

      showPopUp: false,
      newPlant: "",
      oldPlant: "",
    }

  }

  componentDidMount() {
    this.getPlantList()
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;
        plantListOG = response.data.data
        plantList = this.state.plantDataList
        i = 0

        for (i; i < plantListOG.length; i++) {
          plantList.push(
            {
              // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
              // 'value': i,
              // 'Plant_Id': plantListOG[i].Plant_Id,
              // 'Plant_Location': plantListOG[i].Plant_Location,
              // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

              'label': plantListOG[i].Name,
              'value':i,
              'Plant_Id': plantListOG[i].BRCCODE,

            })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  updateName(subadminname) {
    let message = validation.validateName(this.state.errorMsg, subadminname.target.value)
    this.setState({ subadminname: subadminname.target.value, message })
  }

  updateEmail(email) {
    let message = validation.validateEmail(this.state.errorMsg, email.target.value)
    this.setState({ email: email.target.value, message })
  }

  updatePassword(password) {
    let message = validation.validatePassword(this.state.errorMsg, password.target.value)
    this.setState({ password: password.target.value, message });
  }

  validateForm() {
    let nameValidate = validation.validateName(this.state.errorMsg, this.state.subadminname)
    let emailValidate = validation.validateEmail(this.state.errorMsg, this.state.email)
    let passwordValidate = validation.validatePassword(this.state.errorMsg, this.state.password)
    let plantValidate;

    if (!this.state.selectedOption || this.state.selectedOption === "") {
      this.setState({
        plantError: utils.strings.errorMsg.selectPlant
      })
      plantValidate = false
    }
    else {
      this.setState({ plantError: "" })
      plantValidate = true
    }

    if (nameValidate && emailValidate && passwordValidate && plantValidate) {
      return true;
    }
    else {
      this.setState({ errorMsg: this.state.errorMsg })
      return false;
    }
  }

  async createSubadmin() {
    let selectedOptionData = this.state.selectedOption
    let object = {
      userName: this.state.subadminname,
      password: this.state.password,
      emailId: this.state.email,
      plantId: selectedOptionData.Plant_Id
    }
    let formData = new FormData();
    let photo = this.state.profilePicActualImage
    let response
    if (photo !== "" && photo !== undefined && photo !== null) {
      formData.append('image', photo, photo.name, photo.type)
    }
    formData.append('data', JSON.stringify(object))

    try {
      response = await apiCall.addSubAdmin2(formData);
      let { callBack2 } = this.props
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }

      else if (response.data) {
        if (response.data.status) {
          this.setState({ isLoadingBtn: false })
          this.handleClose()
          callBack2()
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem(utils.strings.localStorageItem.token)
          this.setState({ isLoadingBtn: false })
        }
      }
      else {
        this.setState({ isLoadingBtn: false })
        if (response.error_code === 409) {
          utils.utilFunction.toastCall(utils.strings.errorMsg.uniqueUser)
        }
        else {
          utils.utilFunction.toastCall(response.error_message)
        }
      }
    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e)
    }
  }

  handleSubmit(event) {
    if (this.validateForm()) {
      this.createSubadmin()
    }
    else {
      this.setState({ isLoadingBtn: false })
    }
  }

  reset() {
    this.setState({
      profilePic: "",
      profilePicActualImage: "",
      profilePicError: "",
      subadminname: '',
      password: '',
      email: '',
      plantname: '',
      errorMsg: {
        subadminname: '',
        email: '',
        password: '',
        uploadpic: '',
        plantname: '',
      },
      plantError: '',
      selectedOption: "",
    })
  }

  handleClose() {
    let { callBack } = this.props
    this.reset()
    callBack(false)
  }

  selectImage() {
    this.refs.fileUploader.click();
  }

  onImageChange(event) {
    let imageData = event.target.files
    if (imageData && imageData[0]) {
      if (utils.utilFunction.validatePhoto(imageData[0].name)) {
        let reader = new FileReader();
        this.setState({
          profilePicError: '',
          profilePicActualImage: event.target.files[0]
        })

        reader.onload = (e) => {
          this.setState({
            profilePic: e.target.result
          })
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      else {
        this.setState({
          profilePicError: 'Select a valid image file',
          profilePic: "",
        })
      }
    }
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token)) {
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    }
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => this.handleClose()} className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" >
              <p className="forParagraph">{utils.strings.adminString.createSubAdmin}</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>

              <div className="horizontal-center">
                <Image
                  src={this.state.profilePic ? this.state.profilePic : utils.images.placeholderImage}
                  className='image-placeHolder'
                  roundedCircle />
              </div>
              <div className='vertical-dap4' />
              <div className="horizontal-center">
                <div className="pointer" onClick={() => this.selectImage()}>
                  {utils.strings.buttonText.uploadImage}
                </div>
              </div>
              <div className="horizontal-center">
                <div className='error-style'>{this.state.profilePicError}</div>
              </div>
              <input
                type="file" id="file" ref="fileUploader"
                onChange={(event) => this.onImageChange(event)}
                style={{ display: "none" }}
              />

              <Form >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Col>
                    <Form.Label>{utils.strings.inputFeild.userName}</Form.Label>
                    <Form.Control
                      id={"t_name"}
                      type="text" name="subadminname" placeholder="Enter  Username" value={this.state.subadminname}
                      onChange={(e) => this.updateName(e)} />
                    <div className='error-style'>{this.state.errorMsg.subadminname}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalName">
                  <Col>
                    <Form.Label>{utils.strings.inputFeild.password}</Form.Label>
                    <Form.Control
                      id={"t_password"}
                      type="password" name="password" placeholder="Enter password" value={this.state.password}
                      onChange={(e) => this.updatePassword(e)} />
                    <div className='error-style'>{this.state.errorMsg.password}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formGridState">
                  <Col>
                    <Form.Label>{utils.strings.inputFeild.emailId}</Form.Label>
                    <Form.Control id={"t_email"} type="text" name="email" placeholder="Enter email address" value={this.state.email}
                      onChange={(e) => this.updateEmail(e)} />
                    <div className='error-style'>{this.state.errorMsg.email}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalMobile">
                  <Col>
                    <Form.Label>Admin Type</Form.Label>
                    <Form.Control type="text" placeholder="Sub Admin" className="notchange"
                      disabled
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalImages">
                  <Col>
                    <Form.Label>Plant Name</Form.Label>
                    <Select
                      id={"t_dropdown"}
                      placeholder="Select plant name"
                      onChange={
                        (selectedOption) => {
                          this.setState({
                            plantError: !selectedOption || selectedOption === "" ? "Select Plant" : "",
                            selectedOption
                          })
                        }
                      }
                      options={this.state.plantDataList}
                    />
                    <div className='error-style'>{this.state.plantError}</div>
                  </Col>
                </Form.Group>

                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    id={"create-sub-admin"}
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={"Create Sub Admin"}
                    isGradient={true}
                    onBtnClick={(event) => {
                      this.setState({ isLoadingBtn: true, })
                      if (!this.state.isLoadingBtn) {
                        this.handleSubmit(event)
                      }
                    }}
                  />
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}