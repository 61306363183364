import axios from 'axios';
import utils from "./utils/index";

const axiosInstance = axios.create({
    baseURL: utils.config.baseURL+"/"+ utils.config.apiPath,
    headers: {
        'authToken': localStorage.getItem('token'),
    },
})



async function login(body) {
    try {
        return await axiosInstance.post('admin/login', body)
    }
    catch (e) {
        return e.response.data;
    }
}

async function logout() {
    try {
        return await axiosInstance.get('admin/logout',{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function adminList() {
    try {
        return await axiosInstance.get('admin/list',{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function addSubAdmin(data) {
    try {
        return await axiosInstance.post('admin/add', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function addSubAdmin2(data) {
    try {
        return await axiosInstance.post('admin/addwithpic', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getPlantList() {
    try {
        // let v1 = await axiosInstance.get('admin/plant/list')
        let v2 = await axiosInstance.get('admin/plant/listV2',{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}


async function changePlant(data) {
    try {
        return await axiosInstance.put('admin/plant/change', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getAppUser(data) {
    try {
        return await axiosInstance.post('admin/appuser/list', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getRole() {
    try {
        return await axiosInstance.get('admin/roles',{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getDepartment() {
    try {
        // let v1 = await axiosInstance.get('admin/department/list')
        let v2 = await axiosInstance.get('admin/department/listV2',{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}


async function getShift(data) {
    try {
        // let v1 = await axiosInstance.post('admin/shift/list', {plantId:1003})
        let v2 = await axiosInstance.post('admin/shift/listV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getSection() {
    try {
        // let v1 = await axiosInstance.get('admin/section/list')
        let v2 = await axiosInstance.get('admin/section/listV2',{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getCategory(data) {
    try {
        // let v1 = await axiosInstance.get('admin/category/list')
        let v2 = await axiosInstance.get('admin/category/listV2',{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}



async function addAppUser(data) {
    try {
        return await axiosInstance.post('admin/appuser/add', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function addAppUser2(data) {
    try {
        return await axiosInstance.post('admin/appuser/addwithpic', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function changeRole(data) {
    try {
        return await axiosInstance.put('admin/appuser/changerole', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function updateAdmin(data) {
    try {
        return await axiosInstance.put('admin/update', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}


async function block(data) {
    try {
        return await axiosInstance.put('admin/block', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function unBlock(data) {
    try {
        return await axiosInstance.put('admin/reactivate', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function resetPassword(data) {
    try {
        return await axiosInstance.put('admin/updateprofilepassword', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function resetSubAdminPassword(data) {
    try {
        return await axiosInstance.put('admin/updatepassword', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function apiforProfile() {
    try {
        return await axiosInstance.get('admin/profile',{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function deactivateAppUser(data) {
    try {
        return await axiosInstance.put('admin/appuser/deactivate', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }

}

async function editappUser(data) {
    try {
        return await axiosInstance.put('admin/appuser/update', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }

}

async function activateAppUser(data) {
    try {
        return await axiosInstance.put('admin/appuser/reactivate', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function uploadAppUser(data) {
    try {
        return await axiosInstance.post('admin/appuser/upload', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function employeeList(data) {
    try {
        // let v1 = await axiosInstance.post('admin/employee/list', data)
        let v2 = await axiosInstance.post('admin/employee/listV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function activateEmployee(data) {
    try {
        return await axiosInstance.put('admin/employee/reactivate', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function deactivateEmployee(data) {
    try {
        return await axiosInstance.put('admin/employee/deactivate', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function addEmployee(data) {
    try {
        return await axiosInstance.post('admin/employee/add', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}


async function uploadEmployee(data) {
    try {
        return await axiosInstance.post('admin/employee/upload', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function uploadEmployeeExtraHour(data) {
    try {
        return await axiosInstance.post('admin/employee/eh/upload', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}


async function getDesignation() {
    try {
        // let v1 = await axiosInstance.get('admin/designation/list')
        let v2 = await axiosInstance.get('admin/designation/listV2',{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}


async function shiftList(data) {
    try {
        let v1 = await axiosInstance.post('admin/employee/shift/list', {"plantId":1003},{ headers: { 'authToken': localStorage.getItem('token')}})
        // let v2 = await axiosInstance.post('admin/employee/shift/listV2', {"plantId":1003})
        return v1
    }
    catch (e) {
        return e.response.data;
    }
}
async function shiftUpload(data) {
    try {
        return await axiosInstance.post('admin/employee/shift/upload', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}


async function updateEmployee(data) {
    try {
        return await axiosInstance.put('admin/employee/edit', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function exportEmployee(data) {
    try {
        // let v1 = await axiosInstance.get('admin/employee/export' + data, { responseType: 'arraybuffer' })
        let v2 = await axiosInstance.get('admin/employee/exportV2' + data, { responseType: 'arraybuffer', headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getOTP(data) {
    try {
        return await axiosInstance.post('admin/forgetpassword/request', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function chnagePassword(data) {
    try {
        return await axiosInstance.post('admin/forgetpassword/finish', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getDepartmentWiseList(data) {
    try {
        // let v1 = await axiosInstance.post('admin/reports/departmentwise', data)
        let v2 = await axiosInstance.post('admin/reports/departmentwiseV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getSectionWiseList(data) {
    try {
        // let v1 = await axiosInstance.post('admin/reports/sectionwise', data)
        let v2 = await axiosInstance.post('admin/reports/sectionwiseV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getPlantWiseList(data) {
    try {
        // let v1 = await axiosInstance.post('admin/reports/plantWise', data)
        let v2 = await axiosInstance.post('admin/reports/plantwiseV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2

    }
    catch (e) {
        return e.response.data;
    }
}

async function getDashboardCountList() {
    try {
        // let v1 = await axiosInstance.get('admin/dashboard/counts')
        let v2 = await axiosInstance.get('admin/dashboard/countsV2',{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getDashboardDeptWise(data) {
    try {
        // let v1 = await axiosInstance.post('admin/dashboard/departmentwise', data)
        let v2 = await axiosInstance.post('admin/dashboard/departmentwiseV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function getDashboardAttendence(data) {
    try {
        // let v1 = await axiosInstance.post('admin/dashboard/attendanceoverview', data)
        let v2 = await axiosInstance.post('admin/dashboard/attendanceoverviewV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
        return v2
    }
    catch (e) {
        return e.response.data;
    }
}

async function updateAppUserProfilePic(data) {
    try {
        return await axiosInstance.post('admin/appuser/updatepic', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function updateAdminProfilePic(data) {
    try {
        return await axiosInstance.post('admin/updateprofilepic', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getAppUserAppLog(data) {
    try {
        return await axiosInstance.post('admin/appuser/log/list', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function editPlantAddress(data) {
    try {
        return await axiosInstance.put('admin/plant/addressUpdateV2', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function editShiftShortName(data) {
    try {
        return await axiosInstance.post('admin/shift/shortname/update', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function getShiftList(data) {
    try {
        return await axiosInstance.get('admin/shift/listV3',{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

async function updateShiftListForPlant(data) {
    try {
        return await axiosInstance.post('admin/plant/shift/list', data,{ headers: { 'authToken': localStorage.getItem('token')}})
    }
    catch (e) {
        return e.response.data;
    }
}

const apiCall = ({
    login,
    logout,
    adminList,
    addSubAdmin,
    addSubAdmin2,
    getPlantList,
    changePlant,
    getAppUser,
    getRole,
    getDepartment,
    addAppUser,
    addAppUser2,
    addEmployee,
    changeRole,
    updateAdmin,
    block,
    resetPassword,
    resetSubAdminPassword,
    unBlock,
    apiforProfile,
    editappUser,
    deactivateAppUser,
    activateAppUser,
    getShift,
    getSection,
    uploadAppUser,
    employeeList,
    activateEmployee,
    getCategory,
    getDesignation,
    deactivateEmployee,
    uploadEmployee,
    uploadEmployeeExtraHour,
    shiftList,
    shiftUpload,
    updateEmployee,
    exportEmployee,
    getOTP,
    chnagePassword,
    getDepartmentWiseList,
    getSectionWiseList,
    getPlantWiseList,
    getDashboardCountList,
    getDashboardDeptWise,
    getDashboardAttendence,
    updateAppUserProfilePic,
    updateAdminProfilePic,
    getAppUserAppLog,
    editPlantAddress,
    editShiftShortName,
    getShiftList,
    updateShiftListForPlant
});
export default apiCall;