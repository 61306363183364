const gender = [
    { label: 'Male', value: "Male" },
    { label: 'Female', value: "Female" },
    // { label: 'Any', value: "A" },
]

const optionStatus = [
    { value: '1', label: 'Active' },
    { value: '0', label: 'InActive' },
]

const constants = {
    gender,
    optionStatus,
}

export default constants;