import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import apiCall from "../../apiCall";
import myComponent from '../../components';
import utils from "../../utils";
import ErrorPopUp from '../appUserErrorPopUp/errorPopup';
import "./uploadappuser.css";
import {toast} from "react-toastify";

export default class uploadAppUser extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      isLoadingBtn: false,
      selectedFile: '',
      messageModal: false,
      addAppUserError: null,
      fileError: '',
      allRight: false,
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
    }
  }

  validate(e) {
    if (e.target.files[0] !== undefined) {
      if (utils.utilFunction.getFileExtension(e) === "xlsx" || utils.utilFunction.getFileExtension(e) === "xls") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedFileOG: e,
          allRight: true,
          fileError: '',
        })
      }
      else {
        this.setState({
          fileError: utils.strings.errorMsg.xlsxInvalid,
          allRight: false,
        })
      }
    }
    else {
      this.setState({
        allRight: false,
      })
    }
  }

  async uploadAddAppUserFile() {
    try {
      let formData = new FormData();
      formData.append(
        "file",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      let response = await apiCall.uploadAppUser(formData);
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {

        if (response.data.status) {
          this.setState({ isLoadingBtn: false })
          toast.success("App user uploaded")
          let { callBack2 } = this.props
          callBack2()
        }
        if (!response.data.status) {
          this.setState({ isLoadingBtn: false, addAppUserError: response.data })
          this.handleErrorPopUpModal()
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isLoadingBtn: false })
        }
      }
      else {
        this.setState({ isLoadingBtn: false, addAppUserError: response })
        this.handleErrorPopUpModal()
      }
    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e.response)
    }
  }

  handleErrorPopUpModal() {this.setState({ messageModal: true })}

  handleErrorPopUpClose() { this.setState({ messageModal: false }) }

  reset() {
    this.setState({
      fileError: "",
      selectedFile: '',
    })
  }


  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          centered
          size="lg"
        >
          <Modal.Header closeButton
                        onHide={() => this.reset()}
                        className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "center" }}>
              <p className="forParagraph">Upload App User(s) </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  >
            <Container >
              <Form>
                <Form.Group as={Row} controlId="formHorizontalImages">
                  <Col sm={12}>
                    <h5 >Points to consider before uploading.</h5>
                    <div style={{ color: "black" }}>1. Importing file format should be one of <b style={{ fontWeight: "600" }}> XLSX</b> or <b style={{ fontWeight: "600" }}>XLS</b> </div>
                    <div style={{ color: "black" }}>2. Click <a href={utils.config.uploadAddAppUserFileTempletLink} >here</a> to get template/ format for upload file </div>
                    <div style={{ color: "black" }}>3. Mandatory columns are <b style={{ fontWeight: "600"}}>employee_code, first_name, phone_number, role_name </b></div>
                    <div style={{ color: "black" }}>a. If role_name is <b style={{ fontWeight: "600" }}>Admin</b> then plant_name, department_name, section_name, shift_name column values are not required </div>
                    <div style={{ color: "black" }}>b. If role_name is <b style={{ fontWeight: "600"}}>Plant-Head</b> then <b style={{ fontWeight: "600"  }}>plant_name</b> is required and department_name, section_name, shift_name column values are not required </div>
                    <div style={{ color: "black" }}>c. If role_name is <b style={{ fontWeight: "600" }}>Department-Head</b> then <b style={{ fontWeight: "600" }}>plant_name,department_name</b> is required and section_name, shift_name column values are not required </div>
                    <div style={{ color: "black" }}>d. If role_name is <b style={{ fontWeight: "600"  }}>ShiftIncharge</b> then <b style={{ fontWeight: "600"  }}>plant_name, department_name, section_name, shift_name</b> is required </div>
                    <div style={{ color: "black" }}>e. If app user already exists then the system will update that particular record </div>
                  </Col>
                  <Col>
                    <div style={{ marginTop: '10px' }}>
                      <h9 style={{ textAlign: "left", color: "black" }}>File&nbsp;<FontAwesomeIcon icon={faCloudUploadAlt} /></h9>
                    </div>

                    <input type="file" id="file" ref="fileUploader"
                      onChange={(event) => {
                        this.validate(event)
                      }}
                    />
                  </Col>
                </Form.Group>
                <div className='error-style'>{this.state.fileError}</div>

                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={"Upload"}
                    isGradient={true}
                    onBtnClick={() => {
                      if (this.state.allRight) {
                        this.setState({
                          isLoadingBtn: true
                        })
                        if (!this.state.isLoadingBtn) {
                          this.uploadAddAppUserFile()
                        }
                      }
                      else {
                        this.setState({
                          isLoadingBtn: false,
                          fileError: utils.strings.errorMsg.selectFile,
                        })
                      }
                    }}
                  />
                </div>
              </Form>
            </Container>
          </Modal.Body>

          <ErrorPopUp
            show={this.state.messageModal}
            onHide={()=> this.handleErrorPopUpClose()}
            item={this.state.addAppUserError}
          />
        </Modal>
      </>

    )
  }
}
