import { faEdit, faExchangeAlt, faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBDataTable } from 'mdbreact';
import "mdbreact/dist/css/mdb.css";
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Form, Image, Modal, Row, Table } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from 'react-toastify';
import actionComponents from "../../actionComponents/index";
import apiCall from "../../apiCall";
import validation from '../../common/validation';
import MyComponent from "../../components/index";
import "../../globalStyle.css";
import utils from "../../utils/index";
import './appUserDataTable.css';

export default class AppUserDataTable extends Component {
  static propTypes = {
    dataListOG: PropTypes.array,
  }
  static defaultProps = {
    dataListOG: [],
  }
  data = {
    columns: [
      {
        field: 'firstname',
        label: 'First Name',
        sort: 'asc',
      },
      {
        field: 'lastname',
        label: 'Last Name',
        sort: 'asc',
      },
      {
        field: 'email',
        label: 'Email',
        sort: 'asc',
      },
      {
        field: 'mobile',
        label: 'Mobile',
        sort: 'asc',
      },
      {
        field: 'role',
        label: 'Role',
        sort: 'asc',
      },
      {
        field: 'status',
        label: 'Status',
        sort: 'asc',
      },
      {
        field: 'actions',
        label: 'Actions',
        sort: 'asc',
      },
      {
        field: 'log',
        label: 'Logs',
        sort: 'asc',
      },
    ],
    rows: this.processDataList()
  }

  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      changeroleModal: false,
      allowrevokeModal: false,
      deactivatestaffModal: false,
      selectedItem: {},
      editAppUserModal: false,
      appUserList: [],
      activationType: "",

      dataList: [],
      editFirstName: '',
      editLastName: '',
      editPhoneNumber: '',
      editEmailId: '',

      isEditAppUserModalOpen: false,
      errorMsg: {
        subadminname: '',
        lastName: '',
        phNo: '',
        email: '',
        staffId: '',
      },
      isLoadingBtn: false,
      iseditappuserModal: false,
      plantID: '',
      roleList: [],
      plantDataList: [],
      shiftList: [],
      sectionList: [],
      deptList: [],
      categoryList: [],
      selectedOnChangeItem: '',
      isChangeRoleModalOpen: false,


      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: "",
      selectedPlantError: '',
      selectedDeptError: '',
      selectedSectionError: '',
      selectedShiftError: '',

      role1: false,
      role3: false,
      role2: false,
      isAppLogModalOpen: false,

      profilePic: utils.images.placeholderImage,
      profilePicActualImage: "",
      profilePicError: "",
      isPageLoading: false,

      appLogUserId: "",
      appLogUserName: "",
      appLogList: [],
      appLogAppUserPage: 1,
      selectedAppLog: [],

    }
  }

  componentDidMount() {
    if (localStorage.getItem("adminType") !== "SuperAdmin") {
      this.setState({
        selectedPlant: {
          value: 0,
          label: utils.utilFunction.getPlantName(localStorage.getItem("plantId")),
          Plant_Id: localStorage.getItem("plantId")
        },
        defaultPlantData: {
          value: 0,
          label: utils.utilFunction.getPlantName(localStorage.getItem("plantId")),
          Plant_Id: localStorage.getItem("plantId")
        },
      })
    }

    this.getRoleList()
    this.getPlantList()
    this.getDeptList()
    this.getSection()
    this.getShift()
    this.getCategory()
  }

  async getRoleList() {
    try {
      let response = await apiCall.getRole();
      if (response.data.status) {
        let roleListOG, roleList, i;

        roleListOG = response.data.data
        roleList = []

        if (localStorage.getItem("adminType") === "SuperAdmin") {
          i = 0
        }
        else {
          i = 1
        }

        for (i; i < roleListOG.length; i++) {
          roleList.push(
            {
              'display_name': roleListOG[i].RoleName,
              'label': (roleListOG[i].DisplayName).trim(),

              // 'label': roleListOG[i].RoleName,
              'value': (localStorage.getItem("adminType") === "SuperAdmin") ? i : i - 1,
              'Role_id': roleListOG[i].Role_id,
            })
        }

        this.setState({
          roleList: roleList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;

        plantListOG = response.data.data
        plantList = []

        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
            {
              // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
              // 'value': i,
              // 'Plant_Id': plantListOG[i].Plant_Id,
              // 'Plant_Location': plantListOG[i].Plant_Location,
              // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

              'label': plantListOG[i].Name,
              'value': i,
              'Plant_Id': plantListOG[i].BRCCODE,
            })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getDeptList() {

    try {
      let response = await apiCall.getDepartment();
      if (response.data.status) {
        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].department_Name,
              // 'value': i,
              // 'Department_Id': deptListOG[i].Department_Id,
              // 'department_short': deptListOG[i].department_short,

              'label': deptListOG[i].Name,
              'value': i,
              'Department_Id': deptListOG[i].DPTID,
              'department_short': deptListOG[i].ShortName,
            })
        }
        this.setState({
          deptList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getSection() {
    try {
      let response = await apiCall.getSection();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {

        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].section_Name,
              // 'value': i,
              // 'Section_Id': deptListOG[i].Section_Id,

              'label': deptListOG[i].Name,
              'value': i,
              'Section_Id': deptListOG[i].SECID,
            })
        }


        this.setState({
          sectionList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getShift() {
    try {
      let object = {
        // plantId: localStorage.getItem("adminType") === "SuperAdmin" ? val.Plant_Id : localStorage.getItem("plantId")
      }
      let response = await apiCall.getShift(object);
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {
        let shiftListOG, shiftList, i;

        shiftListOG = response.data.data
        shiftList = []

        for (i = 0; i < shiftListOG.length; i++) {
          shiftList.push(
            {
              // 'label': shiftListOG[i].Shift,
              // 'value': i,
              // 'startTime': shiftListOG[i].startTime,
              // 'endTime': shiftListOG[i].endTime,

              label: shiftListOG[i].SFTID,
              value: i,
              startTime: shiftListOG[i].SFTSTTime,
              endTime: shiftListOG[i].SFTEDTime,
            })
        }
        this.setState({ shiftList: shiftList })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getCategory() {
    try {
      let response = await apiCall.getCategory();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data && response.data.status) {

        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].categoryName,
              // 'value': i,
              // 'Category_Id': deptListOG[i].Category_Id,

              'label': deptListOG[i].Name,
              'value': i,
              'Category_Id': deptListOG[i].CTGID,
            })
        }


        this.setState({
          categoryList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getAppLog() {
    this.setState({ isPageLoading: true })
    let data = this.state.selectedAppLog
    try {
      this.setState({
        appLogUserId: data.Staff_id,
        appLogUserName: data.FirstName + " " + data.LastName,
      })
      let object = {
        staffId: data.Staff_id,
        page: this.state.appLogAppUserPage,
      }

      let response = await apiCall.getAppUserAppLog(object);
      if (response.permissionChanged) {
        this.setState({
          isPageLoading: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        this.setState({ isPageLoading: false })
        if (response.data.status) {
          this.setState({
            appLogList: response.data.data.logs,
            totalRecordsFound: response.data.data.totalRecordsFound,
          }, () => this.setState({ isAppLogModalOpen: true }))
        }
        else {
          if (response.data.error.errorcode === 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem('token')
            this.setState({ isPageLoading: false })
          }
        }
      }
    }
    catch (e) {
      this.setState({ isPageLoading: false })
    }
  }

  updateName(event) {
    this.setState({ editFirstName: event.target.value })
  }

  updatePhoneNumber(event) {
    let message = validation.validatePhoneNumber(this.state.errorMsg, event.target.value)
    this.setState({ editPhoneNumber: event.target.value, message })
  }

  updateEmail(event) {
    let message = validation.validateEmail(this.state.errorMsg, event.target.value)
    this.setState({ editEmailId: event.target.value, message })
  }

  updateLastName(event) {
    this.setState({ editLastName: event.target.value })
  }

  renderAction(listOG, i) {
    return (
      <div className='action-view'>
        {
          listOG[i].IsActive ?
            <>
              <div className='pointer action-color' onClick={() => this.editAppUserData(listOG[i])} title="Edit App User"><FontAwesomeIcon icon={faEdit} /></div>
              <div>|</div>
            </>
            :
            <div />
        }
        {
          listOG[i].IsActive ?
            <>
              <div className='pointer action-color' onClick={() => {
                this.preSetDataChangeRole(listOG[i])
              }

              } title="Change Role"><FontAwesomeIcon icon={faExchangeAlt} /></div>
            </>
            :
            <div />
        }

        {

          listOG[i].IsActive ?
            <>
              <div>|</div>
              <div className='pointer action-color' onClick={() => this.handleDeactivateStaff(listOG[i], utils.config.activeAppUser.DEACTIVATE)} title="Deactivate App User"><FontAwesomeIcon icon={faUserSlash} /></div>
            </>
            :
            <div className='pointer action-color' onClick={() => this.handleDeactivateStaff(listOG[i], utils.config.activeAppUser.ACTIVATE)} title="Activate App User"><FontAwesomeIcon icon={faUserCheck} /></div>

        }

      </div>
    )
  }

  renderLog(listOG, i) {
    return (
      <div className='pointer action-color'
        onClick={() => {
          this.setState({
            selectedAppLog: listOG[i]
          },
            () => this.getAppLog()
          )

        }}
        title="Edit App User"
      >View</div>
    )
  }

  processDataList() {
    let listOG = this.props.dataListOG, data = [], i
    if (listOG.length > 0) {
      for (i = 0; i < listOG.length; i++) {
        data.push(
          {
            firstname: listOG[i].FirstName,
            lastname: listOG[i].LastName ? listOG[i].LastName : "--",
            email: listOG[i].EmailId,
            mobile: listOG[i].PhoneNumber,
            // role: listOG[i].RoleName,
            role: listOG[i].DisplayName,
            status: listOG[i].IsActive ? "Active" : "Inactive",
            actions: this.renderAction(listOG, i),
            log: this.renderLog(listOG, i),
          }
        )
      }
      this.setState({
        dataList: data
      })
      return data
    }
  }

  matchRole(role) {
    let data = this.state.roleList, i, len, object;
    // i = localStorage.getItem("adminType") === "SuperAdmin" ? 0 : 1
    i = 0
    len = data.length
    for (i; i < len; i++) {
      // if (data[i].label === role) {
      //   object = {label: data[i].label, value: i, Role_id: data[i].Role_id,}
      //   this.setState({ selectedOption: object })
      //   break;
      // }


      if (data[i].label === role.trim()) {
        object = { 'display_name': data[i].display_name, label: data[i].label, value: i, Role_id: data[i].Role_id, }
        this.setState({ selectedOption: object })
        break;
      }
    }
  }

  matchPlantDefaultList(plantId) {
    let data = this.state.plantDataList, i = 0, object
    for (i; i < data.length; i++) {
      if (data[i].Plant_Id == plantId) {
        object = { 'label': data[i].label, 'value': i, 'Plant_Id': data[i].Plant_Id, }
        this.setState({ selectedPlant: object })
        break;
      }
    }
  }

  matchDepartmentDefaultList(plantId) {
    let data = this.state.deptList, i, object
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Department_Id == plantId) {
        object = { label: data[i].label, value: i, department_short: data[i].department_short, Department_Id: data[i].Department_Id, }
        this.setState({ selectedDept: object })
        break;
      }
    }
  }

  matchSectionDefaultList(plantId) {
    let data = this.state.sectionList, i, object;
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Section_Id == plantId) {
        object = { label: data[i].label, value: i, Section_Id: data[i].Section_Id, }
        this.setState({ selectedSection: object })
        break;
      }
    }
  }

  matchShiftDefaultList(plantId) {
    let data = this.state.shiftList, i, object = []
    let x, y;
    i = 0

    for (i; i < data.length; i++) {
      x = data[i].label
      y = plantId
      let xNew = ""
      let yNew = ""
      for (let xIter in x) {
        xNew = xNew + x[xIter]
      }
      for (let yIter in x) {
        yNew = yNew + y[yIter]
      }

      if (xNew === yNew) {
        object = { 'label': data[i].label, 'value': i, 'startTime': data[i].startTime, 'endTime': data[i].endTime, }
        this.setState({ selectedShift: object })
        break;
      }
    }
  }

  handleDeactivateStaff(row, activationType) {
    this.setState({
      deactivatestaffModal: true,
      selectedItem: row,
      activationType: activationType,
    })
  }

  async updateProfilePic(id) {
    try {
      let { callBack } = this.props
      let formData = new FormData();
      let photo
      photo = this.state.profilePicActualImage
      if (photo !== "" && photo !== undefined && photo !== null) {
        if (this.state.profilePicActualImage === utils.images.placeholderImage) {
          formData.append('image', null)
        }
        else {
          formData.append('image', photo, photo.name, photo.type)
        }
        formData.append('staffId', id)
        let response = await apiCall.updateAppUserProfilePic(formData);
        if (response.data.status) {
          toast.success(utils.strings.successMsg.chgSaved)
        }
      }
      callBack()
    }
    catch (e) {

    }
  }

  validateEditAppUser() {
    let nameRes = utils.validate.validateName((this.state.editFirstName).trim())
    let emailIdRes = utils.validate.validateEmail((this.state.editEmailId).trim())
    let phNoRes = utils.validate.validatePhoneNumber(this.state.editPhoneNumber)

    if (nameRes.status && emailIdRes.status && phNoRes.status) {
      return true
    }
    else {
      return false
    }
  }

  async editAppUser() {
    if (this.validateEditAppUser()) {
      try {
        let object = {
          firstName: this.state.editFirstName,
          lastName: this.state.editLastName,
          emailId: this.state.editEmailId,
          phoneNumber: this.state.editPhoneNumber,
          staffId: this.state.staffId,
        }

        let response = await apiCall.editappUser(object);
        let { callBack } = this.props

        if (response.permissionChanged) {
          this.setState({
            isLoadingBtn: false,
            showPopUp: true,
            newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
            oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          })
        }
        if (response.data) {
          if (response.status) {
            if (this.state.profilePic) {
              this.updateProfilePic(this.state.staffId)
            }
            else {
              toast.success(utils.strings.successMsg.chgSaved)
              callBack()
            }
            this.setState({ isLoadingBtn: false, isEditAppUserModalOpen: false })
          }
          else if (response.data.error.errorcode === 1006) {
            this.setState({ isLoadingBtn: false, isEditAppUserModalOpen: false })
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem('token')
          }
          else {
            this.setState({ isLoadingBtn: false, isEditAppUserModalOpen: false })
          }
        }
        else if (response.error_code === 401) {
          utils.utilFunction.toastCall("Sorry, you are not authorized for this")
          this.setState({ isLoadingBtn: false, isEditAppUserModalOpen: true })
        }
        else {
          this.setState({ isLoadingBtn: false, })
        }
      }
      catch (e) {
        console.error(e)
      }
    }
    else {
      this.setState({ isLoadingBtn: false })
    }
  }

  selectImage() {
    this.refs.fileUploader.click();
  }

  onImageChange(event) {
    let imageData = event.target.files
    if (imageData && imageData[0]) {
      if (utils.utilFunction.validatePhoto(imageData[0].name)) {
        let reader = new FileReader();
        this.setState({
          profilePicError: '',
          profilePicActualImage: event.target.files[0]
        })

        reader.onload = (e) => {
          this.setState({
            profilePic: e.target.result
          })
        };
        reader.readAsDataURL(event.target.files[0]);

      }
      else {
        this.setState({
          profilePicError: 'Select a valid image file',
          profilePic: "",
        })
      }
    }
  }

  editAppUserModal() {
    return (
      <Modal
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.state.isEditAppUserModalOpen}
        onHide={() => this.handleEditUserclose()}
      >
        <Modal.Header closeButton className="forHeader">
          <Modal.Title id="contained-modal-title-vcenter" >
            <p className="forParagraph">Edit App User</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <>
              <div className="horizontal-center">
                <Image
                  src={this.state.profilePic}
                  className='image-placeHolder'
                  roundedCircle />
              </div>
              <div className='vertical-dap5' />
              <div className="horizontal-center">
                <div className="pointer" onClick={() => this.selectImage()}>
                  Upload Image
                  </div>
              </div>
              <div className="horizontal-center">
                <div className='error-style'>{this.state.profilePicError}</div>
              </div>
              <input
                type="file" id="file" ref="fileUploader"
                onChange={(event) => this.onImageChange(event)}
                style={{ display: "none" }}
              />
            </>

            <Form >
              <Form.Group as={Row} controlId="formHorizontalName">
                <Col>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" value={this.state.editFirstName}
                    onChange={(val) => this.updateName(val)} required />
                  <div className='error-style'>{this.state.errorMsg.subadminname}</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalName">
                <Col>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control placeholder="Last Name" type="text" value={this.state.editLastName}
                    onChange={(val) => this.updateLastName(val)} />
                  <div className='error-style'>{this.state.errorMsg.lastName}</div>
                </Col>
              </Form.Group>


              <Form.Group as={Row} controlId="formGridState">
                <Col>
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control type="text" value={this.state.editPhoneNumber}
                    onChange={(event) => {
                      let re = /^\d*$/;
                      if (re.test(event.target.value)) {
                        this.updatePhoneNumber(event)
                      }
                    }}
                    required
                  />
                  <div className='error-style'>{this.state.errorMsg.phNo}</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formGridState">
                <Col>
                  <Form.Label>Email Id.</Form.Label>
                  <Form.Control placeholder="Email" type="text" value={this.state.editEmailId}
                    onChange={(val) => this.updateEmail(val)} required />
                  <div className='error-style'>{this.state.errorMsg.email}</div>
                </Col>
              </Form.Group>



              <div className='d-flex justify-content-end'>
                <MyComponent.HeroButton
                  isBtnLoading={this.state.isLoadingBtn}
                  btnText={"Save"}
                  isGradient={true}
                  onBtnClick={() => {
                    this.setState({ isLoadingBtn: true })
                    if (!this.state.isLoadingBtn) {
                      this.editAppUser()
                    }
                  }
                  }
                />
              </div>
            </Form>
          </Container>
        </Modal.Body>
      </Modal >
    )
  }

  editAppUserData(row) {
    this.setState({
      profilePic: row.ProfilePic !== "" && row.ProfilePic !== undefined && row.ProfilePic !== null ? utils.config.baseURL + row.ProfilePic : utils.images.placeholderImage,
      editFirstName: row.FirstName,
      editLastName: row.LastName,
      editPhoneNumber: row.PhoneNumber,
      editEmailId: row.EmailId,
      PlantId: row.plantID,
      staffId: row.Staff_id,
      isEditAppUserModalOpen: true,

    })
  }

  resetEditAppUserModal() {
    this.setState({
      editFirstName: "",
      editLastName: "",
      editPhoneNumber: "",
      editEmailId: "",
      errorMsg: {
        subadminname: "",
        lastName: "",
        phNo: "",
        email: "",
      }
    })
  }

  handleEditUserclose() {
    this.resetEditAppUserModal()
    this.setState({ isEditAppUserModalOpen: false })
  }

  changeRoleModalClose() {
    this.setState({ changeroleModal: false })
  }

  deactivateStaffModalClose() {
    this.setState({ deactivatestaffModal: false })
  }

  resetChangeRoleModal() {
    this.setState({
      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: "",

      selectedPlantError: '',
      selectedDeptError: '',
      selectedSectionError: '',
      selectedShiftError: '',
      role1: false,
      role3: false,
      role2: false,
    })
  }

  handleClose() {
    this.resetChangeRoleModal()
    this.setState({
      isChangeRoleModalOpen: false
    })
  }

  handleCloseAppLogModal() {
    this.setState({
      isAppLogModalOpen: false,
      appLogAppUserPage: 1,
    })
  }

  validate() {
    let selectedOptionState, selectedPlantState, selectedDeptState, selectedShiftState, selectedSectionState

    // -- Role
    if (this.state.selectedOption) {
      selectedOptionState = true
      this.setState({ selectedOptionError: '' })
    }
    else {
      selectedOptionState = false
      this.setState({ selectedOptionError: 'Select a Role' })
    }

    // -- Plant and role 1
    if (this.state.role1) {
      if (localStorage.getItem("adminType") === "SuperAdmin") {
        if (this.state.selectedPlant) {
          selectedPlantState = true
          this.setState({ selectedPlantError: '' })
        }
        else {
          selectedPlantState = false
          this.setState({ selectedPlantError: 'Select a Plant' })
        }
      }
      else {
        selectedPlantState = true
        this.setState({ selectedPlantError: '' })
      }
    }
    else {
      selectedPlantState = true
      this.setState({ selectedPlantError: '' })
    }

    // -- Department and role 2
    if (this.state.role2) {
      if (this.state.selectedDept) {
        selectedDeptState = true
        this.setState({ selectedDeptError: '' })
      }
      else {
        selectedDeptState = false
        this.setState({ selectedDeptError: 'Select a Department' })
      }
    }
    else {
      selectedDeptState = true
      this.setState({ selectedDeptError: '' })
    }

    // -- Shift and role 3
    if (this.state.role3) {
      if (this.state.selectedShift) {
        selectedShiftState = true
        this.setState({ selectedShiftError: '' })
      }
      else {
        selectedShiftState = false
        this.setState({ selectedShiftError: 'Select a Shift' })
      }
    }
    else {
      selectedShiftState = true
      this.setState({ selectedShiftError: '' })
    }

    // -- Section and role 3
    if (this.state.role3) {
      if (this.state.selectedSection) {
        selectedSectionState = true
        this.setState({ selectedSectionError: '' })
      }
      else {
        selectedSectionState = false
        this.setState({ selectedSectionError: 'Select a Section' })
      }
    }
    else {
      selectedSectionState = true
      this.setState({ selectedSectionError: '' })
    }

    let object = {
      selectedOptionState: this.state.selectedOption,
      selectedPlantState: this.state.selectedPlant,
      selectedDeptState: this.state.selectedDept,
      selectedShiftState: this.state.selectedShift,
      selectedSectionState: this.state.selectedSection,
    }

    if (selectedOptionState && selectedPlantState && selectedDeptState && selectedShiftState && selectedSectionState) {
      return true
    }
    else {
      return false
    }
  }

  reset() {
    this.setState({
      profilePic: "",
      profilePicActualImage: "",
      profilePicError: "",
      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: "",
      defaultPlantData: "",

      selectedPlantError: "",
      selectedOptionError: "",
      selectedDeptError: "",
      selectedShiftError: '',
      selectedSectionError: '',


      role1: false,
      role3: false,
      role2: false,
    })
  }

  async changeRole(item) {
    if (this.validate()) {
      try {
        let object = {
          staffId: item.Staff_id,
          staffRoleId: item.StaffRoleId,
          // roleName: this.state.selectedOption ? this.state.selectedOption.label : "",
          roleName: this.state.selectedOption ? this.state.selectedOption.display_name : "",
          roleId: this.state.selectedOption ? this.state.selectedOption.Role_id : "",
          plantId: this.state.selectedPlant ? this.state.selectedPlant.Plant_Id : "",
          departmentId: this.state.selectedDept ? this.state.selectedDept.Department_Id : "",
          shiftId: this.state.selectedShift ? this.state.selectedShift.label : "",
          sectionId: this.state.selectedSection ? this.state.selectedSection.Section_Id : "",
        }



        this.setState({ isLoadingBtn: false })

        let response = await apiCall.changeRole(object);

        if (response.permissionChanged) {
          this.setState({
            showPopUp: true,
            newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
            oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          })
        }
        else if (response.data.status) {
          this.getRoleList()
          toast.success(utils.strings.toastMsg)
          this.reset()
          let { callBack } = this.props
          callBack()
        }
        else {
          this.setState({
            isLoadingBtn: false
          })
        }

      }
      catch (e) {
        this.setState({
          isLoadingBtn: false
        })
        utils.utilFunction.toastCall(e.response)
      }
    }
    else {
      this.setState({
        isLoadingBtn: false
      })
    }

  }

  async preSetDataChangeRole(row) {
    // if (row.RoleName === "Plant-Head") {
    //   this.setState({
    //     role1: true,
    //     role2: false,
    //     role3: false,
    //   })
    // }
    // if (row.RoleName === "Department-Head") {
    //   this.setState({
    //     role1: true,
    //     role2: true,
    //     role3: false,
    //   })
    // }
    // if (row.RoleName === "ShiftIncharge") {
    //   this.setState({
    //     role1: true,
    //     role2: true,
    //     role3: true,
    //   })
    // }

    if ((row.DisplayName).trim() === "Admin") { // if (row.RoleName === "Plant-Head") {
      this.setState({
        role1: true,
        role2: false,
        role3: false,
      })
    }
    if ((row.DisplayName).trim() === "Department Head") {
      this.setState({
        role1: true,
        role2: true,
        role3: false,
      })
    }
    if ((row.DisplayName).trim() === "ShiftIncharge") {
      this.setState({
        role1: true,
        role2: true,
        role3: true,
      })
    }

    // this.matchRole(row.RoleName)
    this.matchRole(row.DisplayName)


    this.matchPlantDefaultList(row.PlantId)
    this.matchDepartmentDefaultList(row.DepartmentId)
    this.matchSectionDefaultList(row.SectionId)
    // this.matchShiftDefaultList(row.ShiftId)

    if (row.PlantId && row.ShiftId) {
      this.matchShiftDefaultList(row.ShiftId)
    }

    this.setState({
      selectedOnChangeItem: row,
      isChangeRoleModalOpen: true,
    })
  }

  editRole() {
    let item = this.state.selectedOnChangeItem
    return (
      <>
        <MyComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          show={this.state.isChangeRoleModalOpen}
          onHide={() => this.handleClose()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="forHeader">
            <Modal.Title className="contained-modal-title-vcenter" >
              <p className="forParagraph">Change Role</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div>Do you want to change the role for <b>{item.FirstName} {item.LastName}</b></div>
              <br />
              <Form>
                <Form.Label>Role</Form.Label>
                <Form.Group as={Row} controlId="formHorizontalMobile">
                  <Col>
                    <Select
                      placeholder="Select Role"
                      defaultValue={this.state.selectedOption}
                      onChange={(val) => {
                        this.setState({
                          selectedOption: val,
                          // role1: val.label === "Plant-Head" ? true : false,
                          // role2: val.label === "Department-Head" ? true : false,
                          // role3: val.label === "ShiftIncharge " ? true : false,

                          role1: val.label === "Admin" ? true : false,
                          role2: val.label === "Department Head" ? true : false,
                          role3: val.label === "ShiftIncharge " ? true : false,
                        });

                        if (val.label === "Super Admin") { //if (val.label === "Admin") {
                          this.setState({
                            role1: false,
                            role2: false,
                            role3: false,

                            selectedPlant: "",
                            selectedDept: "",
                            selectedShift: '',
                            selectedSection: "",

                            selectedPlantError: '',
                            selectedDeptError: '',
                            selectedSectionError: '',
                            selectedShiftError: '',
                          })
                        }
                        if (val.label === "Admin") { //if (val.label === "Plant-Head") {
                          this.setState({
                            role1: true,
                            role2: false,
                            role3: false,

                            // selectedPlant: "",
                            selectedDept: "",
                            selectedShift: '',
                            selectedSection: "",

                            // selectedPlantError: '',
                            selectedDeptError: '',
                            selectedSectionError: '',
                            selectedShiftError: '',

                          })
                        }
                        if (val.label === "Department Head") { //if (val.label === "Department-Head") {
                          this.setState({
                            role1: true,
                            role2: true,
                            role3: false,

                            // selectedPlant: "",
                            selectedShift: '',
                            selectedSection: "",

                            // selectedPlantError: '',
                            selectedSectionError: '',
                            selectedShiftError: '',
                          })
                        }
                        if (val.label === "ShiftIncharge") {
                          this.setState({
                            role1: true,
                            role2: true,
                            role3: true,
                          })
                        }

                      }}
                      options={this.state.roleList}
                    />
                    <div className='error-style'>{this.state.selectedOptionError}</div>
                    {
                      this.state.role1 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <Form.Label>Plant</Form.Label>
                          {
                            localStorage.getItem("adminType") === "SuperAdmin" ?
                              <Select
                                placeholder="Select Plant"
                                defaultValue={this.state.selectedPlant}
                                onChange={(val) => {
                                  this.setState({
                                    selectedPlant: val,
                                  });
                                }}
                                options={this.state.plantDataList}
                              />
                              :
                              <Select
                                placeholder="Select Plant"
                                defaultValue={this.state.selectedPlant}
                                isDisabled={true}
                              />
                          }
                          <div className='error-style'>{this.state.selectedPlantError}</div>
                        </>
                        :
                        <div />
                    }

                    {
                      this.state.role2 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <Form.Label>Department</Form.Label>
                          <Select
                            placeholder="Select Department"
                            defaultValue={this.state.selectedDept}
                            onChange={(val) => {
                              this.setState({
                                selectedDept: val,
                              });
                            }}
                            options={this.state.deptList}
                          />
                          <div className='error-style'>{this.state.selectedDeptError}</div>
                        </>
                        :
                        <div />
                    }
                    {
                      this.state.role3 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <div className="label-style" >Section</div>
                          <Select
                            placeholder="Select Section"
                            defaultValue={this.state.selectedSection}
                            onChange={(val) => {
                              this.setState({
                                selectedSection: val,
                              });
                            }}
                            options={this.state.sectionList}
                          />
                          <div className='error-style'>{this.state.selectedSectionError}</div>

                          <div style={{ height: '18px' }} />
                          <Form.Label>Shift</Form.Label>
                          <Select
                            placeholder="Select Shift"
                            defaultValue={this.state.selectedShift}
                            onChange={(val) => {
                              this.setState({
                                selectedShift: val,
                              });
                            }}
                            options={this.state.shiftList}
                          />
                          <div className='error-style'>{this.state.selectedShiftError}</div>
                        </>
                        :
                        <div />
                    }

                  </Col>
                </Form.Group>

                <div className='d-flex justify-content-end'>
                  <MyComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={"Save"}
                    isGradient={true}
                    onBtnClick={() => this.changeRole(item)}
                  />
                </div>

              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  renderAppLogTabel(item) {
    let anctionOn = moment(item.ActionOn).format('DD MMM, YYYY (hh:mm A)')
    return (
      <tr>
        <th style={{ textAlign: "center" }}>{item.LogId}</th>
        <th style={{ textAlign: "center" }}>{item.Phone}</th>
        <th style={{ textAlign: "center" }}>{item.EmailId}</th>
        <th style={{ textAlign: "center" }}>{anctionOn}</th>
        <th style={{ textAlign: "center" }}>{item.MobileType}</th>
        <th style={{ textAlign: "center" }}>{item.ActionType}</th>
        <th style={{ textAlign: "center", color: item.Status[0] === "S" ? "#2A7A0C" : "red" }}>{item.Status}</th>
        <th style={{ textAlign: "center" }}>{item.Description}</th>
      </tr>
    )
  }

  appLog() {
    let appLogList = this.state.appLogList

    return (
      <>
        <MyComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          show={this.state.isAppLogModalOpen}
          onHide={() => this.handleCloseAppLogModal()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton className="forHeader">
            <Modal.Title className="contained-modal-title-vcenter" >
              <p className="forParagraph">App Login Log</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='app-log-body-dimention' style={{ backgroundColor: "white" }}>
            <Container style={{ backgroundColor: "white" }}>
              <div>
                <h5>{this.state.appLogUserName}</h5>
              </div>
              <div className='vertical-dap3' />
              {
                appLogList.length === 0 ?
                  <div className="placeHolder-view">No App Log</div>
                  :
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Log Id</th>
                        <th style={{ textAlign: "center" }}>Phone Number</th>
                        <th style={{ textAlign: "center" }}>Email</th>
                        <th style={{ textAlign: "center" }}>Action On</th>
                        <th style={{ textAlign: "center" }}>Device</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                        <th style={{ textAlign: "center" }}>Status</th>
                        <th style={{ textAlign: "center" }}>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        appLogList.map((item, index) =>
                          this.renderAppLogTabel(item, index)
                        )
                      }
                    </tbody>
                  </Table>
              }
              <div className="d-flex justify-content-end">
                <MyComponent.Pagination
                  pageCount={this.state.totalRecordsFound}
                  currentPage={this.state.appLogAppUserPage}
                  pageCallBack={(page) => {
                    this.setState({
                      appLogAppUserPage: page,
                      appLogList: [],
                    },
                      () => this.getAppLog()
                    )
                  }}
                />
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  render() {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
      return <Redirect to={{ pathname: '/' }} />
    }
    return (
      <div className="manageappbody" >
        {this.editAppUserModal()}
        {this.editRole()}
        {this.appLog()}
        <MDBDataTable
          responsive
          striped
          bordered
          hover
          data={this.data}
        />

        <actionComponents.ChangeRole
          show={this.state.changeroleModal}
          onHide={() => this.changeRoleModalClose()}
          callBack={() => this.changeRoleModalClose()}
          callBack2={() => {
            let { callBack } = this.props
            callBack()
          }}
          item={this.state.selectedItem}
        />

        <actionComponents.DeactivateStaff
          show={this.state.deactivatestaffModal}
          item={this.state.selectedItem}
          onHide={() => this.deactivateStaffModalClose()}
          callBack2={() => {
            let { callBack } = this.props
            callBack()
          }}
          activationType={this.state.activationType}
        />

        {
          this.state.isPageLoading ?
            <Col id="overlay" >
              <Col id="loader">
                <FadeLoader
                  size={"30"}
                  width={"10"}
                  radius={"8"}
                  color={"#08334D"}
                  loading={this.state.loading}
                />
              </Col>
            </Col>
            :
            <div />
        }

      </div>
    )
  }
}

