import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import './departmentWiseDataTable.css';
import utils from "../../../utils";

export default class departmentCategoryWiseDataTable extends Component {
  constructor(props) {
    super(props)
    this.props = props;
  }

  data = {
    columns: utils.utilFunction.processColumnList(this.props.dataList),
    rows: utils.utilFunction.processDataList(this.props.dataList)
  }

  render() {
    return (
      <div className="departmentwisebody" >
        <MDBDataTable
          responsive
          id="deptCat"
          striped
          bordered
          hover
          data={this.data} />
      </div>
    )
  }
}
