import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from "../apiCall";
import MyComponent from "../components/index";
import utils from "../utils/index";
import './login.css';

import serialNumber from 'serial-number';

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      session: false,
      userNameError: '',
      passwordError: '',
      isLoadingBtn: false,
      redirect: false,
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;
        plantListOG = response.data.data
        plantList = []
        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
              {
                'label': plantListOG[i].Name,
                'value':i,
                'Plant_Id': plantListOG[i].BRCCODE,
              })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  matchPlantDefaultList(plantId) {
    let data = this.state.plantDataList, i=0, object
    for (i; i < data.length; i++) {
      if (data[i].Plant_Id == plantId) {
        localStorage.setItem(utils.strings.localStorageItem.plantId, data[i].label);
        break;
      }
    }
  }

  async callLoginAPI() {
    try {
      let object = {
        userName: this.state.userName,
        password: this.state.password,
      }
      let response = await apiCall.login(object);
      if (response.data) {
        if (response.data.status) {
          let localStorageItem = utils.strings.localStorageItem
          localStorage.setItem(localStorageItem.token, response.data.sessionId);
          localStorage.setItem(localStorageItem.adminType, response.data.data.AdminType);
          localStorage.setItem(localStorageItem.adminId, response.data.data.Admin_id);
          localStorage.setItem(localStorageItem.emailId, response.data.data.EmailId);
          localStorage.setItem(localStorageItem.isActive, response.data.data.IsActive);
          localStorage.setItem(localStorageItem.lastLoggedIn, response.data.data.LastLoggedIn);
          localStorage.setItem(localStorageItem.plantId, response.data.data.PlantId);
          localStorage.setItem(localStorageItem.userName, response.data.data.Username);
          localStorage.setItem(localStorageItem.status, response.data.data.status);

          this.setState({
            isLoadingBtn: false,
            session: true,
          })
          window.location.reload(false)
        }
      }
      else if (response.error_code === 1004) {
        this.setState({ isLoadingBtn: false })
        toast.error(utils.strings.errorMsg.noDataFound)
      }
      else if (response.error_code === 401) {
        this.setState({ isLoadingBtn: false })
        toast.error(utils.strings.errorMsg.userBlokedLogin)
      }
    }
    catch (e) {
      this.setState({
        isLoadingBtn: false
      })
      toast.error(e.response)
    }
  }

  validate() {
    let nameRes = utils.validate.validateName(this.state.userName)
    let passwdRes = utils.validate.validatePassword(this.state.password)
    this.setState({
      userNameError: nameRes.msg,
      passwordError: passwdRes.msg,
    })
    if (nameRes.status && passwdRes.status) {
      this.callLoginAPI()
    }
    else {
      this.setState({ isLoadingBtn: false })
    }
  }

  render() {
    if (localStorage.getItem(utils.strings.localStorageItem.token) !== null) {
      return <Redirect to={{ pathname: utils.config.pageURL.dashboard }} />
    }
    let isOnline = navigator.onLine
    return (
        <div className='login-container'>
          <Col className='nested'>
            <div className='login-form'>
              {/*<Row>*/}
              {/*  <Col*/}
              {/*      // md={{ span: 6, offset: 1 }}*/}
              {/*       className='d-flex justify-content-center'>*/}
              {/*    <Image*/}
              {/*        alt=""*/}
              {/*        src={utils.images.logo2}*/}
              {/*        className="logo-style"*/}
              {/*    />*/}
              {/*  </Col>*/}
              {/*</Row>*/}

              <div>
                <Image
                    alt=""
                    src={utils.images.logo2}
                    className="logo-style"
                />
              </div>
              <div className="vertical-dap3" />
              <Row>
                <Col>
                  <Form.Group controlId="formBasicEmail">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                          id={"t_username"}
                          required
                          value={this.state.userName}
                          onChange={(event) => {this.setState({ userName: event.target.value })}}
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                    <div className='error-style'>{this.state.userNameError}</div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formBasicPassword">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faLock} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                          id={"t_password"}
                          type="password" placeholder="Password"
                          value={this.state.password}
                          onChange={(event) => {
                            this.setState({ password: event.target.value })
                          }}
                          name="password"
                          required />
                    </InputGroup>
                    <div className='error-style'>{this.state.passwordError}</div>
                  </Form.Group>
                </Col>
              </Row>

              <a href="#forgot-password">{utils.strings.loginStrings.forgotPassword}</a>

              <Row>
                <Col className='d-flex justify-content-end' style={{ marginBottom: "1rem" }}>
                  <MyComponent.HeroButton
                      isBtnLoading={this.state.isLoadingBtn}
                      btnText={utils.strings.buttonText.login}
                      isGradient={true}
                      explicitWidth={'150px'}
                      onBtnClick={() => {
                        if (isOnline) {
                          this.setState({
                            isLoadingBtn: true
                          })
                          if (!this.state.isLoadingBtn) {
                            this.validate()
                          }
                        }}}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </div>
    )
  }

  redirect() {
    if (this.state.redirect) {
      return <Redirect to={utils.config.pageURL.admin} />
    }
  }
}
