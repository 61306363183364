
const logo = require('../assets/images/logo.png');
// const logo2 = require('../assets/images/logo2.jpg');
const logo2 = require('../assets/images/sanseraLogo.png');
const profileImage = require('../assets/images/images.jpg');
const backArrow = require('../assets/images/backArrow.png');
const PNF = require('../assets/images/fogg-page-not-found.png')
const placeholderImage = require('../assets/images/placeholderImage.png')
const uploadImage = require('../assets/images/uploadImage.png')
const removeImage = require('../assets/images/removeImage.png')
const appUserIcon = require('../assets/images/appUserIcon.png')
const employeeIcon = require('../assets/images/employeeIcon.png')
const plantIcon = require('../assets/images/plantIcon.png')
const staffIcon = require('../assets/images/staffIcon.png')

const images = {
    logo,
    logo2,
    profileImage,
    backArrow,
    PNF,
    placeholderImage,
    uploadImage,
    removeImage,
    appUserIcon,
    employeeIcon,
    plantIcon,
    staffIcon,
}

export default images;
