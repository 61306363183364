import React from 'react';
import { Offline } from "react-detect-offline";
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Dashboard from './dashboard/dashboard';
import ForgotPassword from "./forgotPassword/forgotPassword";
import "./globalStyle.css";
import Login from './login/login';
import Admin from "./manageAdmin/admin/admin";
import AppUsers from "./manageAppUser/appUser/appUsers";
import ManageEmployees from './manageEmployees/employees/employees';
import ManagePlant from "./managePlant/managePlant";
import ShiftManagement from "./manageShift/manageShift";
import PageNotFound from "./pageNotFound/pageNotFound";
import Profile from './profile/profile';
import DepartmentWise from './reports/department/departmentWise';
import PlantWise from './reports/plant/plantWise';
import SectionWise from './reports/section/sectionWise';
import utils from "./utils";

class App extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    let pageURL = utils.config.pageURL
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="no-internet">
          <Offline>
            <div className="no-internet-font">
              {utils.strings.stringMiscellaneous.noNet}
            </div>
          </Offline>
        </div>
        <HashRouter>
          <Switch>
            <Route exact path={pageURL.login} component={Login} />
            <Route path={pageURL.forgotPassword} component={ForgotPassword} />

            <Route path={pageURL.dashboard} component={Dashboard} />
            <Route path={pageURL.admin} component={Admin} />
            <Route path={pageURL.manageEmployees} component={ManageEmployees} />
            <Route path={pageURL.manageAppUsers} component={AppUsers} />
            <Route path={pageURL.managePlant} component={ManagePlant} />
            <Route path={pageURL.reportDepartmentWise} component={DepartmentWise} />
            <Route path={pageURL.reportSectionWise} component={SectionWise} />
            <Route path={pageURL.reportPlantWise} component={PlantWise} />
            <Route path={pageURL.profile} component={Profile} />
            <Route path={pageURL.manageShift} component={ShiftManagement} />
            <Route component={PageNotFound} />
          </Switch>
        </HashRouter>
      </>
    );

  }
}

export default App;
