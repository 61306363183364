import 'react-toastify/dist/ReactToastify.css';

const common = {
  validateName,
  validateEmail,
  validatePassword,
  validatePlant,
  validateUploadpic,
  validatePhoneNumber,
  validateLastName,
  validateEmpName,
}

export default common;
function validateName(errorMsg, subadminname) {
  if (subadminname.length === 0) {
    errorMsg.subadminname = "Name must not be empty";
    // toast.error(`${errorMsg.subadminname}`);
    return false;
  } else {
    errorMsg.subadminname = ''
    return true
  }
}

function validateEmail(errorMsg, email) {
  if (email === "") {
    errorMsg.email = "Email must not be empty";
    return false;
  }
  else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    errorMsg.email = "Email id is invalid";
    return false;
  }
  else {
    errorMsg.email = '';
    return true;
  }
}

function validatePhoneNumber(errorMsg, phNo) {
  if (phNo.length > 10 || phNo.length === 0) {
    errorMsg.phNo = "Phone number is invalid";
    return false;
  }
  else {
    errorMsg.phNo = '';

    return true;
  }
}

function validatePassword(errorMsg, password) {
  if (password.length < 6) {
    errorMsg.password = 'Password must be at least 6 characters long';
    // toast.error(`${errorMsg.password}`);
    return false;
  } else if (!/\d/.test(password)) {
    errorMsg.password = 'Password must contain a digit';
    // toast.error(`${errorMsg.password}`);
    return false;
  } else if (!/[!@#$%^&*]/.test(password)) {
    errorMsg.password = 'Password must contain special character: !@#$%^&*';
    // toast.error(`${errorMsg.password}`);
    return false;
  }
  else {
    errorMsg.password = ''
    return true;
  }

}

function validatePlant(errorMsg, options) {
  if (options.length === 0) {
    errorMsg.options = "Please select plant name"
    // toast.error(`${errorMsg.options}`);
    return false;
  }
  else {
    errorMsg.plantname = ''
    return true
  }
}

function validateUploadpic(errorMsg, uploadpic) {
  if (uploadpic.length === 0) {
    errorMsg.uploadpic = "Please select image"
    // toast.error(`${errorMsg.uploadpic}`);
    return false;
  }
  else {
    errorMsg.plantname = ''
    return true
  }
}

function validateLastName(errorMsg, lastName) {
  if (lastName.length === 0) {
    errorMsg.lastName = "Last Name must not be empty";
    // toast.error(`${errorMsg.subadminname}`);
    return false;
  } else {
    errorMsg.lastName = ''
    return true
  }
}


function validateEmpName(errorMsg, name) {
  if (name.length === 0) {
    errorMsg.name = "Name must not be empty";
    return false;
  }
  else {
    errorMsg.name = ''
    return true
  }
}

