import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import './departmentWiseDataTable.css';

export default class departmentShiftWiseDataTable extends Component {
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            columnsAry: [],
            firstArray: [
                {
                    field: 'Srl',
                    label: 'Srl No.',
                    sort: 'asc',
                    width: 50
                },
                {
                    field: 'departmentId',
                    label: 'Department Id',
                    sort: 'asc',
                    width: 50
                },
                {
                    field: 'departmentname',
                    label: 'Department Name',
                    sort: 'asc',
                    width: 50
                },
                {
                    field: 'totalemployee',
                    label: 'Total Emplyoyee',
                    sort: 'asc',
                    width: 50
                }
            ],
            lastArray: [
                {
                    field: 'totalpresent',
                    label: 'Total Present',
                    sort: 'asc',
                    width: 50
                },
                {
                    field: 'eh',
                    label: 'EH',
                    sort: 'asc',
                    width: 50
                }
            ]
        }
    }

    componentDidMount() {
        let listOG = this.props.dataList
        let shiftList = listOG.length > 0 ? listOG[0].shiftList : [];

        let newArray = [];

        for (let i of shiftList) {
            newArray.push({
                field: i.shortName,
                label: i.shortName,
                sort: '',
                width: 50
            })
        }
        this.setState({ columnsAry: newArray })
    }


    processDataList() {
        let listOG = this.props.dataList
        let data = [];

        if (listOG.length > 0) {
            let totalPresentEmp = 0;
            let totalExtraHour = 0;
            let totalEmpFinal = 0;

            let totalObj = {};

            for (let i = 0; i < listOG.length; i++) {
                let shiftList = listOG[i].shiftList;

                let srNo = i + 1
                let departmentId = listOG[i] ? listOG[i].department_Id : "NA";
                let departmentname = listOG[i].department_Name
                let totalemployee = listOG[i].totalEmployees
                let totalpresent = listOG[i].employeePresentCount
                let eh = listOG[i].extraHours

                totalPresentEmp += parseInt(listOG[i].employeePresentCount);
                totalExtraHour += parseInt(listOG[i].extraHours);
                totalEmpFinal += parseInt(listOG[i].totalEmployees);


                let firstObj = {
                    Srl: srNo,
                    departmentId: departmentId,
                    departmentname: departmentname,
                    totalemployee: totalemployee
                };

                let secondObj = {};
                for (let j in shiftList) {
                    let object = {
                        [shiftList[j].shortName]: shiftList[j].count
                    }
                    secondObj = { ...secondObj, ...object }
                    // totalObj = {[shiftList[j].shortName]: shiftList[j].count}
                }

                let lastObj = {
                    totalpresent: totalpresent,
                    eh: eh
                }

                let finalObj = { ...firstObj, ...secondObj, ...lastObj }

                data.push(finalObj)
            }

            let fstObj = {
                Srl: "Total:",
                departmentId: "",
                departmentname: "",
                totalemployee: totalEmpFinal
            }
            let lstObj = {
                totalpresent: totalPresentEmp,
                eh: totalExtraHour
            }
            data.push({...fstObj,...totalObj,...lstObj})
        }
        return data
    }

    render() {
        return (
            <div className="departmentwisebody" >
                <MDBDataTable
                    responsive
                    id="deptShift"
                    striped
                    bordered
                    hover
                    data={
                        {
                            columns: (this.state.firstArray.concat(this.state.columnsAry)).concat(this.state.lastArray),
                            rows: this.processDataList()
                        }
                    }
                />

            </div>
        )
    }
}
