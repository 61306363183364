import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from "../apiCall";
import myComponent from "../components/index";
import "../globalStyle.css";
import "../login/login.css";
import utils from "../utils/index";
import "./forgotPassword.css";

export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isLoadingBtn: false,
            email: '',
            emailError: '',
            step1: true,
            step2: false,
            password: '',
            confirmPassword: "",
            otp: "",
            passwordError: '',
            confirmPasswordError: "",
            otpError: "",
        }
    }

    validateStep1() {
        let res = utils.validate.validateEmail(this.state.email)
        this.setState({
            emailError: res.msg
        })
        if (res.status)
            return true
        else
            return false
    }

    validateStep2() {
        let passwdRes = utils.validate.validatePassword(this.state.password)
        let conPasswdRes = utils.validate.validateConfirmPassword(this.state.password, this.state.confirmPassword)
        let otpRes = utils.validate.validateName(this.state.otp)

        this.setState({
            passwordError: passwdRes.msg,
            confirmPasswordError: conPasswdRes.msg,
        })

        if (otpRes.errorCode === 1)
            this.setState({ otpError: utils.strings.errorMsg.otpError })
        else
            this.setState({ otpError: "" })


        if (passwdRes.status && conPasswdRes.status && otpRes.status)
            return true
        else
            return false

    }

    async proceed() {
        try {
            if (this.validateStep1()) {
                let object = {
                    emailId: this.state.email
                }
                let response = await apiCall.getOTP(object);
                this.setState({ isLoadingBtn: false })

                if (response.data) {
                    if (response.data.status) {
                        this.setState({
                            isLoadingBtn: false,
                            step1: false,
                            step2: true,
                        })
                    }
                }
                else {
                    if (response.error_code) {
                        toast.error(utils.strings.errorMsg.noUser + this.state.email)
                    }
                    else {
                        toast.error(response.error_message)
                    }
                }
            }
            else {
                this.setState({ isLoadingBtn: false })
            }
        }
        catch (e) {
            toast.error(e.response)
        }
    }

    async changePassword() {
        if (this.validateStep2()) {
            try {
                let object = {
                    emailId: this.state.email,
                    newPassword: this.state.password,
                    otp: this.state.otp
                }
                let response = await apiCall.chnagePassword(object);
                if (response.data) {
                    this.setState({ isLoadingBtn: false })
                    if (response.data.status) {
                        toast.success(utils.strings.successMsg.passChange)
                        this.setState({ redirect: true })
                    }
                }
                else {
                    this.setState({ isLoadingBtn: false })
                    if (response.error_code === 498)
                        toast.error(utils.strings.errorMsg.otpInvalid)
                    else
                        toast.error(response.error_message)
                }
            }
            catch (e) {
                this.setState({ isLoadingBtn: false })
            }
        }
        else {
            this.setState({ isLoadingBtn: false })
        }
    }

    render() {
        if (this.state.redirect)
            return <Redirect to={{ pathname: utils.config.pageURL.login }} />

        if (localStorage.getItem(utils.strings.localStorageItem.token)) {
            if (localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin)
                return <Redirect to={{ pathname: utils.config.pageURL.admin }} />
            else
                return <Redirect to={{ pathname: utils.config.pageURL.manageAppUsers }} />
        }
        let input = navigator.onLine
        return (
            <div className='login-container'>
                <Col className='nested'>
                    <div className='forgot-password-form'>
                        {
                            this.state.step1 ?
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md={{ span: 2 }} onClick={() => {
                                                this.setState({
                                                    redirect: true,
                                                })
                                            }
                                            }>
                                                <Image src={utils.images.backArrow} />
                                            </Col>
                                            <Col md={{ span: 6, offset: 1 }} className='d-flex justify-content-center'>

                                                <Image
                                                    alt=""
                                                    src={utils.images.logo2}
                                                    className="logo-style"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className="textColor">{utils.strings.forgotPasswordSrings.forgotPassword}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col ><p className="textColor">{utils.strings.forgotPasswordSrings.des}</p></Col>
                                        </Row>
                                        <Row>
                                            <Col >
                                                <Form>
                                                    <Form.Group controlId="formBasicPassword">
                                                        <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>
                                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                                </InputGroup.Text>
                                                            </InputGroup.Prepend>

                                                            <Form.Control
                                                                id={"t_proceed"}
                                                                style={{ width: "400px" }}
                                                                required
                                                                value={this.state.email}
                                                                onChange={(event) => {
                                                                    let res = utils.validate.validateEmail(event.target.value)
                                                                    this.setState({
                                                                        email: event.target.value,
                                                                        emailError: res.msg,
                                                                    })
                                                                }}
                                                                placeholder="Email id"
                                                            />
                                                        </InputGroup>
                                                        <div className='error-style'>{this.state.emailError}</div>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                        </Row>

                                        <Row className="buttonSpace">
                                            <Col className='d-flex justify-content-end' >
                                                <myComponent.HeroButton
                                                    id={"proceed-btn"}
                                                    isBtnLoading={this.state.isLoadingBtn}
                                                    btnText={utils.strings.errorMsg.proceed}
                                                    isGradient={true}
                                                    onBtnClick={() => {
                                                        this.setState({ isLoadingBtn: true, })
                                                        if (!this.state.isLoadingBtn) {
                                                            this.proceed()
                                                        }
                                                    }
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col md={{ span: 2 }} onClick={() => {
                                                this.setState({
                                                    step1: true,
                                                    step2: false,
                                                    password: '',
                                                    confirmPassword: "",
                                                    otp: "",
                                                    passwordError: '',
                                                    confirmPasswordError: "",
                                                    otpError: "",
                                                })
                                            }
                                            }>
                                                <Image src={utils.images.backArrow} />
                                            </Col>
                                            <Col md={{ span: 6, offset: 1 }} className='d-flex justify-content-center'>
                                                <img
                                                    alt=""
                                                    src={utils.images.logo2}
                                                    style={{ width: "208px", height: "42px", marginTop: "15px", marginBottom: "10px" }} />
                                            </Col>
                                        </Row>

                                        <div className="vertical-dap2" />
                                        <Row>
                                            <Col >
                                                <Form>
                                                    <Form.Control
                                                        required
                                                        disabled
                                                        value={this.state.email}
                                                        placeholder={utils.strings.inputFeild.emailId}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>

                                        <div className={"vertical-dap3"} />
                                        <Row>
                                            <Col >
                                                <Form>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder={utils.strings.inputFeild.password}
                                                        value={this.state.password}
                                                        onChange={(event) => {
                                                            let res = utils.validate.validatePassword(event.target.value)
                                                            this.setState({
                                                                password: event.target.value,
                                                                passwordError: res.msg,
                                                            })
                                                        }}
                                                        name="password"
                                                        required />
                                                    <div className='error-style'>{this.state.passwordError}</div>
                                                </Form>
                                            </Col>
                                        </Row>

                                        <div className={"vertical-dap3"} />
                                        <Row>
                                            <Col >
                                                <Form>
                                                    <Form.Control
                                                        type="password"
                                                        placeholder={utils.strings.inputFeild.confirmPassword}
                                                        value={this.state.confirmPassword}
                                                        onChange={(event) => {
                                                            let res = utils.validate.validateConfirmPassword(this.state.password, event.target.value)
                                                            this.setState({
                                                                confirmPassword: event.target.value,
                                                                confirmPasswordError: res.msg,
                                                            })
                                                        }}
                                                        name="password"
                                                        required />
                                                    <div className='error-style'>{this.state.confirmPasswordError}</div>
                                                </Form>
                                            </Col>
                                        </Row>

                                        <div className={"vertical-dap3"} />
                                        <Row>
                                            <Col >
                                                <Form>
                                                    <Form.Control
                                                        placeholder={utils.strings.inputFeild.OTP}
                                                        value={this.state.otp}
                                                        onChange={(event) => {
                                                            let res = utils.validate.validateName(event.target.value)
                                                            this.setState({ otp: event.target.value, })
                                                            if (res.errorCode !== 1)
                                                                this.setState({ otpError: "", })
                                                            else
                                                                this.setState({ otpError: "Please enter the OTP", })
                                                        }}
                                                        required />
                                                    <div className='error-style'>{this.state.otpError}</div>
                                                </Form>
                                            </Col>
                                        </Row>


                                        <div className="vertical-dap2" />
                                        <Row className="buttonSpace">
                                            <Col className='d-flex justify-content-end' >
                                                <myComponent.HeroButton
                                                    id={"forgot-password-btn"}
                                                    isBtnLoading={this.state.isLoadingBtn}
                                                    btnText={utils.strings.buttonText.changePassword}
                                                    isGradient={true}
                                                    onBtnClick={() => {
                                                        this.setState({ isLoadingBtn: true })
                                                        if (!this.state.isLoadingBtn) {
                                                            this.changePassword()
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        }
                    </div>
                </Col>
            </div>
        )
    }
}