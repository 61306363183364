import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import utils from "../../utils/index";

export default class ChangePlant extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      plantDataList: [],
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
    }
  }

  static propTypes = {
    item: PropTypes.any,
  }

  static defaultProps = {
    item: {}
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  componentDidMount() {
    this.getPlantList()
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG = response.data.data, plantList = this.state.plantDataList, i = 0;
        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
            {
              // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
              // 'value': i,
              // 'Plant_Id': plantListOG[i].Plant_Id,
              // 'Plant_Location': plantListOG[i].Plant_Location,
              // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

              'label': plantListOG[i].Name,
              'value':i,
              'Plant_Id': plantListOG[i].BRCCODE,
            })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  validate() {
    let plant = this.state.selectedOption
    if (plant)
      return true
    else
      return false
  }

  async changePlant(item) {
    if (this.validate()) {
      let plant = this.state.selectedOption
      let object = {
        adminId: this.props.userid,
        plantId: plant.Plant_Id
      }
      try {
        let response = await apiCall.changePlant(object);
        let { callBack2 } = this.props

        if (response.permissionChanged) {
          this.setState({
            showPopUp: true,
            newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
            oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          })
        }
        else if (response.data) {
          if (response.data.status) {
            callBack2()
            this.getPlantList()
            toast.success("plant change is saved")
          }
          else if (response.data.error.errorcode === 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem('token')
            this.setState({ isLoadingBtn: false })
          }
        }
        this.setState({ isLoadingBtn: false })
      }
      catch (e) {
        this.setState({
          isLoadingBtn: false
        })
        utils.utilFunction.toastCall(e.response)
      }
    }
    this.setState({
      isLoadingBtn: false
    })
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    let item = this.props.item
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="forHeader" >
            <Modal.Title id="contained-modal-title-vcenter" >
              <p className="forParagraph">Change Plant</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>

                <Form.Group as={Row} controlId="formHorizontalImages">
                  <Col>
                    <Form.Label>Plant Name</Form.Label>
                    <Select
                      placeholder="Select Plant"
                      onChange={this.handleChange}
                      options={this.state.plantDataList}
                      defaultValue={this.props.item}
                    />
                  </Col>
                </Form.Group>

                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={utils.strings.buttonText.save}
                    isGradient={true}
                    onBtnClick={() => {
                      this.setState({
                        isLoadingBtn: true
                      })
                      if (!this.state.isLoadingBtn) {
                        this.changePlant(item)
                      }
                    }}
                  />
                </div>

              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}