import subDays from "date-fns/subDays";
import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { NavLink, Redirect } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import "react-toggle/style.css";
import * as XLSX from 'xlsx';
import apiCall from "../../apiCall";
import myComponent from '../../components';
import "../../globalStyle.css";
import Sidebar from '../../Sidebar/Sidebar';
import utils from "../../utils/index";
import PlantWiseCategoryDataTable from "./plantDataTable/plantWiseCategoryDataTable";
import PlantWiseShiftDataTable from "./plantDataTable/plantWiseShiftDataTable";
import './plantwise.css';

class plantWise extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadexcelsheet: false,
      selectedOption: '',
      selectedOptionCategories: '',
      checked: false,
      plantDataList: [],
      categoryList: [],
      dataList: [],
      selectedPlantId: "",
      startDate: new Date(),
      endDate: "",
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
      apiCallDone: false,
      catList: [],
      shiftList: [],
      isPageLoading: false,
      noData: false,
      totalCountData: [],
    }
  }

  componentDidMount() {
    this.setState({ isPageLoading: true, })
    this.getPlantList()
    this.getData()
    this.getCategoryList()
  }

  processShiftData(item) {
    this.setState({ shiftList: item })
    let data = [], i
    let totalEmployeesCount = 0, FSTCount = 0, GENCount = 0, EVECount = 0, NSTCount = 0, SECCount = 0, employeePresentCountCount = 0, extraHoursCount = 0
    for (i = 0; i < item.length; i++) {
      data.push(
        {
          "sr no": i + 1,
          "Plant Id": item[i].Plant_Id,
          "Plant Name": utils.utilFunction.getPlantName(item[i].Plant_Id),
          "total Employee": item[i].totalEmployees,
          FST: item[i].FST,
          GEN: item[i].GEN,
          EVE: item[i].EST,
          NST: item[i].NST,
          SEC: item[i].SEC,
          "Present Count": item[i].employeePresentCount,
          "Extra Hours": item[i].extraHours,
        }
      )
      totalEmployeesCount = item[i].totalEmployees + totalEmployeesCount
      FSTCount = item[i].FST + FSTCount
      GENCount = item[i].GEN + GENCount
      EVECount = item[i].EST + EVECount
      NSTCount = item[i].NST + NSTCount
      SECCount = item[i].SEC + SECCount
      employeePresentCountCount = item[i].employeePresentCount + employeePresentCountCount
      extraHoursCount = parseInt(item[i].extraHours) + extraHoursCount
    }
    this.setState({
      totalCountData: [totalEmployeesCount, FSTCount, GENCount, EVECount, NSTCount, SECCount, employeePresentCountCount, extraHoursCount],
      shiftList: data,
      dataList: item,
    })
  }

  processCatData(item) {
    this.setState({ shiftList: item })
    let data = [], i
    let totalEmployeesCount = 0, Associates = 0, contractLabour = 0, Trainee = 0, SWEEPER = 0, NEEM = 0, STAFF = 0, employeePresentCountCount = 0, extraHoursCount = 0
    let DRIVERCount = 0, MICROGENISESCount = 0, SECURITYCount = 0, HOUSE_KEEPINGCount = 0, CategoryCount = 0;

    this.setState({
      totalCountData: [totalEmployeesCount, CategoryCount, Associates, contractLabour, Trainee, DRIVERCount, NEEM, STAFF, MICROGENISESCount, SECURITYCount, HOUSE_KEEPINGCount, employeePresentCountCount, extraHoursCount],
      catList: utils.utilFunction.processDataList(item),
      dataList: item,
    })
  }

  async getData() {
    try {
      let object = {
        startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
        endDate: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : "",
        plantId: "",
        viewType: this.state.checked ? "CATEGORY_VIEW" : "SHIFT_VIEW",
      }
      let response = await apiCall.getPlantWiseList(object);
      if (response.permissionChanged) {
        this.setState({
          isPageLoading: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        if (response.data.status) {
          this.setState({
            isPageLoading: false,
            // dataList: response.data.data,
            noData: (response.data.data).length === 0 ? true : false,
            catList: this.state.checked ? [] : this.state.catList,
            shiftList: this.state.checked ? this.state.shiftList : [],
          })
          if (this.state.checked) {
            this.processCatData(response.data.data)
          }
          else {
            this.processShiftData(response.data.data)
          }
        }
        else {
          this.setState({ isPageLoading: false, })
          if (response.data.error.errorcode == 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem('token')
          }
        }
      }
      this.setState({ apiCallDone: true })
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  exportData() {
    let dataList = this.state.checked ? this.state.catList : this.state.shiftList
    const ws = XLSX.utils.json_to_sheet(dataList);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, "Plant_Report.xlsx");
  }

  handlePlantChange(selectedOption) {
    this.setState({ selectedOption })
  }

  handleCategoryChange(selectedOptionCategories) {
    this.setState({ selectedOptionCategories })
  }

  handleChangeToggle(state) {
    this.setState(
      {
        dataList: [],
        // startDate: new Date(),
        // endDate: "",
        checked: state,
        isPageLoading: true,
      },
      () => this.getData()
    )
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;
        plantListOG = response.data.data
        plantList = this.state.plantDataList
        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
            {
              label: plantListOG[i].Name,
              value: i,
              Plant_Id: plantListOG[i].BRCCODE,
            })
        }
        this.setState({
          plantDataList: plantList
        })
        return plantList
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getCategoryList() {
    try {
      let response = await apiCall.getCategory();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data && response.data.status) {
        let deptListOG, deptList, i;
        deptListOG = response.data.data
        deptList = []
        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              'label': deptListOG[i].Name,
              'value': i,
              'Category_Id': deptListOG[i].CTGID,
            })
        }
        this.setState({
          categoryList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  render() {
    const { checked } = this.state;
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />

    let pageURL = utils.config.pageURL
    return (
      <div className='custom-container'>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <myComponent.Header />
        <div className='row-2'>
          <div className="sidebar-view">
            <div className='sidebar-static'>
              <Sidebar />
            </div>
          </div>
          <div className="content-view">
            <div className="sub-content-view">
              <Row className="heading">
                <Col>
                  <h4>{utils.strings.heading.reports} </h4>
                </Col>
                <Col className="d-flex justify-content-end">
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={utils.strings.buttonText.expXlsx}
                    isGradient={true}
                    onBtnClick={() => {
                      this.exportData()
                    }}
                  />
                </Col>
              </Row>

              <Row >
                <Col md={{ span: 8 }} className="navMargin" >
                  <Nav   >
                    <NavLink className="wiseForOthers" to={pageURL.reportDepartmentWise} >{utils.strings.reportString.deptWise}</NavLink>
                    <NavLink className="wiseForOthers" to={pageURL.reportSectionWise} >{utils.strings.reportString.secWise}</NavLink>
                    <NavLink className="wisedepartment" to={pageURL.reportPlantWise} >{utils.strings.reportString.plantWise}</NavLink>
                  </Nav>
                </Col>
              </Row>

              <div className="gap1" />


              <Row>
                <Row style={{ marginBottom: "20px", marginLeft: "8px" }}>
                  <div style={{
                    color: !this.state.checked ? "white" : "#0E3856",
                    backgroundColor: !this.state.checked ? "#0e3856" : "#E6F5FD",
                  }}
                    className="pointer toggle-view"
                    onClick={() => this.handleChangeToggle(false)}>{utils.strings.reportString.shiftView}</div>

                  <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>

                  <div style={{
                    color: this.state.checked ? "white" : "#0E3856",
                    backgroundColor: this.state.checked ? "#0e3856" : "#E6F5FD",
                  }}
                    className="pointer toggle-view"
                    onClick={() => this.handleChangeToggle(true)}>{utils.strings.reportString.catView}</div>
                </Row>
                <Col md={{ span: 2 }} >
                </Col>
                <Col className='d-flex justify-content-end'  >
                  <p className="rangeCss">{utils.strings.reportString.selectDateRange}</p>
                  <Row>
                    <Col>
                      <DatePicker
                        className={'form-control datePicEditEmployee'}
                        popperPlacement="top-end"
                        minDate={subDays(new Date(), 360)}
                        maxDate={(new Date())}
                        selected={this.state.startDate}
                        onChange={(date) => {
                          this.setState({
                            dataList: [],
                            startDate: date === null ? "" : date,
                            isPageLoading: true,
                          },
                            () => this.getData("")
                          );
                        }}
                        dateFormat="MMMM ,d yyyy "
                        placeholderText="Select Start Date"
                      />
                    </Col>
                    {
                      this.state.startDate === new Date() ?
                        <div />
                        :
                        <Col>
                          <DatePicker
                            isClearable={this.state.endDate ? true : false}
                            className={'form-control datePicEditEmployee'}
                            popperPlacement="top-end"
                            minDate={this.state.startDate}
                            maxDate={new Date()}
                            selected={this.state.endDate}
                            onChange={(date) => {
                              this.setState({
                                dataList: [],
                                endDate: date === null ? "" : date,
                                isPageLoading: true,
                              },
                                () => this.getData("")
                              );
                            }}
                            dateFormat="MMMM ,d yyyy "
                            placeholderText="Select End Date"
                          />
                        </Col>
                    }
                  </Row>
                </Col>
              </Row>

              <div className="gap1" />
              {
                (this.state.apiCallDone) ?
                  (this.state.dataList).length > 0 ?
                    checked ?
                      <PlantWiseCategoryDataTable
                        totalCountData={this.state.totalCountData}
                        dataList={this.state.dataList}
                      />
                      :
                      <PlantWiseShiftDataTable
                        totalCountData={this.state.totalCountData}
                        dataList={this.state.dataList}
                      />
                    :
                    this.state.noData ?
                      <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                      :
                      <div />
                  :
                  <Col id="overlay" >
                    <Col id="loader">
                      <FadeLoader
                        size={"30"}
                        width={"10"}
                        radius={"8"}
                        color={"#08334D"}
                        loading={this.state.loading}
                      />
                    </Col>
                  </Col>
              }

            </div>
          </div>
        </div>

        {
          this.state.isPageLoading ?
            <Col id="overlay" >
              <Col id="loader">
                <FadeLoader
                  size={"30"}
                  width={"10"}
                  radius={"8"}
                  color={"#08334D"}
                  loading={this.state.isPageLoading}
                />
              </Col>
            </Col>
            :
            <div />
        }
      </div>

    )
  }
}

export default plantWise;
