import React, { Component } from 'react';
import { Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import "../../globalStyle.css";
import utils from "../../utils/index";
import "../createSubAdmin/createSubAdmin.css";
import validation from "../../common/validation";

export default class AddAppUser extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      phNo: '',
      phNoError: '',
      email: '',
      emailError: '',
      plantname: '',
      uploadpic: '',
      errorMsg: {
        email: '',
        lastName: '',
        uploadpic: '',
        plantname: '',
        phNo: '',
      },
      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: '',
      selectedPlantError: "",
      selectedOptionError: "",
      selectedDeptError: "",
      selectedShiftError: '',
      selectedSectionError: '',
      code: "",
      codeError: "",
      role1: false,
      role3: false,
      role2: false,
      roleList: [],
      plantDataList: [],
      deptList: [],
      shiftList: [],
      sectionList: [],
      isLoadingBtn: false,
      showPopUp: false,
      newPlant: '',
      oldPlant: '',
      defaultPlantID: '',
      defaultPlant: "",
      profilePic: utils.images.placeholderImage,
      profilePicActualImage: "",
      profilePicError: ""
    }
  }

  getDefaultPant() {
    if (localStorage.getItem(utils.strings.localStorageItem.adminType) !== utils.strings.adminType.superAdmin) {

      let plantName = "", i = 0;
      let plantId = localStorage.getItem(utils.strings.localStorageItem.plantId)
      let data = this.state.plantDataList
      let plantList;

      for (i; i < data.length; i++) {
        if (data[i].Plant_Id == plantId) {
          plantList = data[i]
          plantName = data[i].label
          break;
        }
      }

      this.setState({
        defaultPlantID: plantName,
        selectedPlant: plantList,
      })
    }
  }

  async componentDidMount() {

    await this.getRoleList()
    await this.getPlantList()
    await this.getDeptList()
    await this.getShift()
    await this.getSection()
    this.getDefaultPant()

  }

  async getSection() {
    try {
      let response = await apiCall.getSection();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {
        let deptListOG, deptList, i;
        deptListOG = response.data.data
        deptList = []
        i = 0
        for (i; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].section_Name,
              // 'value': i,
              // 'Section_Id': deptListOG[i].Section_Id,

              'label': deptListOG[i].Name,
              'value': i,
              'Section_Id': deptListOG[i].SECID,
            })
        }

        this.setState({ sectionList: deptList })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getShift() {
    try {
      let object = {
        // plantId: localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin ? val.Plant_Id : localStorage.getItem(utils.strings.localStorageItem.plantId)
      }

      let response = await apiCall.getShift(object);

      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {
        let shiftListOG = response.data.data, shiftList = [], i = 0;
        for (i = 0; i < shiftListOG.length; i++) {
          shiftList.push(
            {
              // 'label': shiftListOG[i].Shift,
              // 'value': i,
              // 'startTime': shiftListOG[i].startTime,
              // 'endTime': shiftListOG[i].endTime,
              // 'plantId': shiftListOG[i].plantId,

              label: shiftListOG[i].SFTID,
              value: i,
              startTime: shiftListOG[i].SFTSTTime,
              endTime: shiftListOG[i].SFTEDTime,

            })
        }


        this.setState({
          shiftList: shiftList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getRoleList() {
    try {
      let response = await apiCall.getRole();
      if (response.data.status) {
        let roleListOG, roleList, i;

        roleListOG = response.data.data
        roleList = []

        if (localStorage.getItem("adminType") === "SuperAdmin") {
          i = 0
        }
        else {
          i = 1
        }

        for (i; i < roleListOG.length; i++) {
          roleList.push(
            {
              'display_name': roleListOG[i].RoleName,
              'label': (roleListOG[i].DisplayName).trim(),
              // 'label': roleListOG[i].RoleName,
              'value': i,
              'Role_id': roleListOG[i].Role_id,
            })
        }

        this.setState({
          roleList: roleList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG = response.data.data, plantList = [], i = 0;

        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
            {
              'label': plantListOG[i].Name,
              'value': i,
              'Plant_Id': plantListOG[i].BRCCODE,
            })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getDeptList() {

    try {
      let response = await apiCall.getDepartment();
      if (response.data.status) {
        let deptListOG = response.data.data, deptList = [], i = 0;

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              'label': deptListOG[i].Name,
              'value': i,
              'Department_Id': deptListOG[i].DPTID,
              'department_short': deptListOG[i].ShortName,
            })
        }
        this.setState({
          deptList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  validateForm() {
    let selectedOptionState, selectedEmailState, selectedPlantState, selectedDeptState, selectedShiftState, selectedSectionState, firstNameValidate, phNoValidate, emailValidate, codeValidate

    codeValidate = utils.validate.validateName(this.state.code)
    if (!codeValidate.status) {
      this.setState({
        codeValidate: false,
        codeError: "Please enter app user code"
      })
    }

    firstNameValidate = utils.validate.validateName(this.state.firstName)
    phNoValidate = utils.validate.validatePhoneNumber(this.state.phNo)
    emailValidate = utils.validate.validateEmail(this.state.email)
    // if (this.state.email !== "") {
    //   emailValidate = utils.validate.validateEmail(this.state.email)
    // }


    if ((this.state.selectedOption).length === 0) {
      selectedOptionState = false
      this.setState({ selectedOptionError: 'Select a Role' })
    }
    else {
      selectedOptionState = true
      this.setState({ selectedOptionError: '' })
    }

    if ((this.state.selectedPlant).length === 0 && this.state.role1) {
      selectedPlantState = false
      this.setState({ selectedPlantError: 'Select a Plant' })
    }
    else {
      selectedPlantState = true
      this.setState({ selectedPlantError: '' })
    }

    if ((this.state.selectedDept).length === 0 && this.state.role2) {
      selectedDeptState = false
      this.setState({ selectedDeptError: 'Select a Department' })
    }
    else {
      selectedDeptState = true
      this.setState({ selectedDeptError: '' })
    }

    if ((this.state.selectedShift).length === 0 && this.state.role3) {
      selectedShiftState = false
      this.setState({ selectedShiftError: 'Select a Shift' })
    }
    else {
      selectedShiftState = true
      this.setState({ selectedShiftError: '' })
    }

    if ((this.state.selectedSection).length === 0 && this.state.role3) {
      selectedSectionState = false
      this.setState({ selectedSectionError: 'Select a Section' })
    }
    else {
      selectedSectionState = true
      this.setState({ selectedSectionError: '' })
    }

    if (codeValidate.status && emailValidate.status && firstNameValidate.status && phNoValidate.status && selectedOptionState && selectedPlantState && selectedDeptState && selectedShiftState && selectedSectionState) {
      return true;
    }
    else {
      this.setState({
        errorMsg: this.state.errorMsg,
        phNoError: phNoValidate.msg,
        firstNameError: firstNameValidate.msg,
        // emailError: this.state.email !== "" ? emailValidate.msg : "",
        emailError: emailValidate.msg,
        isLoadingBtn: false,
      })
      return false;
    }
  }

  async updateProfilePic(id) {
    try {
      let { callBack } = this.props
      let formData = new FormData();
      let photo
      photo = this.state.profilePicActualImage

      if (photo !== "" && photo !== undefined && photo !== null) {
        if (this.state.profilePicActualImage === utils.images.placeholderImage) {
          formData.append('image', photo, "placeholder", 'image/jpeg')
        }
        else {
          formData.append('image', photo, photo.name, photo.type)
        }

        formData.append('staffId', id)
        let response = await apiCall.updateAppUserProfilePic(formData);
        if (response.data.status) {
          toast.success("updateProfilePic")
        }
      }
      callBack()
    }
    catch (e) {

    }
  }

  async addAppUser() {
    try {
      let object = {
        empCode: this.state.code,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailId: this.state.email,
        phoneNumber: this.state.phNo,
        // roleName: this.state.selectedOption.label,
        roleName: this.state.selectedOption.display_name,
        roleId: this.state.selectedOption.Role_id,
        plantId: this.state.selectedPlant && this.state.role1 ? this.state.selectedPlant.Plant_Id : "",
        departmentId: this.state.selectedDept && this.state.role2 ? this.state.selectedDept.Department_Id : "",
        shiftId: this.state.selectedShift && this.state.role3 ? this.state.selectedShift.label : "",
        sectionId: this.state.selectedSection && this.state.role3 ? this.state.selectedSection.Section_Id : "",
      }

      let formData = new FormData();
      let photo = this.state.profilePicActualImage
      let response
      if (photo !== "" && photo !== undefined && photo !== null) {
        formData.append('image', photo, photo.name, photo.type)
      }
      formData.append('data', JSON.stringify(object))
      response = await apiCall.addAppUser2(formData);

      let { callBack2 } = this.props
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      if (response.data) {
        this.setState({ isLoadingBtn: false })
        if (response.data.status) {
          this.handleClose()
          toast.success("App user added")
          callBack2()
        }
        else if (response.data.error && response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isLoadingBtn: false })
        }
        else {
          this.handleClose()
          this.setState({
            isLoadingBtn: false
          })
        }
      }
      else if (response.error_message === "USER_ALREADY_EXIST") {
        this.setState({ isLoadingBtn: false })
        utils.utilFunction.toastCall(utils.strings.errorMsg.appUserUniqueUser)
      }

    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e.response)
    }

  }

  async handleSubmit() {
    if (this.validateForm()) {
      this.addAppUser()
    }
    else {
      this.setState({ isLoadingBtn: false })
    }
  }

  reset() {
    this.setState({
      profilePic: "",
      profilePicActualImage: "",
      profilePicError: "",
      codeError: "",
      code: "",
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      phNo: '',
      phNoError: '',
      email: '',
      emailError: '',
      plantname: '',
      uploadpic: '',
      errorMsg: {
        email: '',
        lastName: '',
        uploadpic: '',
        plantname: '',
        phNo: '',
      },
      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: '',
      selectedPlantError: "",
      selectedOptionError: "",
      selectedDeptError: "",
      selectedShiftError: '',
      selectedSectionError: '',
      role1: false,
      role3: false,
      role2: false,
      showPopUp: false,
      newPlant: '',
      oldPlant: '',
      defaultPlantID: '',
      defaultPlant: "",
    })
  }

  handleClose() {
    let { callBack } = this.props
    this.reset()
    callBack()
  }

  updateCode(empCode) {
    let res = utils.validate.validateName(empCode.target.value)

    if (!res.status) {
      this.setState({
        codeError: "Please enter app user code"
      })
    }
    else {
      this.setState({ codeError: "" })
    }

    this.setState({ code: empCode.target.value })
  }

  selectImage() {
    this.refs.fileUploader.click();
  }

  onImageChange(event) {
    let imageData = event.target.files
    if (imageData && imageData[0]) {
      if (utils.utilFunction.validatePhoto(imageData[0].name)) {
        let reader = new FileReader();
        this.setState({
          profilePicError: '',
          profilePicActualImage: event.target.files[0]
        })

        reader.onload = (e) => {
          this.setState({
            profilePic: e.target.result
          })
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      else {
        this.setState({
          profilePicError: 'Select a valid image file',
          profilePic: "",
        })
      }
    }
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => this.handleClose()}
            className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" >
              <p className="forParagraph"> Add App User</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className="horizontal-center">
                <Image
                  src={this.state.profilePic ? this.state.profilePic : utils.images.placeholderImage}
                  className='image-placeHolder'
                  roundedCircle />
              </div>
              <div className='vertical-dap4' />
              <div className="horizontal-center">
                <div className="pointer" onClick={() => this.selectImage()}>
                  Upload Image
                  </div>
              </div>
              <div className="horizontal-center">
                <div className='error-style'>{this.state.profilePicError}</div>
              </div>
              <input
                type="file" id="file" ref="fileUploader"
                onChange={(event) => this.onImageChange(event)}
                style={{ display: "none" }}
              />


              <Form >
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Col >
                    <Form.Label>Employee code</Form.Label>
                    <Form.Control type="text" placeholder="Please enter app user code*"
                      value={this.state.code}
                      onChange={(e) => {
                        let re = /^[a-zA-Z0-9]*$/;
                        if (re.test(e.target.value) === true) {
                          this.updateCode(e)
                        }
                      }}
                    />
                    <div className='error-style'>{this.state.codeError}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalName">
                  <Col >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" name="firstname" placeholder="Enter First Name*" value={this.state.firstName}
                      onChange={(event) => {
                        let res = utils.validate.validateName(event.target.value)
                        this.setState({
                          firstName: event.target.value,
                          firstNameError: res.msg,
                        })
                      }}
                      required />
                    <div className='error-style'>{this.state.firstNameError}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalName">

                  <Col >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" name="lastname" placeholder="Enter Last Name" value={this.state.lastName}
                      onChange={(event) => {
                        this.setState({
                          lastName: event.target.value,
                        })
                      }}
                    />
                    <div className='error-style'>{this.state.lastNameError}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formGridState">
                  <Col >
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="text" name="phonenumber" placeholder="Enter Mobile number*" value={this.state.phNo}
                      onChange={(event) => {
                        let re = /^\d*$/;
                        if (re.test(event.target.value)) {
                          let res = utils.validate.validatePhoneNumber(event.target.value)
                          this.setState({
                            phNo: event.target.value,
                            phNoError: res.msg,
                          })
                        }
                      }}
                    />
                    <div className='error-style'>{this.state.phNoError}</div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formGridState">
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" name="email" placeholder="Enter Your Email*" value={this.state.email}
                      onChange={(event) => {
                        let res = utils.validate.validateEmail(event.target.value)
                        this.setState({
                          email: event.target.value,
                          emailError: res.errorCode === 2 ? res.msg : "",
                        })
                      }}
                    />
                    <div className='error-style'>{this.state.emailError}</div>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="formHorizontalImages">

                  <Col>
                    <Form.Label>Role</Form.Label>
                    <Select
                      placeholder="Select Role"
                      onChange={(val) => {
                        this.getDefaultPant()
                        this.setState({
                          selectedOption: val,
                          selectedOptionError: val ? "" : this.state.selectedOptionError,
                          // role1: val.label === "Plant-Head" ? true : false,
                          // role2: val.label === "Department-Head " ? true : false,
                          // role3: val.label === "ShiftIncharge " ? true : false,

                          role1: val.label === "Admin" ? true : false,
                          role2: val.label === "Department-Head" ? true : false,
                          role3: val.label === "ShiftIncharge " ? true : false,

                          selectedPlantError: '',
                          selectedDeptError: '',
                          selectedSectionError: '',
                          selectedShiftError: '',
                        });
                        if (val.label === "Admin") { //if (val.label === "Plant-Head") {
                          this.setState({
                            role1: true,
                            role2: false,
                            role3: false,
                          })
                        }
                        if (val.label === "Department Head") {  //if (val.label === "Department-Head") {
                          this.setState({
                            role1: true,
                            role2: true,
                            role3: false,
                          })
                        }
                        if (val.label === "ShiftIncharge") {
                          this.setState({
                            role1: true,
                            role2: true,
                            role3: true,
                          })
                        }
                      }}
                      options={this.state.roleList}
                    />
                    <div className='error-style'>{this.state.selectedOptionError}</div>
                    {
                      this.state.role1 ?
                        <>
                          <div style={{ height: '18px' }} />

                          {
                            localStorage.getItem("adminType") === "SuperAdmin" ?
                              <Select
                                placeholder="Select Plant*"
                                onChange={(val) => {
                                  this.setState({
                                    selectedPlant: val,
                                  });
                                }}
                                options={this.state.plantDataList}
                              />
                              :
                              <Select
                                placeholder="Select Plant*"
                                // defaultValue={[{ value: this.state.defaultPlantID, label: this.state.defaultPlantID, Plant_Id: localStorage.getItem("plantId") }]}
                                defaultValue={this.state.selectedPlant}

                                isDisabled={true}
                              />
                          }
                          <div className='error-style'>{this.state.selectedPlantError}</div>
                        </>
                        :
                        <div />
                    }

                    {
                      this.state.role2 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <Select
                            placeholder="Select Department*"
                            onChange={(val) => {
                              this.setState({
                                selectedDept: val,
                              });
                            }}
                            options={this.state.deptList}
                          />
                          <div className='error-style'>{this.state.selectedDeptError}</div>
                        </>
                        :
                        <div />
                    }
                    {
                      this.state.role3 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <Select
                            placeholder="Select Section*"
                            onChange={(val) => {
                              this.setState({
                                selectedSection: val,
                              });
                            }}
                            options={this.state.sectionList}
                          />
                          <div className='error-style'>{this.state.selectedSectionError}</div>
                          <div style={{ height: '18px' }} />
                          <Select
                            placeholder="Select Shift*"
                            onChange={(val) => {
                              this.setState({
                                selectedShift: val,
                              });
                            }}
                            options={this.state.shiftList}
                          />
                          <div className='error-style'>{this.state.selectedShiftError}</div>
                        </>
                        :
                        <div />
                    }

                  </Col>

                </Form.Group>

                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={"Add App User"}
                    isGradient={true}
                    onBtnClick={() => {
                      this.setState({ isLoadingBtn: true })
                      if (!this.state.isLoadingBtn) {
                        this.handleSubmit()
                      }
                    }}
                  />
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>

    )
  }

}
