import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import './PlantwiseDataTable.css';
import utils from "../../../utils";

export default class plantWiseCategoryDataTable extends Component {
  constructor(props) {
    super(props)
    this.props = props;
  }

  data ={
    columns: utils.utilFunction.processColumnList(this.props.dataList),
    rows: utils.utilFunction.processDataList(this.props.dataList)
  }

  render() {

    return (
      <div className="plantwisebody" >
        <MDBDataTable
          responsive
          id="plantCat"
          striped
          bordered
          hover
          data={this.data} />
      </div>
    )
  }
}

