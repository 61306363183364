import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import utils from "../../utils/index";

export default class ChangeRole extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      loading: false,
      username: '',
      firstname: '',
      lastname: '',
      role: '',


      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: "",

      selectedPlantError: "",
      selectedOptionError: "",
      selectedDeptError: "",
      selectedShiftError: '',
      selectedSectionError: '',


      role1: false,
      role3: false,
      role2: false,

      roleList: [],
      plantDataList: [],
      deptList: [],
      shiftList: [],
      sectionList: [],

      isLoadingBtn: false,


      showPopUp: false,
      newPlant: '',
      oldPlant: '',

      defaultPlantID: '',
      defaultPlant: "",

    }
  }

  static propTypes = {
    item: PropTypes.any,
  }

  static defaultProps = {
    item: {}
  }

  componentDidMount() {

    if (localStorage.getItem("adminType") !== "SuperAdmin") {
      this.setState({
        defaultPlantID: utils.utilFunction.getPlantName(localStorage.getItem("plantId")),
        selectedPlant: { value: this.state.defaultPlantID, label: this.state.defaultPlantID, Plant_Id: localStorage.getItem("plantId") },
      })
    }

    this.setState({ loading: true })
    this.getRoleList()
    this.getPlantList()
    this.getShift()
    this.getSection()
    this.getDeptList()
  }

  async getSection() {
    try {
      let response = await apiCall.getSection();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {

        let deptListOG = response.data.data, deptList = [], i=0;

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].section_Name,
              // 'value': i,
              // 'Section_Id': deptListOG[i].Section_Id,

              'label': deptListOG[i].Name,
              'value': i,
              'Section_Id': deptListOG[i].SECID,
            })
        }


        this.setState({
          sectionList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getShift() {
    try {
      let object = {
        // plantId: val.Plant_Id
      }
      let response = await apiCall.getShift(object);

      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {
        let shiftListOG, shiftList, i;
        shiftListOG = response.data.data
        shiftList = []
        i = 0

        for (i; i < shiftListOG.length; i++) {
          shiftList.push(
            {
              // 'label': shiftListOG[i].Shift,
              // 'value': i,
              // 'startTime': shiftListOG[i].startTime,
              // 'endTime': shiftListOG[i].endTime,
              // 'plantId': shiftListOG[i].plantId,

              label: shiftListOG[i].SFTID,
              value: i,
              startTime: shiftListOG[i].SFTSTTime,
              endTime: shiftListOG[i].SFTEDTime,
            })
        }

        this.setState({
          shiftList: shiftList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getRoleList() {
    try {
      let response = await apiCall.getRole();
      if (response.data.status) {
        let roleListOG, roleList, i;

        roleListOG = response.data.data
        roleList = []


        if (localStorage.getItem("adminType") === "SuperAdmin") {
          i = 0
        }
        else {
          i = 1
        }

        for (i; i < roleListOG.length; i++) {
          roleList.push(
            {
              'label': roleListOG[i].RoleName,
              'value': i,
              'Role_id': roleListOG[i].Role_id,
            })
        }

        this.setState({
          roleList: roleList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();

      if (response.data.status) {
        let plantListOG, plantList, i;

        plantListOG = response.data.data
        plantList = []
        i = 0

        for (i ; i < plantListOG.length; i++) {
          plantList.push(
            {
              // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
              // 'value': i,
              // 'Plant_Id': plantListOG[i].Plant_Id,
              // 'Plant_Location': plantListOG[i].Plant_Location,
              // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

              'label': plantListOG[i].Name,
              'value':i,
              'Plant_Id': plantListOG[i].BRCCODE,
            })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getDeptList() {
    try {
      let response = await apiCall.getDepartment();
      if (response.data.status) {
        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []
        i = 0

        for (i; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].department_Name,
              // 'value': i,
              // 'Department_Id': deptListOG[i].Department_Id,
              // 'department_short': deptListOG[i].department_short,

              'label': deptListOG[i].Name,
              'value': i,
              'Department_Id': deptListOG[i].DPTID,
              'department_short': deptListOG[i].ShortName,
            })
        }
        this.setState({
          deptList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  validate() {
    let selectedOptionState, selectedPlantState, selectedDeptState, selectedShiftState, selectedSectionState

    if ((this.state.selectedOption).length === 0) {
      selectedOptionState = false
      this.setState({ selectedOptionError: 'Select a Role' })
    }
    else {
      selectedOptionState = true
      this.setState({ selectedOptionError: '' })
    }

    if ((this.state.selectedPlant).length === 0 && this.state.role1) {
      selectedPlantState = false
      this.setState({ selectedPlantError: 'Select a Plant' })
    }
    else {
      selectedPlantState = true
      this.setState({ selectedPlantError: '' })
    }

    if ((this.state.selectedDept).length === 0 && this.state.role2) {
      selectedDeptState = false
      this.setState({ selectedDeptError: 'Select a Department' })
    }
    else {
      selectedDeptState = true
      this.setState({ selectedDeptError: '' })
    }

    if ((this.state.selectedShift).length === 0 && this.state.role3) {
      selectedShiftState = false
      this.setState({ selectedShiftError: 'Select a Shift' })
    }
    else {
      selectedShiftState = true
      this.setState({ selectedShiftError: '' })
    }

    if ((this.state.selectedSection).length === 0 && this.state.role3) {
      selectedSectionState = false
      this.setState({ selectedSectionError: 'Select a Section' })
    }
    else {
      selectedSectionState = true
      this.setState({ selectedSectionError: '' })
    }


    if (selectedOptionState && selectedPlantState && selectedDeptState && selectedShiftState && selectedSectionState) {
      return true
    }
    else {
      return false
    }
  }

  reset() {
    this.setState({
      selectedPlant: "",
      selectedOption: "",
      selectedDept: "",
      selectedShift: '',
      selectedSection: "",

      selectedPlantError: "",
      selectedOptionError: "",
      selectedDeptError: "",
      selectedShiftError: '',
      selectedSectionError: '',


      role1: false,
      role3: false,
      role2: false,
    })
  }

  async changeRole(item) {
    if (this.validate()) {
      try {
        let object = {
          staffId: item.Staff_id,
          staffRoleId: item.StaffRoleId,
          roleName: this.state.selectedOption.label,
          roleId: this.state.selectedOption.Role_id,
          plantId: this.state.selectedPlant && this.state.role1 ? this.state.selectedPlant.Plant_Id : "",
          departmentId: this.state.selectedDept && this.state.role2 ? this.state.selectedDept.Department_Id : "",
          shiftId: this.state.selectedShift && this.state.role3 ? this.state.selectedShift.label : "",
          sectionId: this.state.selectedSection && this.state.role3 ? this.state.selectedSection.Section_Id : "",
        }

        let response = await apiCall.changeRole(object);
        if (response.permissionChanged) {
          this.setState({
            showPopUp: true,
            newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
            oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          })
        }
        else if (response.data.status) {
          this.getRoleList()
          this.handleClose()

          toast.success("role is changed")
          this.reset()
          let { callBack2 } = this.props
          callBack2()
        }
        else {
          this.setState({
            isLoadingBtn: false
          })
        }
      }
      catch (e) {
        this.setState({
          isLoadingBtn: false
        })
        utils.utilFunction.toastCall(e.response)
      }
    }
    else {
      this.setState({
        isLoadingBtn: false
      })
    }

  }

  handleClose() {
    this.reset()
    let { callBack } = this.props
    callBack()
  }

  render() {
    let item = this.props.item
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => this.handleClose()}
            className="forHeader">
            <Modal.Title className="contained-modal-title-vcenter" >
              <p className="forParagraph">Change Role</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <p className="forParagraph">Do you want to change the role for <b>{item.FirstName} {item.LastName}</b></p>
              <br />
              <Form>
                <Form.Group as={Row} controlId="formHorizontalMobile">
                  <Col>
                    <Select
                      placeholder="Select Role"
                      onChange={(val) => {
                        this.setState({
                          selectedOption: val,
                          role1: val.label === "Plant-Head" ? true : false,
                          role2: val.label === "Department-Head " ? true : false,
                          role3: val.label === "ShiftIncharge " ? true : false,
                          selectedPlantError: '',
                          selectedDeptError: '',
                          selectedSectionError: '',
                          selectedShiftError: '',
                        });
                        if (val.label === "Plant-Head") {
                          this.setState({
                            role1: true,
                            role2: false,
                            role3: false,
                          })
                        }
                        if (val.label === "Department-Head") {
                          this.setState({
                            role1: true,
                            role2: true,
                            role3: false,
                          })
                        }
                        if (val.label === "ShiftIncharge") {
                          this.setState({
                            role1: true,
                            role2: true,
                            role3: true,
                          })
                        }
                      }}
                      options={this.state.roleList}
                    />
                    <div className='error-style'>{this.state.selectedOptionError}</div>
                    {
                      this.state.role1 ?
                        <>
                          <div style={{ height: '18px' }} />
                          {
                            localStorage.getItem("adminType") === "SuperAdmin" ?
                              <Select
                                placeholder="Select Plant"
                                onChange={(val) => {
                                  // this.getShift(val)
                                  this.setState({
                                    selectedPlant: val,
                                  });
                                }}
                                options={this.state.plantDataList}
                              />
                              :
                              <Select
                                placeholder="Select Plant"
                                defaultValue={[{ value: this.state.defaultPlantID, label: this.state.defaultPlantID, Plant_Id: localStorage.getItem("plantId") }]}
                                // defaultValue={this.state.selectedPlant}
                                isDisabled={true}
                              />
                          }
                          <div className='error-style'>{this.state.selectedPlantError}</div>
                        </>
                        :
                        <div />
                    }
                    {
                      this.state.role2 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <Select
                            placeholder="Select Department"
                            onChange={(val) => {
                              this.setState({
                                selectedDept: val,
                              });
                            }}
                            options={this.state.deptList}
                          />
                          <div className='error-style'>{this.state.selectedDeptError}</div>
                        </>
                        :
                        <div />
                    }
                    {
                      this.state.role3 ?
                        <>
                          <div style={{ height: '18px' }} />
                          <Select
                            placeholder="Select Shift"
                            onChange={(val) => {
                              this.setState({
                                selectedShift: val,
                              });
                            }}
                            options={this.state.deptList}
                          />
                          <div className='error-style'>{this.state.selectedShiftError}</div>
                          <div style={{ height: '18px' }} />
                          <Select
                            placeholder="Select Section"
                            onChange={(val) => {
                              this.setState({
                                selectedSection: val,
                              });
                            }}
                            options={this.state.sectionList}
                          />
                          <div className='error-style'>{this.state.selectedSectionError}</div>
                        </>
                        :
                        <div />
                    }
                  </Col>
                </Form.Group>
                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={"Save"}
                    isGradient={true}
                    onBtnClick={() => this.changeRole(item)}
                  />
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
