import DeactivateDesignation from './deactivateEmployee/deactivateEmployee'
import AddAppUser from "./addAppUser/addAppUser"
import AddEmployee from "./addEmployee/addEmployee"
import blockadmin from "./blockAdmin/blockadmin"
import ChangePassword from "./changePassword/changePassword"
import ChangePlant from "./changePlant/changePlant"
import ChangeRole from "./changeRole/changeRole"
import CreateSubAdmin from "./createSubAdmin/createSubAdmin"
import DeactivateStaff from "./deactivate/deactivateStaff"
import PolicyModal from "./policyModal/policyModal"
import UploadAppUser from "./uploadAppUser/uploadAppUser";
import UploadExcelsheet from "./uploadEmployeeErrorPopUp/uploadExcelsheet";

const actionComponents = {
    ChangePassword,
    ChangePlant,
    blockadmin,
    ChangeRole,
    DeactivateStaff,
    AddAppUser,
    CreateSubAdmin,
    AddEmployee,
    DeactivateDesignation,
    PolicyModal,
    UploadAppUser,
    UploadExcelsheet,
}
export default actionComponents
