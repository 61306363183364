import React, { Component } from 'react';
import "../../globalStyle.css";
// import './plantDataTable.css';
import { MDBDataTable } from 'mdbreact';
import "mdbreact/dist/css/mdb.css";
import utils from "../../utils";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTasks } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import myComponent from "../../components";
import apiCall from "../../apiCall";
import { toast } from "react-toastify";

export default class shidtDataTable extends Component {
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            selectedShift: null,
            updateShiftDetails: false,
            address: "",
            addressError: "",
            isLoadingBtn: false,
            plantID: 0,
            showPopUp: false,
            newPlant: "",
            oldPlant: "",
        }
    }

    handleClose() {
        this.setState({
            updateShiftDetails: false,
            address: "",
            isLoadingBtn: false,
            plantID: 0,
        })
    }

    validate() {
        let shortName = utils.validate.validateShiftShortName(this.state.selectedShift.shortName)


        if (shortName.status) {
            this.setState({ addressError: "" })
            return true
        }
        else {
            this.setState({ addressError: shortName.reason})
            return false
        }
    }

    async editShiftShortName() {
        if (this.validate()) {
            try {
                let object = {
                    shiftId: this.state.selectedShift.shiftId,
                    shortName: this.state.selectedShift.shortName
                }

                let response = await apiCall.editShiftShortName(object);

                let { callBack2 } = this.props

                if (response.permissionChanged) {
                    this.setState({
                        isLoadingBtn: false,
                        showPopUp: true,
                        newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
                        oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
                    })
                }
                if (response.data) {
                    this.setState({ isLoadingBtn: false })
                    if (response.data.status) {
                        this.handleClose()
                        callBack2()
                        toast.success("Short name updated")
                    } else if (response.data.error && response.data.error.errorcode === 1006) {
                        utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
                        localStorage.removeItem('token')
                        this.setState({ isLoadingBtn: false })
                    } else {
                        this.handleClose()
                        this.setState({
                            isLoadingBtn: false
                        })
                    }
                } else if (response.error_message) {
                    this.setState({ isLoadingBtn: false })
                    utils.utilFunction.toastCall(response.error_message)
                }
            } catch (e) {
                this.setState({ isLoadingBtn: false })
                utils.utilFunction.toastCall(e.response)
            }
        }
        else {
            this.setState({ isLoadingBtn: false })
        }
    }


    updatePlantAddressModal() {
        let shiftData = this.state.selectedShift
        return (
            <Modal
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.updateShiftDetails}
                onHide={() => this.setState({
                    updateShiftDetails: false
                })}
            >
                <Modal.Header closeButton className="forHeader">
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <p className="forParagraph">{"Edit Shift"}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Form.Group controlId="addr">
                                <Form.Label>Short Name</Form.Label>
                                <Form.Control
                                    value={shiftData ? shiftData.shortName.toUpperCase() : ""}
                                    onChange={(e) => {
                                        let str = e.target.value.trim();
                                        // str = str.replace(/\s\s+/g, ' ');
                                        shiftData.shortName = str;
                                        this.setState({ selectedShift: shiftData })
                                    }}
                                />
                                <div className='error-style'>{this.state.addressError}</div>
                            </Form.Group>
                        </Form>
                        <div className='d-flex justify-content-end'>
                            <myComponent.HeroButton
                                isBtnLoading={this.state.isLoadingBtn}
                                btnText={"Update"}
                                isGradient={true}
                                onBtnClick={() => {
                                    this.setState({ isLoadingBtn: true, })
                                    if (!this.state.isLoadingBtn) {
                                        this.editShiftShortName()
                                    }
                                }
                                }
                            />
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    setModalData(data) {
        this.setState({
            selectedShift: data
        },
            () => this.setState({ updateShiftDetails: true })
        )
    }

    renderAction(data, i) {
        return (
            <div
                className='pointer action-color' style={{ textAlign: "left" }}
                onClick={() => this.setModalData(data[i])}
                title={"Edit"}
            > &nbsp;&nbsp;<FontAwesomeIcon icon={faEdit} /></div>
        )
    }

    processDataList() {
        let listOG = this.props.dataListOG, data = [], i

        if (listOG.length > 0) {
            for (i = 0; i < listOG.length; i++) {
                data.push(
                    {
                        shiftId: listOG[i].shiftId,
                        label: listOG[i].label,
                        shortName: listOG[i].shortName.toUpperCase(),
                        startTime: listOG[i].startTime,
                        endTime: listOG[i].endTime,
                        edit: this.renderAction(listOG, i)
                    }
                )
            }
        }

        return data
    }

    data = {
        columns: [
            {
                label: 'ID',
                field: 'shiftId',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Shift Name',
                field: 'label',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Short Name',
                field: 'shortName',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Start Time',
                field: 'startTime',
                sort: 'asc',
                width: 100
            },
            {
                label: 'End Time',
                field: 'endTime',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Edit',
                field: 'edit',
                // sort: 'asc',
                width: 100
            }
        ],
        rows: this.processDataList()
    }

    render() {
        if (!localStorage.getItem(utils.strings.localStorageItem.token))
            return <Redirect to={{ pathname: utils.config.pageURL.login }} />
        return (
            <div className="admindatatable">
                {this.updatePlantAddressModal()}
                <div style={{ backgroundColor: "#FFFFFF" }}>
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        hover
                        data={this.data}
                    />
                </div>
            </div>
        )
    }

}
