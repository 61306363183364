import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import './sectionWiseDataTable.css';


export default class sectionwiseDataTable extends Component {

  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      columnsAry: [],
      firstArray: [
        {
          field: 'Srl',
          label: 'Srl No.',
          sort: 'asc',
          width: 50
        },
        {
          field: 'section_Id',
          label: 'Section Id',
          sort: 'asc',
          width: 50
        },
        {
          field: 'sectionname',
          label: 'Section Name',
          sort: 'asc',
          width: 50
        },
        {
          field: 'totalemployee',
          label: 'Total Emplyoyee',
          sort: 'asc',
          width: 50
        }
      ],
      lastArray: [
        {
          field: 'totalpresent',
          label: 'Total Present',
          sort: 'asc',
          width: 50
        },
        {
          field: 'eh',
          label: 'EH',
          sort: 'asc',
          width: 50
        }
      ]
    }
  }

  componentDidMount() {
    let listOG = this.props.dataList
    let shiftList = listOG.length > 0 ? listOG[0].shiftList : [];

    let newArray = [];

    for (let i of shiftList) {
      newArray.push({
        field: i.shortName,
        label: i.shortName,
        sort: '',
        width: 50
      })
    }
    this.setState({ columnsAry: newArray })
  }

  processDataList() {
    let listOG = this.props.dataList
    let totalCountData = this.props.totalCountData;
    let data = []

    if (listOG.length > 0) {
      for (let i = 0; i < listOG.length; i++) {
        let srNo = i + 1
        let section_Id = listOG[i] ? listOG[i].section_Id : "NA"; // -- NEW
        let sectionName = listOG[i].section_Name
        let totalemployee = listOG[i].totalEmployees
        let totalpresent = listOG[i].employeePresentCount
        let eh = listOG[i].extraHours

        let shiftList = listOG[i].shiftList;

        let firstObj = {
          Srl: srNo,
          section_Id: section_Id,
          sectionname: sectionName,
          totalemployee: totalemployee
        };

        let secondObj = {};
        for (let j in shiftList) {
          let object = {
            [shiftList[j].shortName]: shiftList[j].count
          }
          secondObj = { ...secondObj, ...object }
        }

        let lastObj = {
          totalpresent: totalpresent,
          eh: eh
        }

        let finalObj = { ...firstObj, ...secondObj, ...lastObj }

        data.push(finalObj)
      }
      data.push(
        {
          Srl: "",
          section_Id: "",
          sectionname: "Total:",
          totalemployee: totalCountData[0],
          // fst: totalCountData[1],
          // gen: totalCountData[2],
          // eve: totalCountData[3],
          // ngt: totalCountData[4],
          // sec: totalCountData[5],
          totalpresent: totalCountData[6],
          eh: totalCountData[7]
        }
      )
    }

    return data
  }

  render() {
    return (
      <div className="sectionwisebody" >
        <MDBDataTable
          responsive
          id="secShift"
          striped
          bordered
          hover
          data={
            {
              columns: (this.state.firstArray.concat(this.state.columnsAry)).concat(this.state.lastArray),
              rows: this.processDataList()
            }
          } />
      </div>
    )
  }
}

