let testURL = 'http://35.81.75.229:3000';
let localURL = "http://10.230.5.20:3000";
let productionURL = "https://sansma.sansera.in:3000";
const baseURL = productionURL;

const pageURL = {
    login: "/",
    forgotPassword: "/forgot-password",
    dashboard: "/dashboard",
    admin: "/admin",
    manageEmployees: "/manage-employees",
    manageAppUsers: "/manage-app-users",
    managePlant: "/manage-plant",
    manageShift: "/manage-shift",
    reportDepartmentWise: "/report/department-wise",
    reportSectionWise: "/report/section-wise",
    reportPlantWise: "/report/plant-wise",
    profile: "/profile",
}

const apiPath = "api/v1/";

const activeAppUser = {
    ACTIVATE: "ACTIVATE",
    DEACTIVATE: "DEACTIVATE",
}
const activeEmployee = {
    ACTIVATE: "ACTIVATE",
    DEACTIVATE: "DEACTIVATE"
}
const blockSubadmin = {
    BLOCK: "BLOCK",
    UNBLOCK: "UNBLOCK",
}

const uploadAddAppUserFileTempletLink = baseURL + "import_template/upload_app_users_template.xlsx"
const uploadEmployeeFileTempletLink = baseURL + "import_template/upload_employees_template.xlsx"
const uploadEmployeeExtraHoursFileTempletLink = baseURL + "import_template/upload_extra_hours_template.xlsx"
const uploadEmployeeShiftTempletLink = baseURL + "import_template/upload_employee_shift_template.xlsx"

const config = {
    baseURL,
    apiPath,
    activeAppUser,
    blockSubadmin,
    uploadAddAppUserFileTempletLink,
    activeEmployee,
    uploadEmployeeFileTempletLink,
    uploadEmployeeExtraHoursFileTempletLink,
    uploadEmployeeShiftTempletLink,
    pageURL,
}

export default config;
