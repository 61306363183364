import '@fortawesome/fontawesome-free/css/all.min.css';
import {faFileUpload} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import Select from 'react-select';
import FadeLoader from "react-spinners/FadeLoader";
import ActionComponents from "../../actionComponents/index";
import apiCall from "../../apiCall";
import MyComponent from '../../components';
import "../../contentStyle.css";
import "../../globalStyle.css";
import Sidebar from '../../Sidebar/Sidebar';
import utils from "../../utils/index";
import AppUserDataTable from "../appUserDataTable/appUserDataTable";
import './appUsers.css';

class appUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadExcelSheetModal: false,
      uploadAddAppUserModal: false,
      uploadAppUserExcelModal: false,
      selectedOption: '',
      appUserList: [],
      loading: true,
      plantDataList: [],
      plantId: '',
      selectedPlant: '',
      showPopUp: false,
      newPlant: '',
      oldPlant: '',
      apiCallDone: false,
      isPageLoading: false,
    }
  }

  async componentDidMount() {
    await this.getAppUser()
    await this.getPlantList("")
  }

  async getAppUser(plantID) {
    this.setState({ isPageLoading: true })
    let object = {
      plantId: plantID
    }
    try {
      let response = await apiCall.getAppUser(object);
      if (response.permissionChanged) {
        this.setState({
          isPageLoading: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        this.setState({ isPageLoading: false })
        if (response.data.status) {
          this.setState({
            appUserList: response.data.data
          })
        }
        else {
          if (response.data.error.errorcode === 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem('token')
            this.setState({ apiCallDone: true })
          }
        }
      }
      this.setState({ apiCallDone: true })
    }
    catch (e) {
      this.setState({ isPageLoading: false })
      utils.utilFunction.toastCall(e)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;

        plantListOG = response.data.data
        plantList = this.state.plantDataList
        i = 0
        for (i; i < plantListOG.length; i++) {
          plantList.push(
              {
                'label': plantListOG[i].Name,
                'value':i,
                'Plant_Id': plantListOG[i].BRCCODE,
              })
        }

        this.setState({
          plantDataList: plantList
        })

        return plantList
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  handleUploadAppUser() { this.setState({ uploadAddAppUserModal: true }) }

  changePasswordModalClose() { this.setState({ uploadAddAppUserModal: false }) }

  handleUploadClose() { this.setState({ uploadExcelSheetModal: false }) };

  handleUploadAppUserExcelClose() { this.setState({ uploadAppUserExcelModal: true }) }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login}} />

    return (
        <div className='custom-container'>
          <MyComponent.ReLoginPopUp
              show={this.state.showPopUp}
              newPlant={this.state.newPlant}
              oldPlant={this.state.oldPlant}
          />

          <ActionComponents.AddAppUser
              show={this.state.uploadAddAppUserModal}
              onHide={() => this.changePasswordModalClose()}
              callBack={() => this.changePasswordModalClose()}
              callBack2={() => {
                this.setState
                ({ appUserList: [] })
                this.getAppUser()
              }}
          />

          <ActionComponents.UploadExcelsheet
              show={this.state.uploadExcelSheetModal}
              onHide={() => this.handleUploadClose()}
          />

          <ActionComponents.UploadAppUser
              show={this.state.uploadAppUserExcelModal}
              onHide={()=> this.setState({uploadAppUserExcelModal:false})}
              callBack2={() => {
                this.setState({ appUserList: [] })
                this.getAppUser()
              }}
          />

          <MyComponent.Header />

          <div className='row-2'>
            <div className="sidebar-view">
              <div className='sidebar-static'>
                <Sidebar />
              </div>
            </div>
            <div className="content-view">
              <div className="sub-content-view">
                <Row className="heading">
                  <Col>
                    <h4>{utils.strings.heading.manageAppUsers} </h4>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <MyComponent.HeroButton
                        isBtnLoading={this.state.isLoadingBtn}
                        btnText={utils.strings.buttonText.addAppUser}
                        isGradient={true}
                        onBtnClick={() => {
                          this.handleUploadAppUser()
                        }}
                    />
                  </Col>
                </Row>

                <div
                    className={'verticaly-alligned'}
                    onClick={() => this.handleUploadAppUserExcelClose()}
                >
                  <FontAwesomeIcon icon={faFileUpload} size="2x" title="Upload App user Sheet" className="favIcons" />
                  &nbsp;&nbsp;&nbsp;
                  <span className="favIconsText"> {utils.strings.buttonText.appUsers} </span>
                </div>

                <div>
                  {
                    localStorage.getItem("adminType") === "SuperAdmin" ?
                        <>
                          <div className="gap1" />
                          <div className="input-dimenstions">
                            <Select
                                isClearable
                                placeholder="Select Plant"
                                value={this.state.selectedPlant}
                                onChange={(selectedPlant) => {
                                  let val = selectedPlant === null ? "" : selectedPlant.Plant_Id
                                  this.setState({
                                        selectedPlant: selectedPlant === null ? "" : selectedPlant,
                                        plantId: val,
                                        appUserList: []
                                      },
                                      () => this.getAppUser(val)
                                  );
                                }}
                                options={this.state.plantDataList}
                            />
                          </div>
                        </>
                        :
                        <div />
                  }
                </div>

                <div className="gap1" />
                {
                  (this.state.apiCallDone) ?
                      (this.state.appUserList).length > 0 ?
                          <AppUserDataTable
                              dataListOG={this.state.appUserList}
                              callBack={() => {
                                this.setState({ appUserList: [] })
                                this.getAppUser()
                              }
                              }
                          /> :
                          <div className="placeHolder-view" >No Data</div>
                      :
                      <Col id="overlay" >
                        <Col id="loader">
                          <FadeLoader
                              size={"30"}
                              width={"10"}
                              radius={"8"}
                              color={"#08334D"}
                              loading={this.state.loading}
                          />
                        </Col>
                      </Col>
                }
              </div>
            </div>
          </div>
          {
            this.state.isPageLoading ?
                <Col id="overlay" >
                  <Col id="loader">
                    <FadeLoader
                        size={"30"}
                        width={"10"}
                        radius={"8"}
                        color={"#08334D"}
                        loading={this.state.loading}
                    />
                  </Col>
                </Col>
                :
                <div />
          }
        </div>
    )
  }
}

export default appUsers;
