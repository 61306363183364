import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import {Card, Col, Image, Row} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import ActionComponents from "../actionComponents/index";
import apiCall from "../apiCall";
import myComponent from '../components';
import Sidebar from '../Sidebar/Sidebar';
import utils from "../utils/index";
import './profile.css';
import "../globalStyle.css"

export default class profilePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      changePasswordModal: false,
      profileData: [],
      isPageLoading: false,
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
      profilePic: utils.images.placeholderImage,
      profilePicActualImage: "",
      profilePicError: "",
      refreshHeader: false,
    }
  }

  handleChangePassword() {
    this.setState({ changePasswordModal: true })
  }

  componentDidMount() {
    this.setState({ isPageLoading: true })
    this.profile();
  }

  getTimeDiff(profileData) {
    let data = profileData.LastLoggedIn
    return moment(data).format('DD MMM, YYYY (hh:mm A)')
  }

  async profile() {

    try {
      let response = await apiCall.apiforProfile();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else {
        if (response.data) {
          if (response.data.status) {
            this.setState({
              isPageLoading: false,
              profileData: response.data.data,
              profilePic: response.data.data.ProfilePic ? utils.config.baseURL + response.data.data.ProfilePic : this.state.profilePic,
            })
          }
          else {
            if (response.data.error.errorcode == 1006) {
              utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
              localStorage.removeItem('token')
              this.setState({ isPageLoading: false })
            }
          }
        }
      }
      this.setState({ isPageLoading: false })
    }
    catch (error) {
      return error;
    }
  }

  async updateProfilePic() {
    this.setState({ isPageLoading: true })
    try {
      let id = localStorage.getItem(utils.strings.localStorageItem.adminId);
      let formData = new FormData();
      let photo
      photo = this.state.profilePicActualImage
      if (photo !== "" && photo !== undefined && photo !== null) {
        if (this.state.profilePicActualImage === utils.images.placeholderImage) {
          formData.append('image', null)
        }
        else {
          formData.append('image', photo, photo.name, photo.type)
        }
        formData.append('adminId', id)
        let response = await apiCall.updateAdminProfilePic(formData);
        if (response.permissionChanged) {
          this.setState({
            showPopUp: true,
            newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
            oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          })
        }
        else {
          if (response.data) {
            if (response.data.status) {
              this.setState({
                profileData: response.data.data,
                profilePic: response.data.data.ProfilePic ? utils.config.baseURL + response.data.data.ProfilePic : this.state.profilePic,
              })
            }
            else {
              if (response.data.error.errorcode == 1006) {
                utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
                localStorage.removeItem('token')
                this.setState({ isPageLoading: false })
              }
            }
          }
        }
        this.setState({ refreshHeader: !this.state.refreshHeader })
        this.profile()
      }

      this.setState({ isPageLoading: false })
    }
    catch (e) {
      this.setState({ isPageLoading: false })
    }
  }

  changePasswordModalClose() {
    this.setState({ changePasswordModal: false })
  }

  selectImage() {
    this.refs.fileUploader.click();
  }

  onImageChange(event) {
    let imageData = event.target.files
    if (imageData && imageData[0]) {
      if (utils.utilFunction.validatePhoto(imageData[0].name)) {
        let reader = new FileReader();
        this.setState({
          profilePicError: '',
          profilePicActualImage: event.target.files[0]
        })

        reader.onload = (e) => {
          this.setState({
                profilePic: e.target.result
              },
              () => this.updateProfilePic()
          )
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      else {
        utils.utilFunction.toastCall(utils.strings.errorMsg.selectValImgFile)
      }
    }
  }

  render() {
    const { profileData } = this.state;
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />

    return (
        <div className='custom-container'>
          <myComponent.ReLoginPopUp
              show={this.state.showPopUp}
              newPlant={this.state.newPlant}
              oldPlant={this.state.oldPlant}
          />
          <ActionComponents.ChangePassword
              show={this.state.changePasswordModal}
              onHide={() => this.changePasswordModalClose()}
              item={profileData}
              showCurrPswd={true}
              callBack={() => this.changePasswordModalClose()}
              callBack2={() => this.changePasswordModalClose()}
          />
          {
            this.state.refreshHeader ?
                <myComponent.Header />
                :
                <div>
                  <myComponent.Header />
                </div>
          }
          <div className='row-2'>
            <div className="sidebar-view">
              <div className='sidebar-static'>
                <Sidebar />
              </div>
            </div>
            <div className="content-view">
              <div className="sub-content-view">
                <Row className="heading">
                  <Col>
                    <h4>{utils.strings.heading.profile} </h4>
                  </Col>
                </Row>
                <div className='profile-card-container' md={{ span: 11 }} sm={{ span: 8, offset: 3 }} lg={{ span: 8, offset: 3 }} style={{ marginTop: "59px" }}>
                  <Card className="profiledesign" >
                    <Row>
                      <Col md={{ span: 4 }} sm={{ span: 4 }} lg={{ span: 4 }} style={{ marginTop: "30px", textAlign: "center" }}>
                        <Image width="200px" height="200px" src={this.state.profilePic} className="image rounded-circle " />
                        <div className='vertical-dap5' />
                        <div className="pointer" onClick={() => this.selectImage()}>
                          Upload Image
                        </div>
                        <div className='error-style'>{this.state.profilePicError}</div>
                        <input type="file" id="file" ref="fileUploader" onChange={(event) => this.onImageChange(event)} style={{ display: "none" }} />
                      </Col>
                      <Col md={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }} style={{ marginTop: "30px" }}>
                        <h2 style={{ textTransform: "capitalize" }}>{profileData.Username}</h2>
                        <p>{profileData.AdminType}</p>
                        <Row>
                          <Col md={{ span: 4, offset: 8 }} sm={{ span: 4, offset: 8 }} lg={{ span: 4, offset: 8 }}>
                            <a style={{color:"green"}} onClick={() => this.handleChangePassword()}><FontAwesomeIcon icon={faEdit} />&nbsp;Reset Password</a>
                          </Col>
                        </Row>
                        <hr />
                        <Col>
                        </Col>
                        <Col>
                          <Row>
                            <Col md={3}>Username:</Col>
                            <Col md={5}>{profileData.Username}</Col>
                          </Row>
                          <br />
                          <Row>
                            <Col md={3}>Email:</Col>
                            <Col md={5}>{profileData.EmailId}</Col>
                          </Row>
                          <br />
                          <Row>
                            <Col md={3}>LastLogged In:</Col>
                            <Col md={5}>{profileData.LastLoggedIn ? this.getTimeDiff(profileData) : ""}</Col>
                          </Row>
                          <br />
                        </Col>
                        {/*<ul>*/}
                        {/*  <Form.Label style={{ marginRight: "80px", marginTop: "30px" }}><h5>Username:</h5></Form.Label>{profileData.Username}*/}
                        {/*  <br />*/}
                        {/*  <Form.Label style={{ marginRight: "120px" }}><h5>Email:</h5></Form.Label>{profileData.EmailId}*/}
                        {/*  <br />*/}
                        {/*  <Form.Label style={{ marginRight: "45px" }}><h5>LastLogged In:</h5></Form.Label>{profileData.LastLoggedIn ? this.getTimeDiff(profileData) : ""}*/}
                        {/*  <br />*/}
                        {/*</ul>*/}
                      </Col>
                    </Row>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          {
            this.state.isPageLoading ?
                <Col id="overlay" >
                  <Col id="loader">
                    <FadeLoader
                        size={"30"}
                        width={"10"}
                        radius={"8"}
                        color={"#08334D"}
                        loading={this.state.loading}
                    />
                  </Col>
                </Col>
                :
                <div />
          }
        </div>
    )
  }
}