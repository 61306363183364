import { MDBCol, MDBPageItem, MDBPageNav, MDBPagination, MDBRow } from "mdbreact";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import "../../globalStyle.css";
import './pagination.css';

export default class Pagination extends Component {


    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            division: 0,
            divisionArr: [],
            activePage: 1,
            goToPage:1,
        }
    }

    static propTypes = {
        pageCount: PropTypes.any,
        onBtnClick: PropTypes.func,
        currentPage: PropTypes.any,
    }

    static defaultProps = {
        pageCount: 1,
        currentPage: 1,
    }

    componentDidMount() {
        this.per10()
        this.setState({
            activePage: this.props.currentPage,
            goToPage: this.props.currentPage,
        },
            // ()=> this.redirectToCard()
        )

        this.scrollToPage(this.props.currentPage)
    }

    per10() {
        let pageCount, res, i, data = []
        pageCount = this.props.pageCount

        res = pageCount / 10
        if (pageCount % 10 !== 0) {
            res = (res | 0) + 1
        }

        for (i = 1; i <= res; i++) {
            data.push(i)
        }
        this.setState({
            division: res,
            divisionArr: data,
        })
    }

    scrollToPage(){
        return(
            <a href={"_"} />
        )
    }

    getRange(){
        let lowRange = 0, upperRange = 0
        for(let i=1; i <= (this.state.divisionArr).length;i++){

            // if (this.props.currentPage< i || this.props.currentPage> i+9 ){
            //     lowRange = i
            //     upperRange= i+9
            //     break;
            // }
        }
    }

    renderPage(item, index) {
        return (
            <MDBPageItem
                id={item}
                onClick={() => {
                    this.setState({goToPage:item})
                    let { pageCallBack } = this.props
                    pageCallBack(item)
                    this.setState({ activePage: item })
                }}
                active={item === this.state.activePage ? true : false}>
                <MDBPageNav>
                    {item} <span className="sr-only"></span>
                </MDBPageNav>
            </MDBPageItem>
        )
    }

    redirectToCard() {
        this.refs.redirectToElement.click();
    }

    render() {
        let arr = this.state.divisionArr
        if (arr.length === 1 || arr.length === 0) {
            return (
                <div />
            )
        }
        else {
            let id = "#"+this.state.activePage
            return (
                <MDBRow>
                    <a href={id} ref='redirectToElement' />
                    {this.scrollToPage()}
                    <MDBCol>
                        <MDBPagination className="mb-5">
                            <MDBPageItem
                                onClick={() => {
                                    if (this.state.activePage !== 1) {
                                        let page = this.state.activePage - 1
                                        this.setState({goToPage:page})
                                        let { pageCallBack } = this.props
                                        pageCallBack(page)
                                        this.setState({ activePage: page })
                                    }
                                }}
                                disabled={this.state.activePage === 1 ? true : false}
                            >
                                <MDBPageNav aria-label="Previous">
                                    <span aria-hidden="true">Previous</span>
                                </MDBPageNav>
                            </MDBPageItem>

                            <div className='page-view'>
                                {
                                    (this.state.divisionArr).map((item, index) =>
                                        this.renderPage(item, index)
                                    )
                                }
                            </div>

                            <MDBPageItem
                                disabled={this.state.activePage === (this.state.divisionArr).length ? true : false}
                                onClick={() => {
                                    if (this.state.activePage !== (this.state.divisionArr).length) {
                                        let page = this.state.activePage + 1
                                        this.redirectToCard()
                                        this.scrollToPage(page)

                                        this.setState({goToPage:page})
                                        let { pageCallBack } = this.props
                                        pageCallBack(page)
                                        this.setState({ activePage: page })
                                    }
                                }}
                            >
                                <MDBPageNav aria-label="Previous">
                                    <span aria-hidden="true">Next</span>
                                </MDBPageNav>

                            </MDBPageItem>
                        </MDBPagination>
                    </MDBCol>
                </MDBRow >
            )
        }
    }

    onBtnClick() {
        if (this.props.onBtnClick !== undefined) {
            this.props.onBtnClick()
        }
    }
}
