import { faBuilding, faEdit, faKey, faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBDataTable } from 'mdbreact';
import "mdbreact/dist/css/mdb.css";
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from 'react-toastify';
import ActionComponents from "../../actionComponents/index";
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import "../../globalStyle.css";
import utils from "../../utils/index";
import './adminDataTable.css';

export default class AdminDataTable extends Component {

    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            editAdminModal: false,
            changePlantModal: false,
            blockAdminModal: false,
            selectedItem: {},
            changePasswordModal: false,
            dataList: [],
            editEmail: '',
            editAdminType: '',
            editUserName: '',
            isEditModalOpen: false,
            adminId: '',
            editEmailError: '',
            plantDataList: [],
            selectedUserID: '',
            showPopUp: false,
            newPlant: "",
            oldPlant: "",
            profilePic: utils.images.placeholderImage,
            profilePicActualImage: "",
            profilePicError: "",
            isPageLoading: false,
        }
    }

    static propTypes = {
        dataListOG: PropTypes.array,
    }

    static defaultProps = {
        dataListOG: [],
    }

    componentDidMount() {
        this.getPlantList()
    }

    async getPlantList() {
        try {
            let response = await apiCall.getPlantList();

            if (response.data.status) {
                let plantListOG = response.data.data, plantList = this.state.plantDataList, i = 0;
                for (i = 0; i < plantListOG.length; i++) {
                    plantList.push(
                        {
                            // 'label': plantListOG[i].Plant_Name + " , " + plantListOG[i].Plant_ShortAddress,
                            // 'value': i,
                            // 'Plant_Id': plantListOG[i].Plant_Id,
                            // 'Plant_Location': plantListOG[i].Plant_Location,
                            // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,
                            label: plantListOG[i].Name,
                            value: i,
                            Plant_Id: plantListOG[i].BRCCODE,
                            barcode:plantListOG[i].BRCCODE
                        })
                }
                this.setState({ plantDataList: plantList })
                return plantList
            }
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }

    async updateProfilePic() {
        this.setState({ isPageLoading: true })
        try {
            let id = this.state.editUserId
            let { callBack } = this.props
            let formData = new FormData();
            let photo = this.state.profilePicActualImage
            if (photo !== "" && photo !== undefined && photo !== null) {
                formData.append('image', photo, photo.name, photo.type)
                formData.append('adminId', id)
                let response = await apiCall.updateAdminProfilePic(formData);
                if (response.data.status) {
                    callBack()
                    toast.success("Image updated")
                }
            }
            this.setState({ isPageLoading: false })
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }

    async update() {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.editEmail)) {
            try {
                let object = {
                    emailId: this.state.editEmail,
                    adminId: this.state.editUserId,
                }
                let response = await apiCall.updateAdmin(object);
                let { callBack } = this.props
                if (response.permissionChanged) {
                    this.setState({
                        showPopUp: true,
                        newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
                        oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
                    })
                }
                else if (response.data) {
                    if (response.data.status) {
                        callBack()
                        toast.success("Subadmin updated")
                        this.setState({ isEditModalOpen: false })
                    }
                    else if (response.data.error.errorcode === 1006) {
                        utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
                        localStorage.removeItem('token')
                        this.setState({ isLoadingBtn: false })
                    }
                }
                this.setState({ isLoadingBtn: false })
            }
            catch (e) {
                this.setState({ isLoadingBtn: false })
                utils.utilFunction.toastCall(e.response)
            }
        }
        else {
            this.setState({ editEmailError: 'Invalid email', isLoadingBtn: false })
        }
    }

    renderaAtion(data, i) {
        return (
            <div className="btn-group" aria-label="Basic example">
                {
                    data[i].IsActive ?
                        <>
                            <div className='pointer action-color' style={{ marginLeft: "8px", marginRight: "8px" }}
                                 onClick={() => { this.editModalSetData(data[i]) }
                                 }
                                 title={data[i].AdminType === "SuperAdmin" ? 'Edit Super Admin' : 'Edit Sub Admin'}>
                                <a><FontAwesomeIcon icon={faEdit} /></a>
                            </div>

                            <div>|</div>

                            <div className='pointer action-color' style={{ marginLeft: "8px", marginRight: "8px" }}
                                 onClick={() => {
                                     this.handlChangePlant(data[i])
                                 }}
                                 title='Change Plant'
                            >
                                <a ><FontAwesomeIcon icon={faBuilding} /></a>
                            </div>
                            <div>|</div>
                        </>
                        :
                        <div />
                }

                {
                    data[i].IsActive ?
                        <div className='pointer action-color' style={{ marginLeft: "8px", marginRight: "8px" }} onClick={() => this.handleBlockAdmin(data[i], "BLOCK")} title="Block" ><a ><FontAwesomeIcon icon={faUserSlash} /></a></div>
                        :
                        <div className='pointer action-color' style={{ marginLeft: "8px", marginRight: "8px" }} onClick={() => this.handleBlockAdmin(data[i], "UNBLOCK")} title="UnBbock" ><a ><FontAwesomeIcon icon={faUserCheck} /></a></div>
                }

                {
                    data[i].IsActive ?
                        <>
                            <div>|</div>
                            <div className='pointer action-color' style={{ marginLeft: "8px" }} onClick={() => this.handleChangePassword(data[i])} title="Change Password"><a ><FontAwesomeIcon icon={faKey} /></a></div>
                        </>
                        :
                        <div />
                }

            </div>
        )
    }

    renderAdminAction(data, i) {
        return (
            <div
                className='pointer action-color' style={{ textAlign: "left" }}
                onClick={() => this.editModalSetData(data[i])}
                title={data[i].AdminType === "SuperAdmin" ? 'Edit Super Admin' : 'Edit Sub Admin'}
            > &nbsp;&nbsp;<FontAwesomeIcon icon={faEdit} /></div>
        )
    }

    getTimeDiff(listOG, i) {
        let data = listOG[i].LastLoggedIn
        return moment(data).format('DD MMM, YYYY (hh:mm A)')
    }

    processDataList() {
        let listOG = this.props.dataListOG, data = [], i
        if (listOG.length > 0) {
            for (i = 0; i < listOG.length; i++) {
                data.push(
                    {
                        username: listOG[i].Username,
                        email: listOG[i].EmailId,
                        admintype: listOG[i].AdminType,
                        status: listOG[i].IsActive ? "Active" : "Inactive",
                        lastloggedin: listOG[i].LastLoggedIn ? this.getTimeDiff(listOG, i) : "NA",
                        // plantname: listOG[i].PlantId === null ? "NA" : utils.utilFunction.getPlantName(listOG[i].PlantId),
                        plantname: listOG[i].PlantId === null ? "NA" : listOG[i].Name,
                        actions: listOG[i].AdminType === "SuperAdmin" ? this.renderAdminAction(listOG, i) : this.renderaAtion(listOG, i)
                    }
                )
            }
            this.setState({
                dataList: data
            })
            return data
        }
    }

    data = {
        columns: [
            {
                field: 'username',
                label: 'User Name',
                sort: 'asc',
            },
            {
                field: 'email',
                label: 'Email',
                sort: 'asc',
            },
            {
                field: 'admintype',
                label: 'Admin Type',
                sort: 'asc',
            },
            {
                field: 'status',
                label: 'Status',
                sort: 'asc',
            },
            {
                field: 'lastloggedin',
                label: 'Last Logged In',
                sort: 'asc',
            },
            {
                field: 'plantname',
                label: 'Plant Name',
                sort: 'asc',
            },
            {
                field: 'actions',
                label: 'Actions',
                sort: 'asc',
            },
        ],
        rows: this.processDataList()
    }

    matchPlant(row, id) {
        let data = this.state.plantDataList, i
        let prefix = "Plant - "

        for (i = 0; i < data.length; i++) {
            // if (row.PlantId === data[i].Plant_Id) {
            if (row.PlantId == data[i].barcode) {
                let selectedData = [{
                    // 'label': utils.utilFunction.getPlantName(data[i].Plant_Id),
                    // 'value': i,
                    // 'Plant_Id': data[i].Plant_Id,
                    // 'Plant_Location': data[i].Plant_Location,
                    // 'Plant_ShortAddress': data[i].Plant_ShortAddress,

                    'label': data[i].label,
                    'value': i,
                    'Plant_Id': data[i].barcode,
                }]

                this.setState({
                    selectedUserID: id,
                    changePlantModal: true,
                    selectedItem: selectedData,
                })
                break;
            }
        }
        this.setState({ changePlantModal: true })
    }

    handlChangePlant(row) {
        this.matchPlant(row, row.Admin_id)
    }

    handleBlockAdmin(row, blockType) {
        this.setState({
            blockAdminModal: true,
            selectedItem: row,
            blockType: blockType,
        })
    }

    handleChangePassword(row) {
        this.setState({ changePasswordModal: true, selectedItem: row })
    }

    handleEditChange(email) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.editEmail)) {
            this.setState({ editEmailError: 'Invalid email' })
        }
        else {
            this.setState({ editEmailError: '' })
        }
        this.setState({ editEmail: email.target.value })
    }

    selectImage() {
        this.refs.fileUploader.click();
    }

    onImageChange(event) {
        let imageData = event.target.files
        if (imageData && imageData[0]) {
            if (utils.utilFunction.validatePhoto(imageData[0].name)) {
                let reader = new FileReader();
                this.setState({
                    profilePicError: '',
                    profilePicActualImage: event.target.files[0]
                })

                reader.onload = (e) => {
                    this.setState({
                            profilePic: e.target.result
                        },
                        () => this.updateProfilePic()
                    )
                };
                reader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.setState({
                    profilePicError: utils.strings.errorMsg.selectValImgFile,
                    profilePic: "",
                })
            }
        }
    }

    EditAdminModalClose() { this.setState({ isEditModalOpen: false, editEmailError: '' }) };

    editModal() {
        return (
            <Modal
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.isEditModalOpen}
                onHide={() => this.EditAdminModalClose()}
            >
                <Modal.Header closeButton className="forHeader">
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <p className="forParagraph">{utils.strings.adminString.editAdmin.editAdmin}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <>
                            <div className="horizontal-center">
                                <Image
                                    src={this.state.profilePic}
                                    className='image-placeHolder'
                                    roundedCircle />
                            </div>
                            <div className='vertical-dap5' />
                            <div className="horizontal-center">
                                <div className="pointer" onClick={() => this.selectImage()}>
                                    {utils.strings.buttonText.uploadImage}
                                </div>
                            </div>
                            <div className="horizontal-center">
                                <div className='error-style'>{this.state.profilePicError}</div>
                            </div>
                            <input
                                type="file" id="file" ref="fileUploader"
                                onChange={(event) => this.onImageChange(event)}
                                style={{ display: "none" }}
                            />
                        </>

                        <Form>
                            <Form.Group as={Row} controlId="formHorizontalName">
                                <Col>
                                    <Form.Label>{utils.strings.inputLabel.userName}</Form.Label>
                                    <Form.Control type="text" value={this.state.editUserName} disabled />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} >
                                <Col>
                                    <Form.Label>{utils.strings.inputLabel.email}</Form.Label>
                                    <Form.Control type="text" value={this.state.editEmail} onChange={(val) => { this.handleEditChange(val) }} />
                                </Col>
                            </Form.Group>
                            <h5 className='error-style'>{this.state.editEmailError}</h5>
                            <Form.Group as={Row} controlId="formHorizontalMobile">
                                <Col >
                                    <Form.Label>{utils.strings.inputLabel.adminType}</Form.Label>
                                    <Form.Control type="text" value={this.state.editAdminType} disabled />
                                </Col>
                            </Form.Group>
                            <div className='d-flex justify-content-end'>
                                <myComponent.HeroButton
                                    isBtnLoading={this.state.isLoadingBtn}
                                    btnText={utils.strings.buttonText.save}
                                    isGradient={true}
                                    onBtnClick={() => {
                                        this.setState({ isLoadingBtn: true, })
                                        if (!this.state.isLoadingBtn) {
                                            this.update()
                                        }
                                    }}
                                />
                            </div>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    editModalSetData(row) {
        this.setState({
            profilePic: row.ProfilePic !== "" && row.ProfilePic !== undefined && row.ProfilePic !== null ? utils.config.baseURL + row.ProfilePic : utils.images.placeholderImage,
            editEmail: row.EmailId,
            editAdminType: row.AdminType,
            editUserName: row.Username,
            editUserId: row.Admin_id,
            isEditModalOpen: true,
        })
    }

    callBackToAdmin() {
        let { callBack } = this.props
        callBack()
    }

    changePasswordModalClose() { this.setState({ changePasswordModal: false }) }

    blockAdminModalClose() {
        this.setState({ blockAdminModal: false })
    };

    changePlantModalClose() {
        this.setState({ changePlantModal: false })
    }

    render() {
        if (!localStorage.getItem(utils.strings.localStorageItem.token))
            return <Redirect to={{ pathname: utils.config.pageURL.login }} />
        return (
            <div className="admindatatable">
                <myComponent.ReLoginPopUp
                    show={this.state.showPopUp}
                    newPlant={this.state.newPlant}
                    oldPlant={this.state.oldPlant}
                />
                {this.editModal()}
                <div style={{ backgroundColor: "#FFFFFF" }}>
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        hover
                        data={this.data}
                    />
                </div>

                <ActionComponents.ChangePlant
                    show={this.state.changePlantModal}
                    userid={this.state.selectedUserID}
                    item={this.state.selectedItem}
                    onHide={() => this.changePlantModalClose()}
                    callBack={() => this.changePlantModalClose()}
                    callBack2={() => {
                        let { callBack } = this.props
                        callBack()
                    }}
                />

                <ActionComponents.blockadmin
                    show={this.state.blockAdminModal}
                    type={this.state.blockType}
                    item={this.state.selectedItem}
                    onHide={() => this.blockAdminModalClose()}
                    callBack={() => this.callBackToAdmin()}
                />

                <ActionComponents.ChangePassword
                    show={this.state.changePasswordModal}
                    onHide={() => this.changePasswordModalClose()}
                    showCurrPswd={false}
                    item={this.state.selectedItem}
                    callBack={() => this.changePasswordModalClose()}
                    callBack2={() => this.callBackToAdmin()}
                />

                {
                    this.state.isPageLoading ?
                        <Col id="overlay" >
                            <Col id="loader">
                                <FadeLoader
                                    size={"30"}
                                    width={"10"}
                                    radius={"8"}
                                    color={"#08334D"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Col>
                        :
                        <div />
                }

            </div>
        )
    }
}