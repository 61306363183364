import React, { Component } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import apiCall from "../../apiCall";
import "../../globalStyle.css";
import utils from "../../utils/index";
import myComponent from "../index";

export default class ReLoginPopUp extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            isLoadingBtn: false,
            redirect: false,
        }
    }

    async logout() {
        localStorage.removeItem(utils.strings.localStorageItem.token)
        this.setState({ isLoadingBtn: false })
        try {
            await apiCall.logout();
            this.setState({ redirect: true })
        }
        catch (e) {
            this.setState({ isLoadingBtn: false })
            utils.utilFunction.toastCall(e.response)
        }
    }

    render() {
        if (this.state.redirect)
            return <Redirect to={{ pathname: utils.config.pageURL.login }} />

        return (
            <Modal
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                {...this.props}
            >
                <Modal.Header className="forHeader">
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <p className="forParagraph">{utils.strings.reLoginPopUpString.permissionChanged}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 style={{ fontFamily: "Sans-serif", textAlign: "center" }}><strong>{utils.strings.reLoginPopUpString.contStr1} {this.props.oldPlant} {utils.strings.reLoginPopUpString.contStr2} {this.props.newPlant} </strong></h5>
                    <h6 style={{ fontFamily: "Sans-serif", textAlign: "center" }}><strong>{utils.strings.reLoginPopUpString.line2}</strong></h6>
                    <Container className='d-flex justify-content-end'>
                        <myComponent.HeroButton
                            isBtnLoading={this.state.isLoadingBtn}
                            btnText={utils.strings.buttonText.reLogin}
                            isGradient={true}
                            onBtnClick={() => {
                                this.setState({ isLoadingBtn: true })
                                if (!this.state.isLoadingBtn)
                                    this.logout()
                            }}
                        />
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    onBtnClick() {
        if (this.props.onBtnClick !== undefined) {
            this.props.onBtnClick()
        }
    }
}