import subDays from "date-fns/subDays";
import * as moment from 'moment';
import React, { Component } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DatePicker from "react-datepicker";
import { Chart } from "react-google-charts";
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import FadeLoader from "react-spinners/FadeLoader";
import apiCall from "../apiCall";
import MyComponent from "../components/index";
import Sidebar from '../Sidebar/Sidebar';
import utils from "../utils/index";
import './dashboard.css';

export default class dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultPlant:[],
      startDate: '',
      setStartDate: '',
      plantDataList: [],
      selectedOption: '',
      countDataList: [],
      totalAppUsers: "...",
      totalEmployees: "...",
      totalPlants: "...",
      totalStaffs: "...",
      deptList: [],
      attendenceList: [],
      attendanceRenderList: [],
      currMonth: new Date(),
      selectedPlant: {
        // label: "Plant - 1",
        // value: 0,
        // Plant_Id: 1001,
      },
      selectedPlantDept: "",
      currMonthDept: new Date(),
      startDateDept: "",
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
      isPageLoading: false,

      APICallDone:false,
      wait:false,
    }
  }

  async componentDidMount() {
    this.setState({APICallDone: false, isPageLoading:true})
    await this.getPlantList()
    await this.dashboardCount()
    await this.departmentWise()
    await this.attendance()
    this.setState({
          isPageLoading:false,
        }
        ,()=> this.setState({APICallDone: true})
    )
  }

  async getPlantList(){
    this.setState({ isPageLoading: true})
    try {
      let response = await apiCall.getPlantList();
      if (response.data) {
        if (response.data.status) {
          let plantListOG = response.data.data, plantList = [], i = 0;
          for (i; i < plantListOG.length; i++) {
            plantList.push(
                {
                  label: plantListOG[i].Name,
                  value:i,
                  Plant_Id: plantListOG[i].BRCCODE,
                })
          }

          this.setState({
                defaultPlant:[plantList[0]],
                plantDataList: plantList,
                selectedPlant:plantList[0],
              }
          )
        }
        else {
          if (response.data.error.errorcode === 1006) {
            this.setState({ isPageLoading: false })
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem(utils.strings.localStorageItem.token)
          }
        }
      }
    }
    catch (e) {
      this.setState({ isPageLoading: false })
      utils.utilFunction.toastCall(e.response)
    }
  }

  async dashboardCount() {
    this.setState({ isPageLoading: true })
    try {
      let response = await apiCall.getDashboardCountList()
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          isPageLoading: false,
        })
      }
      if (response.data) {
        this.setState({ isPageLoading: false,})
        if (response.data.status) {
          let data = response.data.data, countDataList = this.state.countDataList
          countDataList.push({ totalAppUsers: data.totalAppUsers }, { totalEmployees: data.totalEmployees }, { totalPlants: data.totalPlants }, { totalStaffs: data.totalStaffs })
          this.setState({
            totalAppUsers: data.totalAppUsers,
            totalEmployees: data.totalEmployees,
            totalPlants: data.totalPlants,
            totalStaffs: data.totalStaffs,
            countDataList,
          })
        }
        else {
          if (response.data.error.errorcode === 1006) {
            this.setState({ isPageLoading: false})
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem(utils.strings.localStorageItem.token)
          }
        }
      }
    }
    catch (e) {
      this.setState({ isPageLoading: false })
    }
  }

  async departmentWise() {
    this.setState({ isPageLoading: true })
    try {
      let object = {
        plantId: this.state.selectedPlant === "" || this.state.selectedPlant === null ? "" : this.state.selectedPlant.Plant_Id,
        month_year: moment(this.state.currMonth).format("MMM-yyyy")
      }

      let response = await apiCall.getDashboardDeptWise(object);

      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          isPageLoading: false
        })
      }
      if (response.data) {
        this.setState({ isPageLoading: false })
        if (response.data.status) {
          this.setState({
            deptList: response.data.data,
          })
        }
        else {
          if (response.data.error.errorcode === 1006) {
            this.setState({ isPageLoading: false })
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem(utils.strings.localStorageItem.token)
          }
        }
      }
      else{
        this.setState({ isPageLoading: false })
      }
    }
    catch (e) {
      this.setState({ isPageLoading: false })
    }
  }

  async attendance() {
    this.setState({ isPageLoading: true })
    try {
      let object = {
        plantId: this.state.selectedPlant === "" || this.state.selectedPlant === null ? "" : this.state.selectedPlant.Plant_Id,
        month_year: moment(this.state.currMonth).format("MMM-yyyy")
      }
      let response = await apiCall.getDashboardAttendence(object);
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
          isPageLoading: false
        })
      }
      if (response.data) {
        if (response.data.status) {
          this.setState({
            wait:true,
                attendenceList: response.data.data
              },
              () => this.processDateChartData()
          )
        }
        else {
          if (response.data.error.errorcode === 1006) {
            this.setState({ isPageLoading: false })
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem(utils.strings.localStorageItem.token)
          }
        }
        this.setState({ isPageLoading: false })
      }
      else{
        this.setState({ isPageLoading: false })
      }
    }
    catch (e) {
      this.setState({ isPageLoading: false })
    }
  }

  processDateChartData() {
    let data = this.state.attendenceList, i, data2 = []
    data2.push(['Day', 'No. of Employees'])
    for (i = 0; i < data.length; i++) {
      data2.push(
          [(i + 1).toString(), data[i].employeePresentCount]
      )
    }

    this.setState({
      attendanceRenderList: data2
    },
    ()=> {
      this.setState({wait:false})
    })
  }

  currentTime() {
    let myDate = new Date()
    let NewDate = moment(myDate).format('Do MMMM YYYY hh:mm A')
    return NewDate;
  }

  renderAttendanceView(){
    const { selectedOption } = this.state
    return(
        <Card className="attendanceOverview">
          <Row>
            <Col md={3} >
              <span>{utils.strings.dashboardString.attendanceOverview}</span>
            </Col>
            <Col className='d-flex justify-content-end'>
              <Row>
                <Col>
                  {
                    localStorage.getItem("adminType") === "SuperAdmin" ?
                        <Select
                            isClearable
                            className="plantDrop"
                            placeholder="Plant"
                            defaultValue = {this.state.selectedPlant}
                            onChange={(val) => {

                              this.setState({
                                    selectedOption,
                                    attendenceList: [],
                                    attendanceRenderList: [],
                                    isPageLoading: true,
                                    selectedPlant: val,
                                    wait:true,
                                  },
                                  () => {

                                    this.attendance()
                                    this.departmentWise()
                                  }
                              )
                            }}
                            options={this.state.plantDataList}
                        />
                        :
                        <div />
                  }
                </Col>

                <Col className='d-flex justify-content-end'>
                  <DatePicker
                      className={'form-control datePicEditEmployee'}
                      selected={this.state.startDate}
                      value={moment(this.state.currMonth).format("MMM-yyyy")}
                      onChange={(date) => {
                        this.setState({
                              setStartDate: date,
                              startDate: date,
                              attendenceList: [],
                              attendanceRenderList: [],
                              isPageLoading: true,
                              currMonth: date,
                            },
                            () => {
                              this.attendance()
                              this.departmentWise()
                            }
                        )
                      }}
                      dateFormat="MM/yyyy"
                      placeholderText="Select Month"
                      maxDate={new Date()}
                      minDate={subDays(new Date(), 365)}
                      showMonthYearPicker
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className={"attendance-view"}>

              {
                this.state.APICallDone ?

                    !this.state.wait?
                        (this.state.attendenceList).length !== 0 ?
                            <Chart
                                width="100%"
                                height={'300px'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                data={this.state.attendanceRenderList}
                                options={{
                                  chart: {
                                    title: 'Employees Attendance Per month',
                                  },
                                  legend: {position: 'none'}
                                }}
                            />
                            :
                            <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                        :
                        <div/>
                    :
                    <div/>
              }


            </Col>
          </Row>
        </Card>
    )
  }

  renderCircularDeptQuality(item) {
    let dashboardString = utils.strings.dashboardString
    let percentage = Math.round(item.employeePresentCount * 100 / item.totalEmployees);
    let perColor;
    if (percentage < dashboardString.deptCircularBarValue.small)
      perColor = dashboardString.deptCircularBarColor.small
    else if (percentage < dashboardString.deptCircularBarValue.medium)
      perColor = dashboardString.deptCircularBarColor.medium
    else if (percentage < dashboardString.deptCircularBarValue.large)
      perColor = dashboardString.deptCircularBarColor.large
    else
      perColor = dashboardString.deptCircularBarColor.largeX


    return (
        <Row>
          <Col md={4}>
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  rotation: 0.25,
                  strokeLinecap: 'butt',
                  textSize: '20px',
                  pathTransitionDuration: 0.5,
                  pathColor: perColor,
                  textColor: dashboardString.deptCircularBarColor.textColor,
                  trailColor: dashboardString.deptCircularBarColor.trailColor,
                  backgroundColor: dashboardString.deptCircularBarColor.backgroundColor,
                })}
            />
          </Col>
          <Col md={{ span: 8 }} className="gradientforDepartment">
            <div><text className="textPaddingforDepart">{item.department_Name}</text></div>
            <div><text className="fontForEmp">{item.employeePresentCount}/{item.totalEmployees} {utils.strings.dashboardString.emps}</text></div>
          </Col>
        </Row>
    )
  }

  renderCountView() {
    return (
        <>
          <Row className='widgets-row'>
            <Col sm={12} md={6} xl={3}>
              <div className='widget' >
                <div className='row-view'>
                  <div className='widget-title-view'>
                    <div className='title-font-style'>{this.state.totalPlants}</div>
                    <div className='row-view'>
                      <div className='widget-price-font-style'>{utils.strings.standaredCategories.plantS}</div>
                    </div>
                  </div>
                  <div className='tag-image-bg'>
                    <Image src={utils.images.plantIcon} className="plantImage" />
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} xl={3}>
              <div className='widget' >
                <div className='row-view'>
                  <div className='widget-title-view'>
                    <div className='title-font-style'>{this.state.totalEmployees}</div>
                    <div className='row-view'>
                      <div className='widget-price-font-style'>{utils.strings.standaredCategories.employeeS}</div>
                    </div>
                  </div>
                  <div className='tag-image-bg'>
                    <Image src={utils.images.employeeIcon} className="plantImage"/>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} xl={3}>
              <div className='widget'>
                <div className='row-view'>
                  <div className='widget-title-view'>
                    <div className='title-font-style'>{this.state.totalStaffs}</div>
                    <div className='row-view'>
                      <div className='widget-price-font-style'>{utils.strings.standaredCategories.staffS}</div>
                    </div>
                  </div>
                  <div className='tag-image-bg'>
                    <Image src={utils.images.staffIcon} className="plantImage"/>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={12} md={6} xl={3}>
              <div className='widget' >
                <div className='row-view'>
                  <div className='widget-title-view'>
                    <div className='title-font-style'>{this.state.totalAppUsers}</div>
                    <div className='row-view'>
                      <div className='widget-price-font-style'>{utils.strings.standaredCategories.appUserS}</div>
                    </div>
                  </div>
                  <div className='tag-image-bg'>
                    <Image src={utils.images.appUserIcon} className="plantImage"/>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
    )
  }

  render() {
    if (!localStorage.getItem('token'))
      return <Redirect to={{ pathname: '/' }} />

    return (
        <div className='custom-container'>
          <MyComponent.ReLoginPopUp
              show={this.state.showPopUp}
              newPlant={this.state.newPlant}
              oldPlant={this.state.oldPlant}
          />
          <MyComponent.Header/>

          <div className='row-2'>
            <div className="sidebar-view">
              <div className='sidebar-static'>
                <Sidebar/>
              </div>
            </div>
            {
              this.state.APICallDone ?
                  <div className="content-view">
                    <div className="sub-content-view scroll-style">
                      <Row className="heading">
                        <Col>
                          <h4>{utils.strings.heading.dashboard} </h4>
                        </Col>
                        <Col className="d-flex justify-content-end timeFor">
                          <text
                              className="textPaddingforTime">{utils.strings.dashboardString.today} {this.currentTime()}</text>
                        </Col>
                      </Row>
                      <div className="gap1"/>
                      {this.renderCountView()}
                      <Row>
                        <Col md={9} className="overViewMargin">
                          {this.renderAttendanceView()}
                        </Col>
                        <Col md={3} className="overViewMargin">
                          <Card className={"departmentBody"}>
                            <div className="departmentHeadDash">{utils.strings.standaredCategories.departmentS}</div>
                            <div>
                              {
                                (this.state.deptList).length !== 0 ?
                                    (this.state.deptList).map((item, index) => this.renderCircularDeptQuality(item, index))
                                    :
                                    <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                              }
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  :<div/>
            }
          </div>

          {
            this.state.isPageLoading ?
                <Col id="overlay">
                  <Col id="loader">
                    <FadeLoader
                        size={"30"}
                        width={"10"}
                        radius={"8"}
                        color={"#08334D"}
                        loading={this.state.loading}
                    />
                  </Col>
                </Col>
                :
                <div/>
          }
        </div>
    )
  }
}
