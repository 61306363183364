import React, { Component } from 'react';
import './heroSwitch.css'
import PropTypes from 'prop-types'

const activeColor = '#21669C'

export default class HeroSwitch extends Component {


    constructor(props) {
        super(props);
        this.props = props;
    }

    static propTypes = {
        explicitHeight: PropTypes.string,
        explicitWidth: PropTypes.string,
        isOn: PropTypes.bool,
        onBtnClick: PropTypes.func,
    }

    static defaultProps = {
        btnText: 'Click',
        explicitHeight: 0,
        explicitWidth: 0,
        isOn: false
    }


    render() {
        return (
            <>
                <div
                    className='switch-div'
                    onClick={this.onBtnClick.bind(this)}
                    style={{ backgroundColor: this.props.isOn ? activeColor : '#00000029' }}
                >
                    <div style={{ backgroundColor: this.props.isOn ? activeColor : '#ffffff' }} onClick={() => this.setState({ isActive: !this.props.isOn })} className='switch-dot2' />
                    <div style={{ backgroundColor: !this.props.isOn ? '#CCCDCF' : '#ffffff' }} onClick={() => this.setState({ isActive: !this.props.isOn })} className='switch-dot2' />
                </div>
            </>
        )
    }

    onBtnClick() {
        if (this.props.onBtnClick !== undefined) {
            this.props.onBtnClick()
        }
    }
}