import '@fortawesome/fontawesome-free/css/all.min.css';
import {faEdit, faUserCheck, faUserSlash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MDBDataTable} from 'mdbreact';
import "mdbreact/dist/css/mdb.css";
import moment from 'moment';
import React, {Component} from 'react';
import {Col, Container, Form, Modal, Row} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import {Redirect} from 'react-router-dom';
import Select from 'react-select';
import {toast} from 'react-toastify';
import actionComponents from "../../actionComponents/index";
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import "../../globalStyle.css";
import utils from "../../utils/index";
import './employeeDataTable.css';

export default class ManageEmployeeDataTable extends Component {

  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      deactivateModal: false,
      selectedItem: {},
      isEditEmployeeModalOpen: false,
      designation: '',
      plantname: '',
      department: '',
      category: '',
      shift: "",
      empCode: '',
      name: '',
      gender: '',
      selectedGenderOption: '',
      selectedDesignationOption: '',
      selectedCategoryOption: '',
      selectedPlantOption: '',
      selectedDeptOption: '',
      selectedSectionOption: '',
      selectedShiftOption: '',
      dateOfBirth: '',
      dateOfJoin: '',
      employeeList: [],
      employeeListOG: [],
      designationList: [],
      plantDataList: [],
      deptList: [],
      sectionList: [],
      categoryList: [],
      shiftList: [],
      isLoadingBtn: false,

      showPopUp: false,
      newPlant: "",
      oldPlant: "",

    }
  }

  componentDidMount() {
    this.getDesignation()
    this.getPlantList()
    this.getDeptList()
    this.getSection()
    this.getCategory()
  }

  async updateEmployee() {
    let { callBack2 } = this.props
    try {
      let object = {
        empCode: this.state.empCode,
        name: this.state.name,
        dateOfBirth: this.state.dateOfBirth,
        dateOfJoin: this.state.dateOfJoin,
        categoryId: (this.state.selectedCategoryOption)[0] === undefined ? (this.state.category)[0].Category_Id : (this.state.selectedCategoryOption)[0].Category_Id,
        gender: (this.state.selectedGenderOption)[0] === undefined ? (this.state.gender)[0].value : (this.state.selectedGenderOption)[0].value,
        plantId: (this.state.selectedPlantOption)[0] === undefined ? (this.state.plantname)[0].Plant_Id : (this.state.selectedPlantOption)[0].Plant_Id,
        departmentId: (this.state.selectedDeptOption)[0] === undefined ? (this.state.department)[0].Department_Id : (this.state.selectedDeptOption)[0].Department_Id,
        shiftId: (this.state.selectedShiftOption)[0] === undefined ? (this.state.shift === [] ? "" : (this.state.shift)[0].label) : (this.state.selectedShiftOption)[0].label,
        designationId: (this.state.selectedDesignationOption)[0] === undefined ? (this.state.designation)[0] === undefined ? "" : (this.state.designation)[0].Designation_Id : (this.state.selectedDesignationOption)[0].Designation_Id,
        sectionId: (this.state.selectedSectionOption)[0] === undefined ? (this.state.section)[0] === undefined ? null : (this.state.section)[0].Section_Id : (this.state.selectedSectionOption)[0].Section_Id,
      }

      let response = await apiCall.updateEmployee(object);
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          isEditEmployeeModalOpen: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        this.setState({
          isLoadingBtn: false
        })
        if (response.data) {
          if (response.data.status) {
            this.setState({ isEditEmployeeModalOpen: false })
            toast.success("Employee updated")
            callBack2()
          }
          else if (response.data.error.errorcode === 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem('token')
            this.setState({ isEditEmployeeModalOpen: false })
          }
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isEditEmployeeModalOpen: false })
        }
      }
      else if (response.error_code === 409) {
        if (response.error_message === "USER_ALREADY_EXIST") {
          utils.utilFunction.toastCall(utils.strings.errorMsg.uniqueUser)
        }
        else {
          utils.utilFunction.toastCall(response.error_message)
        }
        this.setState({ isLoadingBtn: false })
      }
      else {
        this.setState({ isLoadingBtn: false })
      }

    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e.response)
    }

  }

  async preSet(data, i) {
    let row = data[i]
    // if (row.Plant_Id !== "" || row.Plant_Id !== null) {
    //   await this.getShift(row.Plant_Id)
    // }
    // else {
    //   await this.getShift("")
    // }
    this.handleEditEmployee(data[i])
  }

  async getDesignation() {
    try {
      let response = await apiCall.getDesignation();
      if (response.data.status) {

        let designationListOG, designationList, i;

        designationListOG = response.data.data
        designationList = []
        i = 0
        for (i; i < designationListOG.length; i++) {
          designationList.push(
              {
                // 'label': designationListOG[i].designationName,
                // 'value': i,
                // 'Designation_Id': designationListOG[i].Designation_Id,

                'label': designationListOG[i].Name,
                'value': i,
                'Designation_Id': designationListOG[i].DSGID,
              })
        }
        this.setState({
          designationList: designationList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;

        plantListOG = response.data.data
        plantList = []

        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
              {
                // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
                // 'value': i,
                // 'Plant_Id': plantListOG[i].Plant_Id,
                // 'Plant_Location': plantListOG[i].Plant_Location,
                // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

                'label': plantListOG[i].Name,
                'value':i,
                'Plant_Id': plantListOG[i].BRCCODE,

              })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getDeptList() {
    try {
      let response = await apiCall.getDepartment();
      if (response.data.status) {
        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
              {
                // 'label': deptListOG[i].department_Name,
                // 'value': i,
                // 'Department_Id': deptListOG[i].Department_Id,
                // 'department_short': deptListOG[i].department_short,

                'label': deptListOG[i].Name,
                'value': i,
                'Department_Id': deptListOG[i].DPTID,
                'department_short': deptListOG[i].ShortName,
              })
        }
        this.setState({
          deptList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getSection() {
    try {
      let response = await apiCall.getSection();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {

        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
              {
                // 'label': deptListOG[i].section_Name,
                // 'value': i,
                // 'Section_Id': deptListOG[i].Section_Id,

                'label': deptListOG[i].Name,
                'value': i,
                'Section_Id': deptListOG[i].SECID,
              })
        }


        this.setState({
          sectionList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getCategory() {
    try {
      let response = await apiCall.getCategory();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data && response.data.status) {

        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
              {
                // 'label': deptListOG[i].categoryName,
                // 'value': i,
                // 'Category_Id': deptListOG[i].Category_Id,

                'label': deptListOG[i].Name,
                'value': i,
                'Category_Id': deptListOG[i].CTGID,
              })
        }
        this.setState({
          categoryList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getShift() {
    try {
      let object = {
        // plantId: val
      }
      let response = await apiCall.getShift(object);

      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {
        let shiftListOG, shiftList, i;

        shiftListOG = response.data.data
        shiftList = []

        for (i = 0; i < shiftListOG.length; i++) {
          shiftList.push(
              {
                // 'label': shiftListOG[i].Shift,
                // 'value': i,
                // 'startTime': shiftListOG[i].startTime,
                // 'endTime': shiftListOG[i].endTime,

                label: shiftListOG[i].SFTID,
                value: i,
                startTime: shiftListOG[i].SFTSTTime,
                endTime: shiftListOG[i].SFTEDTime,
              })
        }

        this.setState({
          shiftList: shiftList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  renderAction(listOG, i) {
    return (
        <div className='action-view'>
          {
            listOG[i].status ?
                <>
                  <div className='pointer action-color'
                       onClick={() => { this.preSet(listOG, i) }}
                       title="Edit Employee"><FontAwesomeIcon icon={faEdit} /></div>
                </>
                :
                <div />
          }
          {

            listOG[i].status ?
                <>
                  <div>|</div>
                  <div className='pointer action-color' onClick={() => this.handleDeactivateEmployee(listOG[i], utils.config.activeEmployee.DEACTIVATE)} title="Deactivate Employee"><FontAwesomeIcon icon={faUserSlash} /></div>
                </>
                :
                <div className='pointer action-color' onClick={() => this.handleDeactivateEmployee(listOG[i], utils.config.activeEmployee.ACTIVATE)} title="Activate Employee"><FontAwesomeIcon icon={faUserCheck} /></div>

          }
        </div>
    )
  }

  handleDeactivateEmployee(row, activationType) {
    this.setState({
      deactivateModal: true,
      selectedItem: row,
      activationType: activationType,
    })
  }

  processDataList() {

    let listOG = this.props.item.employees
    let data = []
    let empId, empName, designation, PlantId, department, section, category, status, shift, _in, out, attendance, eh
    if (listOG.length > 0) {
      let i
      for (i = 0; i < listOG.length; i++) {

        empId = listOG[i].Employee_Id !== null ? listOG[i].Employee_Id : "NA"
        empName = listOG[i].employeeName !== null ? listOG[i].employeeName : "NA"
        designation = listOG[i].designationName !== null ? listOG[i].designationName : "NA"
        PlantId = listOG[i].plant_Id !== null ? listOG[i].plant_Name : "NA"
        department = listOG[i].department_Name !== null ? listOG[i].department_Name : "NA"
        section = listOG[i].section_Name !== null ? listOG[i].section_Name : "NA"
        category = listOG[i].categoryName !== null ? listOG[i].categoryName : "NA"
        shift = listOG[i].Shift !== null ? listOG[i].Shift : "NA"
        attendance = listOG[i].attendance ? listOG[i].attendance : "A"
        eh = listOG[i].extraHours ? listOG[i].extraHours : "--"
        status = listOG[i].status !== null ? (listOG[i].status !== 0 ? "Active" : "Inactive") : "NA"
        _in = listOG[i].entry_ && listOG[i].attendance === "P" ? moment(listOG[i].In).format('DD MMM, YYYY (hh:mm A)') : "--"
        out = listOG[i].exit_ && listOG[i].entry_ && listOG[i].attendance === "P" ? moment(listOG[i].Out).format('DD MMM, YYYY (hh:mm A)') : "--"

        let gender
        switch (listOG[i].gender) {
          case "M":
            gender = "Male"
            break;
          case "F":
            gender = "Female"
            break;
          default:
            gender = "Any"
        }

        data.push(
            {
              id: <div>{empId}</div>,
              name: empName,
              gender: gender,
              position: <div style={{ textAlign: "left" }}>{designation}</div>,
              plantname: <div style={{ textAlign: "left" }}> {PlantId}</div >,
              departmantname: <div style={{ textAlign: "left" }}>{department}</div>,
              section: section,
              category: category,
              shift: shift,
              in: _in,
              out: out,
              attendance: attendance,
              eh: eh,
              status: status,
              actions: this.renderAction(listOG, i),
            }
        )
      }
      this.setState({
        employeeList: data
      })
      return data

    }
  }

  data = {
    columns: [
      {
        label: 'Emp Code',
        field: 'id',
        sort: 'asc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Gender',
        field: 'gender',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Designation',
        field: 'position',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Plant',
        field: 'plantname',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Department',
        field: 'departmantname',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Section',
        field: 'section',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Category',
        field: 'category',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Shift',
        field: 'shift',
        sort: 'asc',
        width: 150
      },
      {
        label: 'IN',
        field: 'in',
        sort: 'asc',
        width: 150
      },
      {
        label: 'OUT',
        field: 'out',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Attendance',
        field: 'attendance',
        sort: 'asc',
        width: 150
      },
      {
        label: 'EH',
        field: 'eh',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 150
      },
      // {
      //   label: 'Actions',
      //   field: 'actions',
      //   sort: 'asc',
      //   width: 100
      // },
    ],
    rows: this.processDataList()
  }

  matchDesignationDefaultList(plantId) {
    let data = this.state.designationList, i, object = []

    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Designation_Id == plantId) {
        object.push(
            {
              label: data[i].label,
              value: i,
              Designation_Id: data[i].Designation_Id,
            })
        break;
      }
    }
    return object
  }

  matchPlantDefaultList(plantId) {
    let data = this.state.plantDataList, i, object = []
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Plant_Id == plantId) {
        object.push(
            {
              'label': data[i].label,
              'value':i,
              'Plant_Id': data[i].Plant_Id,
            })
        break;
      }
    }
    return object
  }

  matchDepartmentDefaultList(plantId) {
    let data = this.state.deptList, i, object = []
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Department_Id === plantId) {
        object.push(
            {
              label: data[i].label,
              value: i,
              Department_Id: data[i].Department_Id,
              department_short: data[i].department_short,
            })
        break;
      }
    }
    return object
  }

  matchSectionDefaultList(plantId) {
    let data = this.state.sectionList, i, object = []
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Section_Id === plantId) {
        object.push(
            {
              label: data[i].label,
              value: i,
              Section_Id: data[i].Section_Id,
            })
        break;
      }
    }
    return object
  }

  matchCategoryDefaultList(plantId) {
    let data = this.state.categoryList, i, object = []
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].Category_Id == plantId) {
        object.push(
            {
              label: data[i].label,
              value: i,
              Category_Id: data[i].Category_Id,
            })
        break;
      }
    }
    return object
  }

  matchShiftDefaultList(plantId) {
    let data = this.state.shiftList, i, object = []
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].label == plantId) {
        object.push(
            {
              // 'label': data[i].label,
              // 'value': i,
              // 'startTime': data[i].startTime,
              // 'endTime': data[i].endTime,

              label: data[i].SFTName,
              value: i,
              startTime: data[i].SFTSTTime,
              endTime: data[i].SFTEDTime,
            })
        break;
      }
    }
    return object
  }

  matchGenderDefaultList(gender) {
    let data, i, object = []
    data = utils.constants.gender
    i = 0
    for (i; i < data.length; i++) {
      if (data[i].value === gender) {
        object.push(
            {
              'label': data[i].label,
              'value': data[i].value,
            })
        break;
      }
    }
    return object
  }

  handleEditEmployee(row) {
    let designationDefault, plantDefault, departmentDefault, sectionDefault, categoryDefault, shifDefault, genderDefault

    if (row.Designation_Id !== "" || row.Designation_Id !== null)
      designationDefault = this.matchDesignationDefaultList(row.Designation_Id)
    else
      designationDefault = row.Designation_Id

    if (row.Plant_Id !== "" || row.Plant_Id !== null) {
      plantDefault = this.matchPlantDefaultList(row.plant_Id)
    }
    else {
      plantDefault = row.Plant_Id
    }

    if (row.Department_Id !== "" || row.Department_Id !== null)
      departmentDefault = this.matchDepartmentDefaultList(row.Department_Id)
    else
      departmentDefault = row.Department_Id

    if ((row.Section_Id !== "" || row.Section_Id !== null))
      sectionDefault = this.matchSectionDefaultList(row.Section_Id)
    else
    if (row.Section_Id === "NA")
      sectionDefault = ""
    else
      sectionDefault = row.Section_Id

    if (row.Category_Id !== "" || row.Category_Id !== null)
      categoryDefault = this.matchCategoryDefaultList(row.Category_Id)
    else
      categoryDefault = row.Category_Id

    if (row.Shift !== "" || row.Shift !== null)
      shifDefault = this.matchShiftDefaultList(row.Shift)
    else
      shifDefault = row.Shift

    if (row.gender !== "" || row.gender !== null)
      genderDefault = this.matchGenderDefaultList(row.gender)
    else
      genderDefault = row.gender

    let tempObj = {
      empCode: row.Employee_Id,
      name: row.employeeName,
      gender: genderDefault,
      designation: designationDefault,
      plantname: plantDefault,
      department: departmentDefault,
      section: sectionDefault,
      category: categoryDefault,
      shift: shifDefault,
      isEditEmployeeModalOpen: true,
      dateOfBirth: new Date(moment(row.dateOfBirth, 'D/M/YYYY hh:mm:ss')),
      dateOfJoin: new Date(moment(row.dateOfJoin, 'D/M/YYYY hh:mm:ss')),
    }

    this.setState({
      empCode: row.Employee_Id,
      name: row.employeeName,
      gender: genderDefault,
      designation: designationDefault,
      plantname: plantDefault,
      department: departmentDefault,
      section: sectionDefault,
      category: categoryDefault,
      shift: shifDefault,
      isEditEmployeeModalOpen: true,
      // dateOfBirth: new Date(moment(row.dateOfBirth).format('M/DD/YYYY hh:mm:ss')),
      // dateOfJoin: new Date(moment(row.dateOfJoin).format('M/DD/YYYY hh:mm:ss')),

      dateOfBirth: new Date(moment(row.dateOfBirth, 'D/M/YYYY hh:mm:ss')),
      dateOfJoin: new Date(moment(row.dateOfJoin, 'D/M/YYYY hh:mm:ss')),
    })
  }

  editEmployeeModalClose() { this.setState({ isEditEmployeeModalOpen: false, editEmailError: '' }) };

  editModal() {
    let empCode, name, gender, designation, plantname, category, department, section, shift, dateOfBirth, dateOfJoin

    empCode = this.state.empCode
    name = this.state.name
    gender = this.state.gender
    designation = this.state.designation
    plantname = this.state.plantname
    category = this.state.category
    department = this.state.department
    section = this.state.section
    shift = this.state.shift
    dateOfBirth = this.state.dateOfBirth
    dateOfJoin = this.state.dateOfJoin

    return (
        <Modal
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.isEditEmployeeModalOpen}
            onHide={() => this.editEmployeeModalClose()}
        >
          <Modal.Header closeButton className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" >
              <p className="forParagraph">Edit Employee Details</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>
                <Form.Group as={Row} controlId="formHorizontalEmployeeCode">
                  <Col>
                    <Form.Label>Employee Code</Form.Label>
                    <Form.Control type="text" value={empCode} disabled />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalEmployee">
                  <Col >
                    <Form.Label>Employee Name</Form.Label>
                    <Form.Control type="text" value={name}
                                  onChange={(e) => {
                                    this.setState({ name: e.target.value })
                                  }}
                    />
                  </Col>
                  <Col >
                    <Form.Label>Gender</Form.Label>
                    {
                      gender === "" || gender === null ?
                          <Select
                              placeholder="Select Gender"
                              onChange={(val) => { this.setState({ selectedGenderOption: [val] }) }}
                              options={utils.constants.gender}
                          />
                          :
                          <Select
                              placeholder="Select Gender"
                              defaultValue={gender}
                              onChange={(val) => { this.setState({ selectedGenderOption: [val] }) }}
                              options={utils.constants.gender}
                          />
                    }
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalDesignation">
                  <Col>
                    <Form.Label>Designation</Form.Label>
                    {
                      designation === "" || designation === null ?
                          <Select
                              placeholder="Select Designation"
                              onChange={(val) => { this.setState({ selectedDesignationOption: [val] }) }}
                              options={this.state.designationList}
                          />
                          :
                          <Select
                              placeholder="Select Designation"
                              defaultValue={designation}
                              onChange={(val) => { this.setState({ selectedDesignationOption: [val] }) }}
                              options={this.state.designationList}
                          />
                    }
                  </Col>
                  <Col>
                    <Form.Label>Category</Form.Label>
                    {
                      category === "" || category === null ?
                          <Select
                              placeholder="Select Category"
                              onChange={(val) => { this.setState({ selectedCategoryOption: [val] }) }}
                              options={this.state.categoryList}
                          />
                          :
                          <Select
                              placeholder="Select Category"
                              defaultValue={category}
                              onChange={(val) => { this.setState({ selectedCategoryOption: [val] }) }}
                              options={this.state.categoryList}
                          />
                    }
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalMobile">
                  <Col  >
                    <Form.Label>Plant</Form.Label>
                    {
                      plantname === "" || plantname === null ?
                          <Select
                              placeholder="Select Plant"
                              onChange={(val) => {
                                // this.getShift(val.Plant_Id)
                                this.setState({
                                  selectedPlantOption: [val],
                                  selectedShiftOption: '',
                                  shift: '',
                                })
                              }}
                              options={this.state.plantDataList}
                          />
                          :
                          <Select
                              placeholder="Select Plant"
                              defaultValue={plantname}
                              onChange={(val) => {
                                // this.getShift(val.Plant_Id)
                                this.setState({
                                  selectedPlantOption: [val],
                                  selectedShiftOption: '',
                                  shift: '',
                                })
                              }}
                              options={this.state.plantDataList}
                          />
                    }
                  </Col>
                  <Col  >
                    <Form.Label>Department</Form.Label>
                    {
                      department === "" || department === null ?
                          <Select
                              placeholder="Select Department"
                              onChange={(val) => { this.setState({ selectedDeptOption: [val] }) }}
                              options={this.state.deptList}
                          />
                          :
                          <Select
                              placeholder="Select Department"
                              defaultValue={department}
                              onChange={(val) => { this.setState({ selectedDeptOption: [val] }) }}
                              options={this.state.deptList}
                          />
                    }
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalMobile">
                  <Col  >

                    <Form.Label>Section</Form.Label>
                    {
                      section === "" || section === null ?
                          <Select
                              placeholder="Select Section"
                              onChange={(val) => { this.setState({ selectedSectionOption: [val] }) }}
                              options={this.state.sectionList}
                          />
                          :
                          <Select
                              placeholder="Select Section"
                              defaultValue={section}
                              onChange={(val) => { this.setState({ selectedSectionOption: [val] }) }}
                              options={this.state.sectionList}
                          />
                    }
                  </Col>
                  <Col>
                    <Form.Label>Shift</Form.Label>
                    {
                      shift === "" || shift === null ?
                          <Select
                              placeholder="Select Shift"
                              onChange={(val) => { this.setState({ selectedShiftOption: [val] }) }}
                              options={this.state.shiftList}
                          />
                          :
                          <Select
                              placeholder="Select Shift"
                              defaultValue={shift}
                              onChange={(val) => { this.setState({ selectedShiftOption: [val] }) }}
                              options={this.state.shiftList}
                          />
                    }
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formHorizontalImages">
                  <Col md={{ span: 6 }} >
                    <Form.Label>Date of Birth</Form.Label>
                    <DatePicker
                        className={'form-control datePicEditEmployee'}
                        popperPlacement="top-end"
                        selected={dateOfBirth}
                        onChange={(date) => {
                          this.setState({
                            dateOfBirth: date
                          })
                        }}
                        dateFormat="d MMMM, yyyy "
                        placeholderText="Select date of birth"
                    />
                  </Col>
                  <Col md={{ span: 6 }} >
                    <Form.Label>Date of Joining</Form.Label>
                    <DatePicker
                        className={'form-control datePicEditEmployee'}
                        popperPlacement="top-end"
                        selected={dateOfJoin}
                        onChange={(date) => this.setState({
                          dateOfJoin: date
                        })}
                        dateFormat="d MMMM, yyyy "
                        placeholderText="Select date of joining"
                    />
                  </Col>
                </Form.Group>
                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                      isBtnLoading={this.state.isLoadingBtn}
                      btnText={"Save"}
                      isGradient={true}
                      onBtnClick={() => {
                        this.setState({ isLoadingBtn: true, })
                        if (!this.state.isLoadingBtn) {
                          this.updateEmployee()
                        }
                      }}
                  />
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
    )
  }

  handleDeactivateStaffModalClose() { this.setState({ deactivateModal: false }) }

  render() {
    let currPage = this.props.currentPage ? this.props.currentPage : 1

    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />

    return (
        <div className="manageemployeesdatabody">
          <myComponent.ReLoginPopUp
              show={this.state.showPopUp}
              newPlant={this.state.newPlant}
              oldPlant={this.state.oldPlant}
          />
          {this.editModal()}

          <MDBDataTable
              responsive
              className="table-bg-adjust"
              id="manageemployee"
              striped
              bordered
              hover
              paging={false}
              data={this.data}
              searchTop={false}
              searching={false}
          />

          <div className="d-flex justify-content-end">
            <myComponent.Pagination
                pageCount={this.props.item.totalRecordsFound}
                currentPage={currPage}
                pageCallBack={(page) => {
                  let { pageCallBack } = this.props
                  pageCallBack(page)
                }}
            />
          </div>

          <actionComponents.DeactivateDesignation
              show={this.state.deactivateModal}
              onHide={() => this.handleDeactivateStaffModalClose()}
              item={this.state.selectedItem}
              callBack={() => this.handleDeactivateStaffModalClose()}
              callBack2={() => {
                let { callBack2 } = this.props
                callBack2()
              }}
              activationType={this.state.activationType}
          />
        </div>
    );
  }
}
