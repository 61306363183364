/* 
-------- Error Code------------
// true = 0
// Empty Data = 1
// Formate Error = 2
// no matching = 3

--------------END-------------
*/

import utils from "../utils/index"

const emailREGX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const alphaNumericSpace = /^[a-zA-Z0-9 ]+$/;

function validateName(name) {
    if (name === "") {
        let object = {
            status: false,
            reason: "data is empty",
            msg: utils.strings.errorMsg.nameError,
            errorCode: 1,
        }
        return object
    }
    else {
        let object = {
            status: true,
            reason: "",
            msg: "",
            errorCode: 0,
        }
        return object
    }
}

function validateShiftShortName(name) {
    if (name === "") {
        let object = {
            status: false,
            reason: "Short name is is empty",
            msg: utils.strings.errorMsg.nameError,
            errorCode: 1,
        }
        return object
    }
    else if (!alphaNumericSpace.test(name)) {
        let object = {
            status: false,
            reason: "Enter valid short name",
            msg: utils.strings.errorMsg.nameError,
            errorCode: 1,
        }
        return object
    }
    else if (name.length > 5) {
        let object = {
            status: false,
            reason: "Short name length should not be more than 5",
            msg: utils.strings.errorMsg.nameError,
            errorCode: 1,
        }
        return object
    }
    else {
        let object = {
            status: true,
            reason: "",
            msg: "",
            errorCode: 0,
        }
        return object
    }
}

function validatePhoneNumber(phoneNumber) {
    if (phoneNumber === "") {
        let object = {
            status: false,
            reason: "data is empty",
            msg: utils.strings.errorMsg.phnoError,
            errorCode: 1,
        }
        return object
    }
    if (phoneNumber.length > 10 || phoneNumber.length === 0) {
        let object = {
            status: false,
            reason: "Invalid PhoneNumber lenght",
            msg: utils.strings.errorMsg.phnoError2,
            errorCode: 2,
        }
        return object
    }
    else {
        let object = {
            status: true,
            reason: "",
            msg: "",
            errorCode: 0,
        }
        return object
    }
}

function validatePassword(password) {
    if (password === "") {
        let object = {
            status: false,
            reason: "data is empty",
            msg: utils.strings.errorMsg.passwdError,
            errorCode: 1,
        }
        return object
    }
    else {
        let object = {
            status: true,
            reason: "",
            msg: "",
            errorCode: 0,
        }
        return object
    }
}

function validateConfirmPassword(password, confirmPassword) {
    let object
    if (confirmPassword === "") {
        object = {
            status: false,
            reason: "data is empty",
            msg: utils.strings.errorMsg.conpasswdError,
            errorCode: 1,
        }
        return object
    }
    else {
        if (!password || password === "") {
            object = {
                status: true,
                reason: "",
                msg: "",
                errorCode: 0,
            }
            return object
        }
        else {
            if (password === confirmPassword) {
                object = {
                    status: true,
                    reason: "",
                    msg: "",
                    errorCode: 0,
                }
                return object
            }
            else {
                object = {
                    status: false,
                    reason: "no matching",
                    msg: utils.strings.errorMsg.conpasswdError2,
                    errorCode: 3,
                }
                return object
            }
        }
    }
}

function validateEmail(email) {
    if (email === "") {
        let object = {
            status: false,
            reason: "data is empty",
            msg: utils.strings.errorMsg.emailError,
            errorCode: 1,
        }
        return object
    }
    else if (!emailREGX.test(email)) {
        let object = {
            status: false,
            reason: "invalid email",
            msg: utils.strings.errorMsg.emailError2,
            errorCode: 2,
        }
        return object
    }
    else {
        let object = {
            status: true,
            reason: "",
            msg: "",
            errorCode: 0,
        }
        return object
    }
}

const validate = {
    validateName,
    validateShiftShortName,
    validateEmail,
    validatePassword,
    validatePhoneNumber,
    validateConfirmPassword
}
export default validate;