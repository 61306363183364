import {
  faBuilding,
  faFile,
  faIdBadge,
  faTachometerAlt,
  faUserClock,
  faUserLock,
  faUsers,
  faTasks
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {NavLink, Redirect} from 'react-router-dom';
import apiCall from '../apiCall';
import utils from "../utils/index";
import './Sidebar.css';

let sidebarMenu = [];
let pageURL = utils.config.pageURL
sidebarMenu = [
  { label: 'Dashboard', link: pageURL.dashboard, icon: faTachometerAlt, key:0 },
  { label: 'Manage Admin', link:pageURL.admin, icon: faUserLock, key:1 },
  { label: 'Manage Employees', link: pageURL.manageEmployees, icon: faUsers, key:2 },
  { label: 'Manage App Users', link: pageURL.manageAppUsers, icon: faUserClock, key:3 },
  { label: 'Manage Plants', link: pageURL.managePlant, icon: faBuilding, key:4 },
  { label: 'Shift Management', link: pageURL.manageShift, icon: faTasks, key:7 },
  { label: 'Reports', link:pageURL.reportDepartmentWise, icon: faFile, key:5 },
  { label: 'Profile', link:pageURL.profile, icon: faIdBadge, key:6 },
]


export default class Sidebar extends Component {

  async logout() {
    try {
      let response = await apiCall.logout();
      if (response.status) {
        localStorage.removeItem(utils.strings.localStorageItem.token);
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  renderSidebarMenu(item) {
    return (
        <>
          {
            localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin ?
                <NavLink
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to={item.link}
                >
                  <Row className='item-style'>
                    <Col md={'2'} xs={'2'} lg={'2'}>
                      <span className="icons"><FontAwesomeIcon icon={item.icon}/></span>
                    </Col>
                    <Col className="labelSide" md={'10'}>{item.label}</Col>
                  </Row>
                </NavLink>
                :
                item.link !== "/admin" && item.link !== "/manage-plant" && item.link !== "/manage-shift" ?
                    <NavLink
                        exact
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to={item.link}
                    >
                      <Row className='item-style'>
                        <Col md={'2'} xs={'2'} lg={'2'}>
                          <span className="icons"><FontAwesomeIcon icon={item.icon} /></span>
                        </Col>
                        <Col className="labelSide" md={'10'}>{item.label}</Col>
                      </Row>
                    </NavLink>
                    :
                    <div />
          }
        </>
    )
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token)) {
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    }
    return (
        <div>
          <nav
              className="sidebar"
          >
            <div>
              <ul className="nav flex-column">
                {
                  sidebarMenu.map((item) =>
                      this.renderSidebarMenu(item)
                  )
                }
              </ul>
            </div>
          </nav>
        </div>
    )
  }

}
