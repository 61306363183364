import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Modal, Table } from 'react-bootstrap';
import "../appUserErrorPopUp/errorPopup.css";



export default class errorPopupEmployee extends Component {
  constructor(props) {
    super(props)
    this.props = props;
  }

  static propTypes = {
    isBtnLoading: PropTypes.any,
  }

  static defaultProps = {
    isBtnLoading: ''
  }



  renderError(item, index) {
    return (
      <tr>
        <td style={{ textAlign: "center" }} >{item.row}</td>
        <td >{item.message}</td>
      </tr>
    )
  }

  render() {
    let itemArray = this.props.item
    let item = itemArray === null ? [] : this.props.item.errors
    return (
      <Modal
        backdrop="static"
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#07344D", color: "white" }}>
          <Modal.Title id="contained-modal-title-vcenter" >
            Error Log for upload Employee
      </Modal.Title>
        </Modal.Header>
        <Modal.Body className='error-body-dimention' >
          <Container>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th
                    style={{ textAlign: "center" }}> Row Number</th>
                  <th style={{ textAlign: "center" }}>Error Message</th>
                </tr>
              </thead>
              <tbody style={{ color: "red" }}>
                {
                  item.map((item, index) =>
                    this.renderError(item, index)
                  )
                }
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
      </Modal>

    )
  }

}