import Header from "./header/header"
import HeroButton from "./heroButton/heroButton"
import HeroSwitch from "./heroSwitch/heroSwitch"
import Pagination from "./pagination/pagination"
import ReLoginPopUp from "./reLoginPopUp/reLoginPopUp"


const myComponent = {
    HeroButton,
    HeroSwitch,
    Header,
    ReLoginPopUp,
    Pagination,
}

export default myComponent