import config from "./config";
import constants from "./constants";
import utilFunction from "./functions";
import images from "./images";
import strings from "./strings";
import validate from "./validation";

const utils = {
    images,
    utilFunction,
    config,
    validate,
    strings,
    constants,
}
export default utils
