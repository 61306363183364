import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import MyComponent from "../components/index";
import "../globalStyle.css";
import Sidebar from '../Sidebar/Sidebar';
import utils from "../utils/index";
import './manageShift.css';
import ShiftDataTable from './manageShiftDataTable/manageShiftDataTable'
import apiCall from "../apiCall";
import AdminDataTable from "../manageAdmin/adminDataTable/adminDataTable";
import FadeLoader from "react-spinners/FadeLoader";
import moment from 'moment';

export default class managePlant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            plantDataList:[],
            isPageLoading:false,
            apiCallDone: false,
            shiftList:[],
        }
    }

    componentDidMount() {
        this.getShiftList()
    }

    async getShiftList() {
        try {
            let response = await apiCall.getShiftList();
            if (response.data.status) {
                this.setState({apiCallDone: true})
                let sftList = response.data.data;

                let shiftList =  [];
                sftList.map((data) => {
                    shiftList.push({
                        label:data.shiftName,
                        shortName:data.shortName,
                        value:data.shiftId,
                        shiftId:data.shiftId,
                        startTime:data.startTime,
                        endTime:data.endTime
                    })
                })
                this.setState({
                    shiftList: shiftList
                })
            }
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }


    render() {
        // if (!localStorage.getItem(utils.strings.localStorageItem.token))
        //     return <Redirect to={{ pathname: utils.config.pageURL.login }} />

        if (localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin) {
            if (!localStorage.getItem(utils.strings.localStorageItem.token))
                return <Redirect to={{ pathname: utils.config.pageURL.login }} />
        }
        else
            return <Redirect to={{ pathname: utils.config.pageURL.dashboard }} />

        return(
            <div className='custom-container'>
                {
                    this.state.refreshHeader ?
                        <MyComponent.Header />
                        :
                        <div>
                            <MyComponent.Header />
                        </div>
                }
                <div className='row-2'>
                    <div className="sidebar-view">
                        <div className='sidebar-static'>
                            <Sidebar />
                        </div>
                    </div>
                    <div className="content-view">
                        <div className="sub-content-view">
                            <Row className="heading">
                                <h4>{utils.strings.heading.manageShift} </h4>
                            </Row>
                            <div className="gap1" />

                            {
                                (this.state.apiCallDone) ?
                                    (this.state.shiftList).length > 0 ?
                                        <ShiftDataTable
                                            dataListOG={this.state.shiftList}
                                            callBack2={() => {
                                                this.setState({
                                                        shiftList:[]
                                                },
                                                    ()=> this.getShiftList()
                                                )
                                            }
                                            }
                                        />
                                        :
                                        <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                                    :
                                    <div />
                            }

                        </div>
                    </div>
                </div>
                {
                    this.state.isPageLoading ?
                        <Col id="overlay" >
                            <Col id="loader">
                                <FadeLoader
                                    size={"30"}
                                    width={"10"}
                                    radius={"8"}
                                    color={"#08334D"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Col>
                        :
                        <div />
                }
            </div>
        )
    }
}