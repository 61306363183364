import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import utils from "../../utils/index";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      loading: false,
      isLoadingBtn: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordError: '',
      newpasswordError: '',
      confirmPasswordError: '',
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
    }
  }
  componentDidMount() {
    this.setState({ loading: true })
  }

  validate(item) {
    if ((this.props.showCurrPswd ? this.state.oldpassword !== "" : true) && this.state.newPassword !== "" && this.state.confirmPassword !== "" && this.state.newPassword === this.state.confirmPassword) {
      this.changePassword(item)
    }
    if (this.state.oldPassword === "" && this.props.showCurrPswd) {
      this.setState({
        isLoadingBtn: false,
        oldPasswordError: 'Please enter your current password'
      })
    }
    if (this.state.oldPassword !== "" && this.props.showCurrPswd) {
      this.setState({
        oldPasswordError: ''
      })
    }
    if (this.state.newPassword === "") {
      this.setState({
        isLoadingBtn: false,
        newpasswordError: 'Please enter password'
      })
    }
    if (this.state.newPassword !== "") {
      this.setState({
        newpasswordError: ''
      })
    }
    if (this.state.confirmPassword === "") {
      this.setState({
        isLoadingBtn: false,
        confirmPasswordError: 'Please confirm password'
      })
    }
    if (this.state.confirmPassword !== "") {
      this.setState({
        confirmPasswordError: ''
      })
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        isLoadingBtn: false,
        confirmPasswordError: 'Password dosent match'
      })
    }
    if (this.state.newPassword === this.state.confirmPassword) {
      this.setState({
        confirmPasswordError: ''
      })
    }
  }

  async changePassword(item) {
    try {
      let object, response;
      if (this.props.showCurrPswd) {
        object = {
          adminId: item.Admin_id,
          oldPassword: this.state.oldPassword,
          newPassword: this.state.newPassword,
        }
      }
      else {
        object = {
          adminId: item.Admin_id,
          password: this.state.newPassword,
        }
      }

      this.props.showCurrPswd ?
        response = await apiCall.resetPassword(object)
        :
        response = await apiCall.resetSubAdminPassword(object)

      let { callBack2 } = this.props

      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }

      if (response.status) {
        this.setState({ isLoadingBtn: false });
        callBack2()
        toast.success(utils.strings.successMsg.passChange)
      }
      else if (response.error_code === 401) {
        utils.utilFunction.toastCall(utils.strings.errorMsg.passCurrIncorrect)
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  reset() {
    this.setState({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      oldPasswordError: '',
      newpasswordError: '',
      confirmPasswordError: '',
    })
  }

  handleClose() {
    let { callBack } = this.props
    this.reset()
    callBack()
  }

  render() {
    let item = this.props.item
    return (

      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton onHide={() => this.handleClose()}
            className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter">
              <p className="forParagraph">Change Password </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>
                {
                  localStorage.getItem("adminType") === "SuperAdmin" ?
                    this.props.showCurrPswd ?
                      <Form.Group as={Row} controlId="formHorizontalName">
                        <Col sm={12}>
                          <Form.Label>Current Password</Form.Label>
                          <Form.Control type="password" placeholder="Enter current password"
                            value={this.state.oldPassword}
                            onChange={(event) => this.setState({ oldPassword: event.target.value })}
                          />
                        </Col>
                        <div style={{ marginLeft: '20px' }} className='error-style'>{this.state.oldPasswordError}</div>
                      </Form.Group>
                      :
                      <div />
                    :
                    <Form.Group as={Row} controlId="formHorizontalName">
                      <Col sm={12}>
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter current password"
                          value={this.state.oldPassword}
                          onChange={(event) => this.setState({ oldPassword: event.target.value })}
                        />
                      </Col>
                      <div style={{ marginLeft: '20px' }} className='error-style'>{this.state.oldPasswordError}</div>
                    </Form.Group>
                }



                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Col sm={12}>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      value={this.state.newPassword}
                      type="password"
                      placeholder="Enter new passowrd "
                      onChange={(event) =>
                        this.setState({
                          newPassword: event.target.value
                        })
                      }
                    />
                  </Col>
                  <div style={{ marginLeft: '20px' }} className='error-style'>{this.state.newpasswordError}</div>
                </Form.Group>


                <Form.Group as={Row} controlId="formHorizontalMobile">
                  <Col sm={12}>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      value={this.state.confirmPassword}
                      onChange={(event) =>
                        this.setState({
                          confirmPassword: event.target.value
                        })
                      }
                      type="password"
                      placeholder="Confirm new password" />
                  </Col>
                  <div style={{ marginLeft: '20px' }} className='error-style'>{this.state.confirmPasswordError}</div>
                </Form.Group>


                <Form.Group as={Row} >
                  <Col className='d-flex justify-content-end'>
                    <myComponent.HeroButton
                      isBtnLoading={this.state.isLoadingBtn}
                      btnText={"Change Password"}
                      isGradient={true}
                      onBtnClick={() => { this.validate(item) }}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}