import moment from "moment";
import React from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import utils from "../utils/index";

const printOn = false

function checkSameDay(first, second) {
    return (first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate())
}

function dateDifference(sDate, eDate) {
    let res = checkSameDay(sDate, eDate)

    let msec, mins, hrs, days, yrs

    msec = eDate - sDate;
    mins = Math.floor(msec / 60000);
    hrs = Math.floor(mins / 60);
    days = Math.floor(hrs / 24);
    yrs = Math.floor(days / 365)


    if (!res) {
        let newEndDate, msec2, mins2, hrs2, days2
        let locDays = days + 1

        newEndDate = new Date(moment(sDate).format('M/DD/YYYY') + moment(eDate).format(' HH:mm:sss'))

        msec2 = newEndDate - sDate;
        mins2 = Math.floor(msec2 / 60000);
        hrs2 = Math.floor(mins2 / 60);
        hrs = hrs2 * locDays
    }


    if (yrs <= 0) {
        if (days <= 0) {
            if (hrs <= 0) {
                if (mins <= 0) {
                    return { time: 'less than a minute', key: 'sec' }
                }
                else {
                    return { time: mins, key: 'mins' }
                }
            }
            else {
                return { time: hrs, key: 'hrs' }
            }
        }
        else {
            return { time: days, key: 'days' }
        }
    }
    else {
        return { time: yrs, key: 'yrs' }
    }
    return hrs
}

function reverse(data) {
    let newData = false, i
    if (data) {
        newData = ''
        i = (data.length - 1)
        for (i; i >= 0; i--) {
            newData = newData + data[i]
        }
    }
    return newData
}

function getNameInitials(data) {
    let initial1, initial2, initial
    initial = data.split(' ', 2)
    initial1 = initial[0][0].toUpperCase()
    initial2 = ''
    if (initial.length === 2) {
        initial2 = initial[1][0].toUpperCase()
    }

    return [initial1, initial2]
}

function getPlantName(id) {
    let str = "NA"
    if (id) {
        let newId = id.toString()
        str = "Plant - " + parseInt(newId[2] + newId[3])
    }
    return str
}

function validateRoleUploadFile(e) {
    let isCorrect = false;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        let i
        for (i = 0; i < data.length; i++) {
            if (data[i].length !== 0) {
                if (data[i][1] && data[i][0]) {
                    if (data[i][1] === "Department-Head") {
                        if (data[i][2] && data[i][3]) {
                            isCorrect = true
                        }
                        else
                            return false
                    }
                    else if (data[i][1] === "Plant-Head") {
                        if (data[i][2]) {
                            isCorrect = true
                        }
                        else
                            return false
                    }
                }
                else
                    return false
            }
        }

    };
    reader.readAsBinaryString(f);
    return isCorrect
}

function validateAppUserUploadFile(e) {
    let isCorrect = false;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        let i
        for (i = 0; i < data.length; i++) {

        }
    }
    reader.readAsBinaryString(f);
}

function getFileExtension(e) {
    let str = this.reverse(e.target.files[0].name)
    let i, newData = ''
    for (i = 0; i < str.length; i++) {
        if (str[i] === '.') {
            break
        }
        newData = newData + str[i]
    }
    return this.reverse(newData)
}

function checkSession() {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null) {
        toastCall(utils.strings.sessionMsg.sesExpired)
        this.redirect()
        return false
    }
    else {
        return true
    }
}

function redirect() {
    return <Redirect to='#/admin' />
}

function toastCall(msg) {
    if (localStorage.getItem('token') || localStorage.getItem('token') !== null)
        return toast.error(msg)
}

function successToastCall(msg) {
    if (localStorage.getItem('token') || localStorage.getItem('token') !== null)
        return toast.error(msg)
}

function getImageType(data) {
    let str = reverse(data)
    let i, newData = ''
    for (i = 0; i < str.length; i++) {
        if (str[i] === '.') {
            break
        }
        newData = newData + str[i]
    }
    return reverse(newData)
}

function validatePhoto(image) {
    let i, isImage = false
    let imageType = ['png', 'jpeg', 'jpg']
    let res = getImageType(image)
    for (i = 0; i < imageType.length; i++) {
        if (res === imageType[i]) {
            isImage = true
            break
        }
    }
    return isImage
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function print(key, value){
    if(printOn)
        console.log(":: "+key+" ",value);
}

function normalText(key) {
    let str, i, x;
    key = key.replace("_", "")
    key = key.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
    str = key.split(" ");
    for (i = 0, x = str.length; i < x; i++) {
        if(typeof(str[i][0]) === "string" ){
            str[i] = str[i][0].toUpperCase() + (str[i].substr(1)).toLowerCase();
        }
    }

    return str.join(" ")
}

function processColumnList(listOG){
    let data = [], newList= []
    data.push({field: "srNo", label: "Sr. No.", sort: 'asc', width: 50})
    if (listOG.length > 0) {
        newList = listOG[0]
        for (let key in newList) {
            // data.push({field: key, label: normalText(key), sort: 'asc', width: 50},)
            data.push({field: normalText(key), label: normalText(key), sort: 'asc', width: 50},)
        }
    }
    return data
}

function processDataList(listOG){
    let newList= [], data = [];
    if (listOG.length > 0) {
        let t, totalCountObject = {"srNo":"Total"}
        for (t = 0; t < listOG.length; t++) {
            totalCountObject = {"srNo":"Total"};
            newList = listOG[t]
            for (let key in newList) {

                // typeof(newList[key]) === 'string' || key.includes("_Id", 0) ? totalCountObject[key] = "" : totalCountObject[key] = 0
                typeof(newList[key]) === 'string' || key.includes("_Id", 0) ? totalCountObject[normalText(key)] = "" : totalCountObject[normalText(key)] = 0
            }
            break;
        }
        let i
        for (i = 0; i < listOG.length; i++) {
            newList = listOG[i]
            let object = {srNo: i+1,};
            for (let key in newList) {
                // typeof(newList[key]) === 'string' || key.includes("_Id", 0) ? totalCountObject[key] = "" : totalCountObject[key] = totalCountObject[key] + newList[key]
                // object[key] = newList[key];

                typeof(newList[key]) === 'string' || key.includes("_Id", 0) ? totalCountObject[normalText(key)] = "" : totalCountObject[normalText(key)] = totalCountObject[normalText(key)] + newList[key]
                object[normalText(key)] = newList[key];
            }
            data.push(object)
        }
        data.push(totalCountObject)
    }
    return data
}


const utilFunction = ({
    checkSameDay,
    reverse,
    getNameInitials,
    dateDifference,
    getPlantName,
    getFileExtension,
    validateRoleUploadFile,
    validateAppUserUploadFile,
    checkSession,
    redirect,
    toastCall,
    successToastCall,
    validatePhoto,
    sleep,
    print,
    normalText,
    processColumnList,
    processDataList,
})

export default utilFunction
