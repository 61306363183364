import React, { Component } from 'react';
import "../../globalStyle.css";
import './managePlantDataTable.css';
import { MDBDataTable } from 'mdbreact';
import "mdbreact/dist/css/mdb.css";
import utils from "../../utils";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTasks } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import myComponent from "../../components";
import apiCall from "../../apiCall";
import { toast } from "react-toastify";

export default class plantDataTable extends Component {
    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            updatePlantAddressState: false,
            address: "",
            addressError: "",
            isLoadingBtn: false,
            plantID: 0,
            showPopUp: false,
            newPlant: "",
            oldPlant: "",
            plantData: null,
            updateShiftModal: false,
            shiftList: [],
        }
    }

    componentDidMount() {
        this.getShiftList();
    }

    async getShiftList() {
        try {
            let response = await apiCall.getShiftList();
            if (response.data.status) {
                let sftList = response.data.data;
                this.setState({
                    shiftList: sftList
                })
            }
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }

    handleClose() {
        this.setState({
            updatePlantAddressState: false,
            address: "",
            isLoadingBtn: false,
            plantID: 0,
        })
    }

    validate() {
        let addRes = utils.validate.validateName(this.state.address)


        if (addRes.status) {
            this.setState({ addressError: "" })
            return true
        }
        else {
            this.setState({ addressError: "Please enter plant address" })
            return false
        }
    }

    async editPlantAddress() {
        if (this.validate()) {
            try {
                let object = {
                    plantId: this.state.plantID,
                    plantAddress: this.state.address
                }

                let response = await apiCall.editPlantAddress(object);

                let { callBack2 } = this.props

                if (response.permissionChanged) {
                    this.setState({
                        isLoadingBtn: false,
                        showPopUp: true,
                        newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
                        oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
                    })
                }
                if (response.data) {
                    this.setState({ isLoadingBtn: false })
                    if (response.data.status) {
                        this.handleClose()
                        callBack2()
                        toast.success("Plant address changed")
                    } else if (response.data.error && response.data.error.errorcode === 1006) {
                        utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
                        localStorage.removeItem('token')
                        this.setState({ isLoadingBtn: false })
                    } else {
                        this.handleClose()
                        this.setState({
                            isLoadingBtn: false
                        })
                    }
                } else if (response.error_message === "USER_ALREADY_EXIST") {
                    this.setState({ isLoadingBtn: false })
                    utils.utilFunction.toastCall(utils.strings.errorMsg.uniqueUser)
                }
            } catch (e) {
                this.setState({ isLoadingBtn: false })
                utils.utilFunction.toastCall(e.response)
            }
        }
        else {
            this.setState({ isLoadingBtn: false })
        }
    }

    async updateShiftList() {
        try {
            let selectedShiftList = [];
            let shiftList = this.state.shiftList;
            for(let i in shiftList){
                if(shiftList[i].isSelected){
                    selectedShiftList.push({
                        shiftId:shiftList[i].shiftId, 
                    })
                }
            }
            let object = {
                shiftList: selectedShiftList,
                plantId:parseInt(this.state.plantData.Plant_Id)
            }

            let response = await apiCall.updateShiftListForPlant(object);

            let { callBack2 } = this.props

            if (response.permissionChanged) {
                this.setState({
                    isLoadingBtn: false,
                    showPopUp: true,
                    newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
                    oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
                })
            }
            if (response.data) {
                this.setState({ isLoadingBtn: false })
                if (response.data.status) {
                    this.handleClose()
                    callBack2()
                    toast.success("Shift updated")
                } else if (response.data.error && response.data.error.errorcode === 1006) {
                    utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
                    localStorage.removeItem('token')
                    this.setState({ isLoadingBtn: false })
                } else {
                    this.handleClose()
                    this.setState({
                        isLoadingBtn: false
                    })
                }
            } else if (response.error_message) {
                this.setState({ isLoadingBtn: false })
                utils.utilFunction.toastCall(response.error_message)
            }
        } catch (e) {
            this.setState({ isLoadingBtn: false })
            utils.utilFunction.toastCall(e.response)
        }
    }


    updatePlantAddressModal() {
        let str = ""
        return (
            <Modal
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.updatePlantAddressState}
                onHide={() => this.setState({
                    updatePlantAddressState: false
                })}
            >
                <Modal.Header closeButton className="forHeader">
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <p className="forParagraph">{"Edit Address"}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Form.Group controlId="addr">
                                <Form.Label>Plant Address</Form.Label>
                                <Form.Control as="textarea" rows="3" value={this.state.address}
                                    onChange={(e) => {
                                        str = e.target.value
                                        str = str.replace(/\s\s+/g, ' ');
                                        this.setState({ address: str })
                                    }}
                                />
                                <div className='error-style'>{this.state.addressError}</div>
                            </Form.Group>
                        </Form>
                        <div className='d-flex justify-content-end'>
                            <myComponent.HeroButton
                                isBtnLoading={this.state.isLoadingBtn}
                                btnText={"Update"}
                                isGradient={true}
                                onBtnClick={() => {
                                    this.setState({ isLoadingBtn: true, })
                                    if (!this.state.isLoadingBtn) {
                                        this.editPlantAddress()
                                    }
                                }
                                }

                            />
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    updateShiftModal() {
        return (
            <Modal
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.updateShiftModal}
                onHide={() => {
                    let array = this.state.shiftList;
                    for (let i in array) {
                        array[i].isSelected = false;
                    }
                    this.setState({
                        shiftList: array,
                        updateShiftModal: false
                    })
                }}
            >
                <Modal.Header closeButton className="forHeader">
                    <Modal.Title id="contained-modal-title-vcenter" >
                        <p className="forParagraph">{"Shifts List"}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: 0 }}>
                    <div className={'mainShiftMapping'}>
                        {this.state.shiftList.map((item, index) => this.renderShiftList(item, index))}
                    </div>
                    <div className='d-flex justify-content-end' style={{ padding: '1rem', borderTop: '1px solid #07344d' }}>
                        <myComponent.HeroButton
                            isBtnLoading={this.state.isLoadingBtn}
                            btnText={"Update"}
                            isGradient={true}
                            onBtnClick={() => {
                                this.setState({ isLoadingBtn: true, })
                                if (!this.state.isLoadingBtn) {
                                    this.updateShiftList()
                                }
                            }
                            }

                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    renderShiftList(data, index) {
        return (
            <div className={'mainShiftRow'} onClick={() => this.setShift(index)} key={index}>
                <div className={'shiftNameInModal'}>({data.shiftId}) {data.shortName.toUpperCase()} - {data.shiftName}</div>
                <input className={'checkBoxInModal'} type="checkbox" checked={data.isSelected} />
            </div>
        )
    }

    setShift(index) {
        let shiftArray = this.state.shiftList;
        shiftArray[index].isSelected = !(shiftArray[index].isSelected);
        this.setState({ shiftList: shiftArray })
    }

    setModalData(data) {
        this.setState({
            plantID: data.Plant_Id,
            address: data.address,

        },
            () => this.setState({ updatePlantAddressState: true, })
        )
    }

    setShiftData(data) {
        let assignedShift = data.assignedShift;
        let shiftList = this.state.shiftList;
        for (let i in shiftList) {
            if (assignedShift.length > 0) {
                for (let j in assignedShift) {
                    if (shiftList[i].shiftId == assignedShift[j].shiftId) {
                        shiftList[i].isSelected = true;
                    }
                    else if (j = (assignedShift.length - 1) && !shiftList[i].isSelected) {
                        shiftList[i].isSelected = false;
                    }
                }
            }
            else {
                shiftList[i].isSelected = false;
            }
        }

        this.setState({ plantData: data, shiftList: shiftList }, () => this.setState({ updateShiftModal: true }));
    }

    renderAction(data, i) {
        return (
            <div style={{ display: 'flex', alignItems: "center" }}>
                <div
                    className='pointer action-color' style={{ textAlign: "left" }}
                    onClick={() => this.setModalData(data[i])}
                    title={"Edit"}
                > &nbsp;&nbsp;<FontAwesomeIcon icon={faEdit} /></div>
                <div onClick={() => this.setShiftData(data[i])} className='pointer action-color' title="Shift Settings">| <FontAwesomeIcon icon={faTasks} /></div>
            </div>
        )
    }

    processDataList() {
        let listOG = this.props.dataListOG, data = [], i

        if (listOG.length > 0) {
            for (i = 0; i < listOG.length; i++) {
                data.push(
                    {
                        id: listOG[i].Plant_Id,
                        plant: listOG[i].label,
                        address: listOG[i].address,
                        shiftCount: listOG[i].shiftCount,
                        edit: this.renderAction(listOG, i)
                    }
                )
            }
        }

        return data
    }

    data = {
        columns: [
            {
                label: 'ID',
                field: 'id',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Plant',
                field: 'plant',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Address',
                field: 'address',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Shift count',
                field: 'shiftCount',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Actions',
                field: 'edit',
                sort: 'asc',
                width: 100
            },
        ],
        rows: this.processDataList()
    }

    render() {
        if (!localStorage.getItem(utils.strings.localStorageItem.token))
            return <Redirect to={{ pathname: utils.config.pageURL.login }} />
        return (
            <div className="admindatatable">
                {this.updatePlantAddressModal()}
                {this.updateShiftModal()}
                <div style={{ backgroundColor: "#FFFFFF" }}>
                    <MDBDataTable
                        responsive
                        striped
                        bordered
                        hover
                        data={this.data}
                    />
                </div>
            </div>
        )
    }

}
