import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import MyComponent from "../components/index";
import "../globalStyle.css";
import Sidebar from '../Sidebar/Sidebar';
import utils from "../utils/index";
import './managePlant.css';
import PlantDataTable from './managePlantDataTable/managePlantDataTable'
import apiCall from "../apiCall";
import AdminDataTable from "../manageAdmin/adminDataTable/adminDataTable";
import FadeLoader from "react-spinners/FadeLoader";

export default class managePlant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            plantDataList: [],
            isPageLoading: false,
            apiCallDone: false,
        }
    }

    componentDidMount() {
        this.getPlantList()
    }

    async getPlantList() {
        try {
            let response = await apiCall.getPlantList();
            if (response.data.status) {
                this.setState({ apiCallDone: true })
                let plantListOG = response.data.data, plantList = [], i = 0;
                for (i = 0; i < plantListOG.length; i++) {
                    plantList.push(
                        {
                            'label': plantListOG[i].Name,
                            'value': i,
                            'Plant_Id': plantListOG[i].BRCCODE,
                            address: plantListOG[i].BRCAddress,
                            assignedShift: plantListOG[i].assignedShift,
                            shiftCount:plantListOG[i].assignedShift.length
                        })
                }
                this.setState({
                    plantDataList: plantList
                })
            }
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }


    render() {
        // console.log(localStorage.getItem(utils.strings.localStorageItem.token))
        // if (!localStorage.getItem(utils.strings.localStorageItem.token))
        //     return <Redirect to={{ pathname: utils.config.pageURL.login }} />

        if (localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin) {
            if (!localStorage.getItem(utils.strings.localStorageItem.token))
                return <Redirect to={{ pathname: utils.config.pageURL.login }} />
        }
        else {
            return <Redirect to={{ pathname: utils.config.pageURL.dashboard }} />
        }

        return (
            <div className='custom-container'>
                {
                    this.state.refreshHeader ?
                        <MyComponent.Header />
                        :
                        <div>
                            <MyComponent.Header />
                        </div>
                }
                <div className='row-2'>
                    <div className="sidebar-view">
                        <div className='sidebar-static'>
                            <Sidebar />
                        </div>
                    </div>
                    <div className="content-view">
                        <div className="sub-content-view">
                            <Row className="heading">
                                <h4>{utils.strings.heading.managePlant} </h4>
                            </Row>
                            <div className="gap1" />

                            {
                                (this.state.apiCallDone) ?
                                    (this.state.plantDataList).length > 0 ?
                                        <PlantDataTable
                                            dataListOG={this.state.plantDataList}
                                            callBack2={() => {
                                                this.setState({
                                                    plantDataList: []
                                                },
                                                    () => this.getPlantList()
                                                )
                                            }
                                            }
                                        />
                                        :
                                        <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                                    :
                                    <Col id="overlay" >
                                        <Col id="loader">
                                            <FadeLoader
                                                size={"30"}
                                                width={"10"}
                                                radius={"8"}
                                                color={"#08334D"}
                                                loading={true}
                                            />
                                        </Col>
                                    </Col>
                            }

                        </div>
                    </div>
                </div>
                {
                    this.state.isPageLoading ?
                        <Col id="overlay" >
                            <Col id="loader">
                                <FadeLoader
                                    size={"30"}
                                    width={"10"}
                                    radius={"8"}
                                    color={"#08334D"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Col>
                        :
                        <div />
                }
            </div>
        )
    }
}