import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from '../../components';
import utils from "../../utils";
import ErrorPopupEmployee from '../errorPopupEmployee/errorPopupEmployee';

export default class UploadEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      admintype: '',
      plantname: '',
      selectedFile: '',
      messageModal: false,
      addEmployeeError: null,
      fileError: '',
      allRight: false,
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
    }
  }
  handleEditChange(Name) { this.setState({ Name: Name.target.value }) }

  validate(e) {
    if (e.target.files[0] !== undefined) {
      if (utils.utilFunction.getFileExtension(e) === "xlsx" || utils.utilFunction.getFileExtension(e) === "xls") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedFileOG: e,
          allRight: true,
          fileError: '',
        })
      }
      else {
        this.setState({
          fileError: "Please choose a valid xlsx or xls file",
          allRight: false,
        })
      }
    }
    else {
      this.setState({
        allRight: false,
      })
    }
  }

  async uploadEmployeeFile() {
    try {
      let formData = new FormData();
      formData.append(
        "file",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      let response = await apiCall.uploadEmployee(formData);
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        if (response.data.status) {
          this.setState({ isLoadingBtn: false })
          let { callBack2 } = this.props
          let { callBack } = this.props
          callBack()
          callBack2()
          toast.success("Employee sheet is uploaded")
        }
        if (!response.data.status) {
          this.setState({ isLoadingBtn: false, addEmployeeError: response.data })
          let { callBack } = this.props
          callBack()
          this.handleErrorPopUpModal()
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isLoadingBtn: false })
        }
      }
      else {
        this.setState({ isLoadingBtn: false, addEmployeeError: response })
        this.handleErrorPopUpModal()
      }
    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e.response)
    }
  }

  handleErrorPopUpModal() { this.setState({ messageModal: true }) };

  handleErrorPopUpClose() { this.setState({ messageModal: false }) };

  reset() {
    this.setState({
      fileError: "",
      selectedFile: '',
    })
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    return (
      <>
        <Modal
          backdrop="static"
          {...this.props}
          centered
          size="lg"
        >
          <Modal.Header closeButton onHide={() => this.reset()} className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "center" }}>
              <p className="forParagraph"> Upload Employee(s) </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  >
            <Container >
              <Form>
                <Form.Group as={Row} controlId="formHorizontalImages">
                  <Col sm={12}>
                    <h5 style={{ color: "black" }}>Points to consider before uploading.</h5>
                    <div className={"d-flex"} style={{ color: "black" }}>1.&nbsp;Importing file format should be one of<b style={{ fontWeight: "600" }}>&nbsp;XLSX</b>&nbsp;or<b style={{fontWeight: "600" }}>&nbsp;XLS</b> </div>
                    <div style={{ color: "black" }}>2.&nbsp;Click <a href={utils.config.uploadEmployeeFileTempletLink} >here</a> to get template/ format for upload file </div>
                    <div style={{ color: "black" }}>3.&nbsp;Mandatory columns are <b style={{ fontWeight: "600" }}>employee_code, employee_name, gender, plant_name, date_of_birth, date_of_join, department_name, category_name, shift </b></div>
                    <div style={{ color: "black" }}>4.&nbsp;Date format in the sheet should be <b style={{ fontWeight: "600"  }}>YYYY-MM-DD</b></div>
                    <div style={{ color: "black" }}>5.&nbsp;If Employee already exists then system will update that particular record  </div>
                  </Col>
                  <Col>
                    <div style={{ marginTop: '10px' }}>
                      <h9 style={{ fontFamily: "Sans-sarif", textAlign: "left", color: "black" }}>File&nbsp;<FontAwesomeIcon icon={faCloudUploadAlt} /></h9>
                    </div>
                    <input type="file" id="file" ref="fileUploader"
                      onChange={(event) => {
                        this.validate(event)
                      }}
                    />
                  </Col>
                </Form.Group>
                <div className='error-style'>{this.state.fileError}</div>
                <div className='d-flex justify-content-end'>
                  <myComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={"Upload"}
                    isGradient={true}
                    onBtnClick={() => {
                      if (this.state.allRight) {
                        this.setState({
                          isLoadingBtn: true
                        })
                        if (!this.state.isLoadingBtn) {
                          this.uploadEmployeeFile()
                        }
                      }
                      else {
                        this.setState({
                          isLoadingBtn: false,
                          fileError: "Select a file",
                        })
                      }
                    }
                    }
                  />
                </div>
              </Form>
            </Container>
          </Modal.Body>

          <ErrorPopupEmployee
            show={this.state.messageModal}
            onHide={() => this.handleErrorPopUpClose()}
            item={this.state.addEmployeeError}
          />
        </Modal>
      </>
    )
  }
}