import React, { Component } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components";
import utils from "../../utils";

export default class deactivateEmployee extends Component {
  constructor(props) {
    super(props)
    this.props = props;
    this.state = {
      isLoadingBtn: false,
      oldPlant: '',
      newPlant: "",
      showPopUp: false,
    }
  }

  async deactivate(id, activationType) {
    try {
      let object = {
        empCode: this.props.item.Employee_Id
      }
      let response
      activationType === utils.config.activeEmployee.DEACTIVATE ?
        response = await apiCall.deactivateEmployee(object)
        :
        response = await apiCall.activateEmployee(object)
      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        if (response.data.status) {
          this.setState({ isLoadingBtn: false, })
          activationType === utils.config.activeEmployee.DEACTIVATE ?
            toast.success("Employee is Deactivated")
            :
            toast.success("Employee is Activated")
          let { callBack2 } = this.props
          callBack2()
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isLoadingBtn: false, })
        }
      }
      else {
        this.setState({
          isLoadingBtn: false,
        })
      }
    }
    catch (e) {
      this.setState({
        isLoadingBtn: false,
      })
      utils.utilFunction.toastCall(e.response)
    }

  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    return (
      <>
        <myComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />
        <Modal
          backdrop="static"
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="forHeader">
            <Modal.Title id="contained-modal-title-vcenter" >
              {
                this.props.activationType === utils.config.activeEmployee.DEACTIVATE ?
                  <p className="forParagraph">Deactivate Employee </p>
                  :
                  <p className="forParagraph">Activate Employee </p>
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form>
                <div>
                  {
                    this.props.activationType === utils.config.activeEmployee.DEACTIVATE ?
                      <h5 className="messageLine">Are you sure you want to deactivate {this.props.item.employeeName} </h5>
                      :
                      <h5 className="messageLine">Are you sure you want to activate {this.props.item.employeeName} </h5>
                  }

                  <div className='d-flex justify-content-end'>
                    <myComponent.HeroButton
                      isBtnLoading={this.state.isLoadingBtn}
                      btnText={
                        this.props.activationType === utils.config.activeEmployee.DEACTIVATE ?
                          "Deactivate"
                          :
                          "Activate"
                      }
                      isGradient={true}
                      onBtnClick={() => {
                        this.setState({ isLoadingBtn: true, })
                        if (!this.state.isLoadingBtn) {

                          this.deactivate(this.props.item.Employee_Id, this.props.activationType)

                        }
                      }
                      }

                    />
                  </div>
                </div>
              </Form>
            </Container>
          </Modal.Body>



        </Modal>
      </>
    );
  }
}