import '@fortawesome/fontawesome-free/css/all.min.css';
import { faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import "mdbreact/dist/css/mdb.css";
import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import FadeLoader from "react-spinners/FadeLoader";
import actionComponents from "../../actionComponents/index";
import UploadEmployee from '../../actionComponents/uploadEmployee/uploadEmployee';
import UploadEmployeeEH from '../../actionComponents/uploadEmployeeErrorPopUp/uploadExcelsheet';
import apiCall from "../../apiCall";
import MyComponent from "../../components/index";
import "../../globalStyle.css";
import Sidebar from '../../Sidebar/Sidebar';
import utils from "../../utils/index";
import ManageEmployeesDatatable from "../employeesDataTable/employeeDataTable";
import './employees.css';

export default class employees extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadExcelSheet: false,
      uploadEmployeeModal: false,
      addEmployeeModal: false,
      plantDataList: [],
      deptDataList: [],
      sectionDataList: [],
      employeeList: [],
      categoryList: [],
      apiCallDone: false,
      filterByPlant: "",
      filterByDept: '',
      filterBySection: '',
      filterByStatus: '',
      filterByCat: '',
      filterByPlantLabel: "",
      filterByDeptLabel: "",
      filterBySectionLabel: "",
      filterByStatusLabel: "",
      filterByCatLabel: "",
      isLoadingBtn: false,
      isPageLoading: false,
      page: 1,
      search: "",
      show:false,
      newPlant:"",
      oldPlant:"",
    }
  }

  componentDidMount() {
    this.setState({ isPageLoading: true })
    this.getAppUserList(1)
    this.getPlantList()
    this.getDeptList()
    this.getSection()
    this.getCategory()
  }

  async getAppUserList(page) {
    try {
      let object = {
        page: page,
        search: this.state.search,
      }
      if (this.state.filterByPlant !== "") {
        object.plantId = this.state.filterByPlant
      }
      if (this.state.filterByDept !== "") {
        object.departmentId = this.state.filterByDept
      }
      if (this.state.filterByStatus !== "") {
        object.status = this.state.filterByStatus
      }
      if (this.state.filterBySection !== "") {
        object.sectionId = this.state.filterBySection
      }
      if (this.state.filterByCat !== "") {
        object.categoryId = this.state.filterByCat
      }

      let response = await apiCall.employeeList(object);
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          isPageLoading: false,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        this.setState({ isPageLoading: false, })
        if (response.data.status) {
          this.setState({
            employeeList: response.data.data,
            page: page,
          })
        }
        else {
          if (response.data.error.errorcode === 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem(utils.strings.localStorageItem.token)
            this.setState({ isPageLoading: false })
          }
        }
      }

      this.setState({
        isPageLoading: false,
        apiCallDone: true
      })
    }
    catch (e) {
      this.setState({
        isPageLoading: false,
        apiCallDone: true
      })
      utils.utilFunction.toastCall(e)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG, plantList, i;
        plantListOG = response.data.data
        plantList = this.state.plantDataList
        for (i = 0; i < plantListOG.length; i++) {
          plantList.push(
            {
              // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
              // 'value': i,
              // 'Plant_Id': plantListOG[i].Plant_Id,
              // 'Plant_Location': plantListOG[i].Plant_Location,
              // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

              'label': plantListOG[i].Name,
              'value':i,
              'Plant_Id': plantListOG[i].BRCCODE,
            })
        }
        this.setState({ plantDataList: plantList })

        return plantList
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getDeptList() {

    try {
      let response = await apiCall.getDepartment();
      if (response.data.status) {
        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].department_Name,
              // 'value': i,
              // 'Department_Id': deptListOG[i].Department_Id,
              // 'department_short': deptListOG[i].department_short,

              'label': deptListOG[i].Name,
              'value': i,
              'Department_Id': deptListOG[i].DPTID,
              'department_short': deptListOG[i].ShortName,
            })
        }
        this.setState({
          deptDataList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getSection() {
    try {
      let response = await apiCall.getSection();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {

        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].section_Name,
              // 'value': i,
              // 'Section_Id': deptListOG[i].Section_Id,

              'label': deptListOG[i].Name,
              'value': i,
              'Section_Id': deptListOG[i].SECID,
            })
        }
        this.setState({
          sectionDataList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getCategory() {
    try {
      let response = await apiCall.getCategory();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data && response.data.status) {

        let deptListOG, deptList, i;

        deptListOG = response.data.data
        deptList = []

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
            {
              // 'label': deptListOG[i].categoryName,
              // 'value': i,
              // 'Category_Id': deptListOG[i].Category_Id,

              'label': deptListOG[i].Name,
              'value': i,
              'Category_Id': deptListOG[i].CTGID,
            })
        }
        this.setState({
          categoryList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  handleUpload() {
    this.setState({ uploadExcelSheet: true })
  }

  handleUploadEmployee() {
    this.setState({ uploadEmployeeModal: true })
  }

  handleAddEmployee() {
    this.setState({ addEmployeeModal: true })
  }

  clearFilter() {
    this.setState({
      filterByPlant: "",
      filterByDept: '',
      filterBySection: '',
      filterByStatus: '',
    })
  }

  handleUploademployeeClose() { this.setState({ uploadEmployeeModal: false }) }

  handleUploadClose() { this.setState({ uploadExcelSheet: false }) }

  handleaddemployeeClose() { this.setState({ addEmployeeModal: false }) }

  async exportEmployee() {
    try {
      let object = [], obj, finalObj = ''

      if (this.state.filterByPlant !== "") {
        obj = { key: "plantId", value: this.state.filterByPlant }
        object.push(obj)
      }
      if (this.state.filterByDept !== "") {
        obj = { key: "departmentId", value: this.state.filterByDept }
        object.push(obj)
      }
      if (this.state.filterByStatus !== "") {
        obj = { key: "status", value: this.state.filterByStatus }
        object.push(obj)
      }
      if (this.state.filterBySection !== "") {
        let string = this.state.filterBySection
        let str = string.replace(/\s\s+/g, '');
        obj = { key: "sectionId", value: str }
        object.push(obj)
      }
      if (this.state.filterByCat !== "") {
        obj = { key: "categoryId", value: this.state.filterByCat }
        object.push(obj)
      }

      object.map((item, index) => {
        if (index !== 0) {
          finalObj = finalObj + "&" + item.key + "=" + item.value
        }
        else {
          finalObj = finalObj + "?" + item.key + "=" + item.value
        }
      })

      let response = await apiCall.exportEmployee(finalObj);

      if (response.permissionChanged) {
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      if (response.status === 200) {
        this.setState({ isLoadingBtn: false })
        let file = response.data
        var blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Employee_List.xlsx');
        this.setState({
          isLoadingBtn: false
        })
      }
      if (response.error_code === 409) {
        this.setState({ isLoadingBtn: false })
        if (response.error_message === "USER_ALREADY_EXIST") {
          utils.utilFunction.toastCall(utils.strings.errorMsg.uniqueUser)
        }
        else {
          utils.utilFunction.toastCall(response.error_message)
        }

        this.setState({ isLoadingBtn: false })
      }
      else if (response.data.error.errorcode === 1006) {
        utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
        localStorage.removeItem(utils.strings.localStorageItem.token)
        this.setState({ isLoadingBtn: false })
      }
    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e.response)
    }
  }

  tableSearch(val) {
    this.setState({
      search: val,
      employeeList: [],
    },
      () => this.getAppUserList()
    )
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />

    return (
      <div className='custom-container'>
        <MyComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />

        <UploadEmployeeEH
          show={this.state.uploadExcelSheet}
          onHide={() => this.handleUploadClose()}
          callBack={() => this.handleUploadClose()}
          callBack2={() => {
            this.setState({
              employeeList: []
            },
              () => this.getAppUserList()
            )
          }}
        />

        <UploadEmployee
          show={this.state.uploadEmployeeModal}
          onHide={() => this.handleUploademployeeClose()}
          callBack={() => this.handleUploademployeeClose()}
          callBack2={() => {
            this.setState
              ({ employeeList: [] })
            this.getAppUserList()
          }}
        />

        <actionComponents.AddEmployee
          show={this.state.addEmployeeModal}
          onHide={() => this.handleaddemployeeClose()}
          callBack={() => this.handleaddemployeeClose()}
          callBack2={() => {
            this.setState({ employeeList: [] })
            this.getAppUserList(1)
          }}
        />

        <MyComponent.Header />
        <div className='row-2'>
          <div className="sidebar-view">
            <div className='sidebar-static'>
              <Sidebar />
            </div>
          </div>
          <div className="content-view">
            <div className="sub-content-view">
              <Row className="heading">
                <Col>
                  <h4>{utils.strings.heading.mangageEmployees} </h4>
                </Col>
                <Col className="d-flex justify-content-end">
                  <MyComponent.HeroButton
                    isBtnLoading={this.state.isLoadingBtn}
                    btnText={utils.strings.buttonText.expXlsx}
                    isGradient={true}
                    onBtnClick={() => {
                      this.setState({ isLoadingBtn: true, })
                      if (!this.state.isLoadingBtn) {
                        this.exportEmployee()
                      }
                    }
                    }
                  />
                </Col>
              </Row>

              {/*<div className='verticaly-alligned'>*/}
              {/*  <FontAwesomeIcon icon={faPlus} title="Add Employee" onClick={() => this.handleAddEmployee()} className="favIcons" size="2x" />*/}
              {/*  &nbsp;&nbsp;*/}
              {/*        <span title="Add Employee" className="favIconsText" onClick={() => this.handleAddEmployee()}>{utils.strings.standaredCategories.employee}</span>*/}
              {/*  &nbsp;&nbsp;&nbsp;&nbsp;*/}
              {/*        <FontAwesomeIcon icon={faFileUpload} size="2x" title="Upload Employee Sheet" className="favIcons" onClick={() => this.handleUploadEmployee()} />*/}
              {/*  &nbsp;&nbsp;*/}
              {/*        <span className="favIconsText" title="Upload Employee Sheet" onClick={() => this.handleUploadEmployee()}>{utils.strings.standaredCategories.employeeS}</span>*/}
              {/*  &nbsp;&nbsp;&nbsp;&nbsp;*/}
              {/*        <FontAwesomeIcon icon={faFileUpload} size="2x" title="Upload Employee's Extra Hour" onClick={() => this.handleUpload()} className="favIcons" />*/}
              {/*  &nbsp;&nbsp;*/}
              {/*        <span className="favIconsText" title="Upload Employee's Extra Hour" onClick={() => this.handleUpload()}>{utils.strings.standaredCategories.extraHour}</span>*/}
              {/*</div>*/}

              <div className="favIconsText">{utils.strings.stringMiscellaneous.filterBy}</div>
              <Row className='vertical-center marginSelect'>
                {
                  localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin ?
                    <Col md={{ span: 2 }} className="filter-vertical-gap">
                      <Select
                        isClearable
                        placeholder="Plant"
                        value={this.state.filterByPlantLabel}
                        onChange={(val) => {
                          this.setState({
                            filterByPlantLabel: val === null ? "" : val,
                            filterByPlant: val === null ? "" : val.Plant_Id,
                            employeeList: [],
                            isPageLoading: true,
                          },
                            () => this.getAppUserList("")
                          );
                        }}
                        options={this.state.plantDataList}
                      />
                    </Col>
                    :
                    <div />
                }

                <Col md={{ span: 2 }} className="filter-vertical-gap">
                  <Select
                    placeholder={utils.strings.standaredCategories.department}
                    isClearable
                    value={this.state.filterByDeptLabel}
                    onChange={(val) => {
                      this.setState({
                        filterByDeptLabel: val === null ? "" : val,
                        filterByDept: val === null ? "" : val.Department_Id,
                        employeeList: [],
                        isPageLoading: true,
                      },
                        () => this.getAppUserList("")
                      );
                    }}
                    options={this.state.deptDataList}
                  />
                </Col>

                <Col md={{ span: 2 }} className="filter-vertical-gap">
                  <Select
                    isClearable
                    placeholder={utils.strings.standaredCategories.category}
                    value={this.state.filterByCatLabel}
                    onChange={(val) => {
                      this.setState({
                        filterByCatLabel: val === null ? "" : val,
                        filterByCat: val === null ? "" : val.Category_Id,
                        employeeList: [],
                        isPageLoading: true,
                      },
                        () => this.getAppUserList("")
                      );
                    }}
                    options={this.state.categoryList}
                  />
                </Col>

                <Col md={{ span: 2 }} className="filter-vertical-gap">
                  <Select
                    isClearable
                    placeholder={utils.strings.standaredCategories.section}
                    value={this.state.filterBySectionLabel}
                    onChange={(val) => {
                      this.setState({
                        filterBySectionLabel: val === null ? "" : val,
                        filterBySection: val === null ? "" : val.Section_Id,
                        employeeList: [],
                        isPageLoading: true,
                      },
                        () => this.getAppUserList("")
                      );
                    }}
                    options={this.state.sectionDataList}
                  />
                </Col>

                <Col md={{ span: 2 }} className="filter-vertical-gap">
                  <Select
                    isClearable
                    placeholder={utils.strings.standaredCategories.status}
                    value={this.state.filterByStatusLabel}
                    onChange={(val) => {
                      this.setState({
                        filterByStatusLabel: val === null ? "" : val,
                        filterByStatus: val === null ? "" : val.value,
                        employeeList: [],
                        isPageLoading: true,
                      },
                        () => this.getAppUserList("")
                      );
                    }}
                    options={utils.constants.optionStatus}
                  />
                </Col>

                <Col md={{ span: 2 }} className="filter-vertical-gap">
                  <Form.Control
                      className='search-dimention'
                      type="text"
                      placeholder="Search"
                      value={this.state.code}
                      onChange={(e) => this.tableSearch(e.target.value)}
                  />
                </Col>
              </Row>


              {/*<Row className="d-flex justify-content-end search-bg-adjust">*/}
              {/*  <Form.Control*/}
              {/*    className='search-dimention'*/}
              {/*    type="text"*/}
              {/*    placeholder="Search"*/}
              {/*    value={this.state.code}*/}
              {/*    onChange={(e) => this.tableSearch(e.target.value)}*/}
              {/*  />*/}
              {/*</Row>*/}


              <div className="gap1" />
              {
                (this.state.apiCallDone) ?
                  (this.state.employeeList).length !== 0 ?
                    (this.state.employeeList.employees).length !== 0 ?
                      <ManageEmployeesDatatable
                        item={this.state.employeeList}
                        currentPage={this.state.page}
                        callBack2={() => {
                          this.setState({ employeeList: [] })
                          this.getAppUserList(1)
                        }}
                        pageCallBack={(page) => {
                          this.setState({ employeeList: [] })
                          this.getAppUserList(page)
                        }}
                      />
                      :
                      <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                    : <div />
                  : <div />
              }
            </div>
          </div>
        </div>
        {
          this.state.isPageLoading ?
            <Col id="overlay" >
              <Col id="loader">
                <FadeLoader
                  size={"30"}
                  width={"10"}
                  radius={"8"}
                  color={"#08334D"}
                  loading={this.state.loading}
                />
              </Col>
            </Col>
            :
            <div />
        }
      </div>
    )
  }
}
