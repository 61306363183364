import '@fortawesome/fontawesome-free/css/all.min.css';
import { faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact";
import React, { Component } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import apiCall from '../../apiCall';
import "../../globalStyle.css";
import Sidebar from "../../Sidebar/Sidebar";
import utils from "../../utils/index";
import './header.css';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            isOpen: false,
            redirect: false,
            isSidebarOpen: false,
            profilePic: utils.images.placeholderImage,
            profileData: [],
            isPageLoading: false,
            plantDataList:[],
        }
    }

    componentDidMount() {
        this.profile()
        this.getPlantList()
    }

    async getPlantList() {
        try {
            let response = await apiCall.getPlantList();
            if (response.data.status) {
                let plantListOG, plantList, i;
                plantListOG = response.data.data
                plantList = []
                for (i = 0; i < plantListOG.length; i++) {
                    plantList.push(
                        {
                            'label': plantListOG[i].Name,
                            'value':i,
                            'Plant_Id': plantListOG[i].BRCCODE,
                        })
                }
                this.setState({
                    plantDataList: plantList
                })
            }
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }

    matchPlantDefaultList(plantId) {
        let data = this.state.plantDataList, i=0, object
        for (i; i < data.length; i++) {
            if (data[i].Plant_Id == plantId) {
                localStorage.setItem(utils.strings.localStorageItem.plantId, data[i].label);
                break;
            }
        }
    }

    async profile() {
        this.setState({ isPageLoading: true })
        try {
            let response = await apiCall.apiforProfile();
            if (response.permissionChanged) {
                this.setState({
                    showPopUp: true,
                    isPageLoading: false,
                    newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
                    oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
                })
            }
            else {
                this.setState({ isPageLoading: false })
                if (response.data) {
                    if (response.data.status) {
                        this.setState({
                            profileData: response.data.data,
                            profilePic: response.data.data.ProfilePic ? utils.config.baseURL + response.data.data.ProfilePic : this.state.profilePic,
                        })
                    }
                    else {
                        if (response.data.error.errorcode == 1006) {
                            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
                            localStorage.removeItem(utils.strings.localStorageItem.token)
                        }
                    }
                }
            }
        }
        catch (error) {
            this.setState({ isPageLoading: false })
            return error;
        }
    }

    async logout() {
        try {
            let response = await apiCall.logout();
            if (response.status) {
                localStorage.removeItem(utils.strings.localStorageItem.token);
                this.setState({ redirect: true })
            }
        }
        catch (e) {
            utils.utilFunction.toastCall(e.response)
        }
    }

    render() {
        if (!localStorage.getItem(utils.strings.localStorageItem.token))
            return <Redirect to={{ pathname: utils.config.pageURL.login }} />

        let adminType = localStorage.getItem(utils.strings.localStorageItem.adminType);
        let userName = localStorage.getItem(utils.strings.localStorageItem.userName);
        let plantName = ""
        let data = this.state.plantDataList, i=0, object
        let plantId = localStorage.getItem(utils.strings.localStorageItem.plantId)
        for (i; i < data.length; i++) {
            if (data[i].Plant_Id == plantId) {
                // localStorage.setItem(utils.strings.localStorageItem.plantId, data[i].label);
                plantName = data[i].label
                break;
            }
        }

        return (
            <Row className="header">
                <div className='menu-sidebar' style={{ display: this.state.isSidebarOpen ? 'flex' : 'none' }}>
                    <Sidebar />
                </div>
                <Col>
                    <div className="menu-logo">
                        <div className="menu">
                            <svg
                                onClick={() =>
                                    this.setState({
                                        isSidebarOpen: !this.state.isSidebarOpen
                                    })
                                }
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#07344d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                            &nbsp;&nbsp;
                        </div>
                        <div className='brand-logo'>
                            <Image alt="" src={utils.images.logo2} className="header-logo-style" />
                        </div>
                    </div>
                </Col>
                <Col className="d-flex justify-content-end">
                    <MDBDropdown>
                        <MDBDropdownToggle nav>
                            <div className="d-flex vertical-center">
                                <Image width="50px" height="50px" src={this.state.profilePic} className="image rounded-circle"></Image>
                                <div className="name-dropdown">
                                    <div className="vertical-center">
                                        <div>
                                            <span style={{ color: "black", textTransform: "none" }}>&nbsp;&nbsp;{utils.strings.stringMiscellaneous.hi}{userName}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#52A4D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                                        </div>
                                    </div>
                                    &nbsp;&nbsp;
                                    {
                                        localStorage.getItem(utils.strings.localStorageItem.adminType) !== utils.strings.adminType.superAdmin ?
                                            // <>{utils.utilFunction.getPlantName(localStorage.getItem(utils.strings.localStorageItem.plantId))}</>
                                            <>{plantName}</>
                                            // {"Plant - "+localStorage.getItem(utils.strings.localStorageItem.plantId)}
                                            :
                                            <>{adminType}</>
                                    }
                                </div>
                            </div>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu className="dropdown-default">
                            <p> {utils.strings.stringMiscellaneous.typeSignedIn}{adminType}</p>
                            <MDBDropdownItem href="#profile">
                                <FontAwesomeIcon className="forProfile common-dropdown-item" icon={faUser} />{utils.strings.signUpOption.profile}
                            </MDBDropdownItem>
                            <MDBDropdownItem onClick={() => this.logout()} >
                                <FontAwesomeIcon className="forSpaceColor common-dropdown-item" icon={faPowerOff} />{utils.strings.signUpOption.logout}
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </Col>
                {
                    this.state.isPageLoading ?
                        <Col id="overlay" >
                            <Col id="loader">
                                <FadeLoader
                                    size={"30"}
                                    width={"10"}
                                    radius={"8"}
                                    color={"#08334D"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Col>
                        :
                        <div />
                }
            </Row>
        )
    }


    redirect() {
        if (this.state.redirect)
            return <Redirect to={{ pathname: '/' }} />
    }

    onBtnClick() {
        if (this.props.onBtnClick !== undefined) {
            this.props.onBtnClick()
        }
    }
}
