import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import actionComponents from "../../actionComponents/index";
import apiCall from '../../apiCall';
import MyComponent from "../../components/index";
import "../../globalStyle.css";
import Sidebar from '../../Sidebar/Sidebar';
import utils from "../../utils/index";
import AdminDataTable from "../adminDataTable/adminDataTable";
import './admin.css';

export default class admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      createSubAdminModal: false,
      subAdminList: [],
      apiCallDone: false,
      showPopUp: false,
      newPlant: "",
      oldPlant: "",
      isPageLoading: false,
      refreshHeader: false,
    }
  }

  componentDidMount() {
    this.getSubAdminList()
  }

  async getSubAdminList() {
    this.setState({ isPageLoading: true })
    try {
      let response = await apiCall.adminList();
      if (response.permissionChanged) {
        this.setState({
          isPageLoading: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data) {
        this.setState({ isPageLoading: false })
        if (response.data.status) {
          this.setState({
            apiCallDone: true,
            subAdminList: response.data.data
          })
        }
        else {
          if (response.data.error.errorcode === 1006) {
            utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
            localStorage.removeItem(utils.strings.localStorageItem.token)
            this.setState({ isPageLoading: false })
          }
        }
      }
    }
    catch (e) {
      this.setState({ isPageLoading: false })
      utils.utilFunction.toastCall(e)
    }
  }

  async logout() {
    try {
      let response = await apiCall.logout();
      if (response.status) {
        localStorage.removeItem(utils.strings.localStorageItem.token);
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  createSubAdminModalClose(data) {this.setState({ createSubAdminModal: data })}

  render() {
    if (localStorage.getItem(utils.strings.localStorageItem.adminType) === utils.strings.adminType.superAdmin) {
      if (!localStorage.getItem(utils.strings.localStorageItem.token) || localStorage.getItem(utils.strings.localStorageItem.token) === null)
        return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    }
    else
      return <Redirect to={{ pathname: utils.config.pageURL.dashboard }} />

    return (
      <div className='custom-container'>
        <MyComponent.ReLoginPopUp
          show={this.state.showPopUp}
          newPlant={this.state.newPlant}
          oldPlant={this.state.oldPlant}
        />

        <actionComponents.CreateSubAdmin
          show={this.state.createSubAdminModal}
          onHide={() => this.createSubAdminModalClose(false)}
          callBack={(data) => this.createSubAdminModalClose(data)}
          callBack2={() => {
            this.setState({ subAdminList: [], isPageLoading: true })
            this.getSubAdminList()
          }}
        />

        {
          this.state.refreshHeader ?
            <MyComponent.Header />
            :
            <div>
              <MyComponent.Header />
            </div>
        }
        <div className='row-2'>
          <div className="sidebar-view">
            <div className='sidebar-static'>
              <Sidebar />
            </div>
          </div>
          <div className="content-view">
            <div className="sub-content-view">
              <Row className="heading">
                <Col>
                  <h4>{utils.strings.heading.admin} </h4>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button bsPrefix="exportEmployee" onClick={() => { this.setState({ createSubAdminModal: true }) }}>{utils.strings.buttonText.createSubAdmin}</Button>
                </Col>
              </Row>

              <div className="gap1" />
              {
                (this.state.apiCallDone) ?
                  (this.state.subAdminList).length > 0 ?
                    <AdminDataTable
                      dataListOG={this.state.subAdminList}
                      callBack={() => {
                        this.setState({
                          refreshHeader: !this.state.refreshHeader,
                          subAdminList: [],
                        })
                        this.getSubAdminList()
                      }
                      }
                    />
                    :
                    <div className="placeHolder-view">{utils.strings.stringMiscellaneous.noData}</div>
                  :
                  <div />
              }
            </div>
          </div>
        </div>
        {
          this.state.isPageLoading ?
            <Col id="overlay" >
              <Col id="loader">
                <FadeLoader
                  size={"30"}
                  width={"10"}
                  radius={"8"}
                  color={"#08334D"}
                  loading={this.state.loading}
                />
              </Col>
            </Col>
            :
            <div />
        }
      </div>
    )
  }
}