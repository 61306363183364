import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import "../../globalStyle.css";
import './heroButton.css';

export default class HeroButton extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    static propTypes = {
        isBtnLoading: PropTypes.bool,
        explicitHeight: PropTypes.any,
        explicitWidth: PropTypes.any,
        onBtnClick: PropTypes.func,
        btnText: PropTypes.string.isRequired,
        isGradient: PropTypes.bool,

    }

    static defaultProps = {
        isBtnLoading: false,
        btnText: 'Click',
        explicitHeight: 0,
        explicitWidth: 0,
        isGradient: true,
    }

    render() {
        return (
            <>
                <div id={"login-btn"} onClick={this.onBtnClick.bind(this)}
                    style={{
                        height: this.props.explicitHeight !== 0 ? this.props.explicitHeight : null,
                        width: this.props.explicitWidth !== 0 ? this.props.explicitWidth : null
                    }}
                    className={
                        classNames(
                            'button-common',
                            this.props.isGradient ? 'gradientBg' : 'normalBg',
                            )
                    }>
                    {
                        this.props.isBtnLoading ?
                            <div className='loader' style={{ marginRight: '6px' }}/>
                            :
                            <div />
                    }

                    {
                        this.props.loading ?
                            <i class="fa fa-circle-o-notch fa-spin" />
                            :
                            <div />
                    }
                    {this.props.btnText}
                </div>
            </>
        )
    }

    onBtnClick() {
        if (this.props.onBtnClick !== undefined) {
            this.props.onBtnClick()
        }
    }
}