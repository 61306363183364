import React, { Component } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import apiCall from "../../apiCall";
import myComponent from "../../components/index";
import "../../globalStyle.css";
import utils from "../../utils/index";
import "../createSubAdmin/createSubAdmin.css";
import './addEmployee.css';

export default class AddEmployee extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      empCode: '',
      empCodeError: '',
      name: '',
      nameError: '',
      category: '',
      categoryError: '',
      plantname: '',
      selectedDesignationOption: '',
      selectedOptionsCategory: '',
      selectedOptionsCategoryError: '',
      selectedPlant: '',
      selectedPlantError: '',
      selectedDept: '',
      selectedDeptError: '',
      selectedShift: '',
      selectedShiftError: '',
      selectedSection: "",
      selectedSectionError: "",
      selectedGender: "",
      selectedGenderError: "",

      dob: '',
      joinDate: '',
      dobError: '',
      joinDateError: '',

      errorMsg: {
        name: '',
        category: '',
        plantname: '',
      },

      roleList: [],
      plantDataList: [],
      deptList: [],
      sectionList: [],
      shiftList: [],
      categoryList: [],
      designationList: [],

      isLoadingBtn: false,

      showPopUp: false,
      newPlant: "",
      oldPlant: "",


      defaultPlantID: '',
      defaultPlant: "",
    }
  }

  componentDidMount() {
    if (localStorage.getItem("adminType") !== "SuperAdmin") {
      this.setState({
        // defaultPlantID: utils.utilFunction.getPlantName(localStorage.getItem("plantId")),
        // selectedPlant: { value: this.state.defaultPlantID, label: this.state.defaultPlantID, Plant_Id: localStorage.getItem("plantId") },

        defaultPlantID: "Plant - "+localStorage.getItem("plantId"),
        selectedPlant: { value: this.state.defaultPlantID, label: this.state.defaultPlantID, Plant_Id: localStorage.getItem("plantId") },
      })
    }

    this.getRoleList()
    this.getPlantList()
    this.getSection()
    this.getDeptList()
    this.getShift()
    this.getCategory()
    this.getDesignation()
  }

  async getRoleList() {
    try {
      let response = await apiCall.getRole();
      if (response.data.status) {
        let roleListOG, roleList, i;

        roleListOG = response.data.data
        roleList = []

        if (localStorage.getItem("adminType") === "SuperAdmin") {
          i = 0
        }
        else {
          i = 1
        }

        for (i; i < roleListOG.length; i++) {
          roleList.push(
              {
                'label': roleListOG[i].RoleName,
                'value': i,
                'Role_id': roleListOG[i].Role_id,
              })
        }

        this.setState({
          roleList: roleList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getPlantList() {
    try {
      let response = await apiCall.getPlantList();
      if (response.data.status) {
        let plantListOG = response.data.data, plantList = [], i= 0 ;

        for (i ; i < plantListOG.length; i++) {
          plantList.push(
              {
                // 'label': utils.utilFunction.getPlantName(plantListOG[i].Plant_Id),
                // 'value': i,
                // 'Plant_Id': plantListOG[i].Plant_Id,
                // 'Plant_Location': plantListOG[i].Plant_Location,
                // 'Plant_ShortAddress': plantListOG[i].Plant_ShortAddress,

                'label': plantListOG[i].Name,
                'value':i,
                'Plant_Id': plantListOG[i].BRCCODE,
              })
        }
        this.setState({
          plantDataList: plantList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getDeptList() {

    try {
      let response = await apiCall.getDepartment();
      if (response.data.status) {
        let deptListOG = response.data.data, deptList = [], i = 0;

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
              {
                // 'label': deptListOG[i].department_Name,
                // 'value': i,
                // 'Department_Id': deptListOG[i].Department_Id,
                // 'department_short': deptListOG[i].department_short,

                'label': deptListOG[i].Name,
                'value': i,
                'Department_Id': deptListOG[i].DPTID,
                'department_short': deptListOG[i].ShortName,
              })
        }
        this.setState({
          deptList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  async getSection() {
    try {
      let response = await apiCall.getSection();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {

        let deptListOG = response.data.data, deptList = [], i=0;

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
              {
                // 'label': deptListOG[i].section_Name,
                // 'value': i,
                // 'Section_Id': deptListOG[i].Section_Id,

                'label': deptListOG[i].Name,
                'value': i,
                'Section_Id': deptListOG[i].SECID,
              })
        }


        this.setState({
          sectionList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getShift() {
    try {
      let object = {
        // plantId: localStorage.getItem("adminType") === "SuperAdmin" ? val.Plant_Id : localStorage.getItem("plantId")
      }
      let response = await apiCall.getShift(object);

      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data.status) {
        let shiftListOG = response.data.data, shiftList= [], i=0;

        for (i = 0; i < shiftListOG.length; i++) {
          shiftList.push(
              {
                // 'label': shiftListOG[i].Shift,
                // 'value': i,
                // 'startTime': shiftListOG[i].startTime,
                // 'endTime': shiftListOG[i].endTime,

                label: shiftListOG[i].SFTID,
                value: i,
                startTime: shiftListOG[i].SFTSTTime,
                endTime: shiftListOG[i].SFTEDTime,
              })
        }


        this.setState({
          shiftList: shiftList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getCategory() {
    try {
      let response = await apiCall.getCategory();
      if (response.permissionChanged) {
        this.setState({
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      else if (response.data && response.data.status) {

        let deptListOG = response.data.data, deptList= [], i=0;

        for (i = 0; i < deptListOG.length; i++) {
          deptList.push(
              {
                // 'label': deptListOG[i].categoryName,
                // 'value': i,
                // 'Category_Id': deptListOG[i].Category_Id,

                'label': deptListOG[i].Name,
                'value': i,
                'Category_Id': deptListOG[i].CTGID,
              })
        }


        this.setState({
          categoryList: deptList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e)
    }
  }

  async getDesignation() {
    try {
      let response = await apiCall.getDesignation();
      if (response.data.status) {

        let designationListOG, designationList, i;

        designationListOG = response.data.data
        designationList = []
        i = 0
        for (i; i < designationListOG.length; i++) {
          designationList.push(
              {
                // 'label': designationListOG[i].designationName,
                // 'value': i,
                // 'Designation_Id': designationListOG[i].Designation_Id,

                'label': designationListOG[i].Name,
                'value': i,
                'Designation_Id': designationListOG[i].DSGID,

              })
        }

        this.setState({
          designationList: designationList
        })
      }
    }
    catch (e) {
      utils.utilFunction.toastCall(e.response)
    }
  }

  updateEmpCode(empCode) {
    let res = utils.validate.validateName(empCode.target.value)
    if (!res.status) {
      this.setState({
        empCodeError: "Please enter employee code"
      })
    }
    else {
      this.setState({ empCodeError: "" })
    }

    this.setState({ empCode: empCode.target.value })

  }

  updateName(name) {
    let res = utils.validate.validateName(name.target.value)
    if (!res.status) {
      this.setState({
        nameError: "Please enter employee name"
      })
    }
    else {
      this.setState({ nameError: "" })
    }
    this.setState({ name: name.target.value })
  }

  handleDesignationChange = selectedDesignationOption => {
    this.setState({ selectedDesignationOption });
  }

  validateForm() {

    let nameValidate, empCodevalidate, selectedOptionsCategoryStat, selectedPlantStat, selectedDeptStat, selectedShiftStat, startDateStat, startDate1Stat, selectedGenderStat
    if (this.state.dob === "") {
      startDateStat = false
      this.setState({ dobError: 'Select birth date' })
    }
    else {
      startDateStat = true
      this.setState({ dobError: '' })
    }

    if (this.state.joinDate === "") {
      startDate1Stat = false
      this.setState({ joinDateError: 'Select joining date' })
    }
    else {
      startDate1Stat = true
      this.setState({ joinDateError: '' })
    }

    nameValidate = utils.validate.validateName(this.state.name)
    empCodevalidate = utils.validate.validateName(this.state.empCode)
    if (nameValidate.status)
      this.setState({ nameError: "" })
    else
      this.setState({ nameError: "Please enter employee name" })

    if (empCodevalidate.status)
      this.setState({ empCodeError: "" })
    else
      this.setState({ empCodeError: "Please enter employee code" })


    if ((this.state.selectedOptionsCategory).length === 0) {
      selectedOptionsCategoryStat = false
      this.setState({ selectedOptionsCategoryError: 'Select a category' })
    }
    else {
      selectedOptionsCategoryStat = true
      this.setState({ selectedOptionsCategoryError: '' })
    }

    if ((this.state.selectedPlant).length === 0) {
      selectedPlantStat = false
      this.setState({ selectedPlantError: 'Select a Plant' })
    }
    else {
      selectedPlantStat = true
      this.setState({ selectedPlantError: '' })
    }

    if ((this.state.selectedDept).length === 0) {
      selectedDeptStat = false
      this.setState({ selectedDeptError: 'Select a Dept' })
    }
    else {
      selectedDeptStat = true
      this.setState({ selectedDeptError: '' })
    }

    if ((this.state.selectedShift).length === 0) {
      selectedShiftStat = false
      this.setState({ selectedShiftError: 'Select a Shift' })
    }
    else {
      selectedShiftStat = true
      this.setState({ selectedShiftError: '' })
    }

    if ((this.state.selectedGender).length === 0) {
      selectedGenderStat = false
      this.setState({ selectedGenderError: 'Select a Gender' })
    }
    else {
      selectedGenderStat = true
      this.setState({ selectedGenderError: '' })
    }

    if (nameValidate.status && empCodevalidate.status && selectedOptionsCategoryStat && selectedPlantStat && selectedDeptStat && selectedShiftStat && startDateStat && startDate1Stat && selectedGenderStat) {
      return true;
    }
    else {
      return false;
    }
  }

  async addEmployee() {
    let { callBack, callBack2 } = this.props
    try {

      let object = {
        empCode: this.state.empCode,
        name: this.state.name,
        dateOfBirth: this.state.dob,
        dateOfJoin: this.state.joinDate,
        categoryId: this.state.selectedOptionsCategory.Category_Id,
        gender: this.state.selectedGender.value,
        plantId: this.state.selectedPlant.Plant_Id,
        departmentId: this.state.selectedDept.Department_Id,
        shiftId: this.state.selectedShift.label,
        designationId: this.state.selectedDesignationOption.Designation_Id,
        sectionId: this.state.selectedSection.Section_Id
      }
      let response = await apiCall.addEmployee(object);
      if (response.permissionChanged) {
        callBack()
        this.setState({
          isLoadingBtn: false,
          showPopUp: true,
          newPlant: utils.utilFunction.getPlantName(response.newPlantDetails[0].Plant_Id),
          oldPlant: utils.utilFunction.getPlantName(response.oldPlantDetails[0].Plant_Id),
        })
      }
      if (response.data) {
        if (response.data.status) {
          this.setState({ isLoadingBtn: false })
          this.handleClose()
          callBack2()
          toast.success("Employee added")
        }
        else if (response.data.error.errorcode === 1006) {
          utils.utilFunction.toastCall(utils.strings.sessionMsg.sesExpired)
          localStorage.removeItem('token')
          this.setState({ isLoadingBtn: false })
        }
      }
      if (response.error_code === 409) {
        if (response.error_message === "USER_ALREADY_EXIST") {
          utils.utilFunction.toastCall(utils.strings.errorMsg.uniqueUser)
        }
        else {
          utils.utilFunction.toastCall(response.error_message)
        }

        this.setState({ isLoadingBtn: false })
      }
    }
    catch (e) {
      this.setState({ isLoadingBtn: false })
      utils.utilFunction.toastCall(e.response)
    }

  }

  handleSubmit(event) {
    if (this.validateForm()) {
      this.addEmployee()
    }
    else {
      this.setState({ isLoadingBtn: false })
    }
  }

  reset() {
    this.setState({
      name: "",
      nameError: "",
      empCode: "",
      empCodeError: '',
      selectedOptionsCategory: '',
      selectedOptionsCategoryError: '',
      selectedPlant: "",
      selectedPlantError: '',
      selectedDept: "",
      selectedDeptError: '',
      selectedSection: '',
      selectedShift: '',
      selectedShiftError: '',
      dob: "",
      joinDate: '',
      dobError: "",
      joinDateError: '',
      selectedGender: "",
      selectedGenderError: "",
    })
  }

  handleClose() {
    let { callBack } = this.props
    this.reset()
    callBack()
  }

  render() {
    if (!localStorage.getItem(utils.strings.localStorageItem.token))
      return <Redirect to={{ pathname: utils.config.pageURL.login }} />
    let min = new Date(new Date().getMonth() + '/' + new Date().getDate() + '/' + (new Date().getFullYear() - 18))
    return (
        <>
          <myComponent.ReLoginPopUp
              show={this.state.showPopUp}
              newPlant={this.state.newPlant}
              oldPlant={this.state.oldPlant}
          />
          <Modal
              backdrop="static"
              {...this.props}
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
            <Modal.Header closeButton
                          onHide={() => this.handleClose()}
                          className="forHeader">
              <Modal.Title id="contained-modal-title-vcenter" >
                <p className="forParagraph">{utils.strings.standaredCategories.employee}</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Form >
                  <Form.Group as={Row} controlId="formHorizontalName">
                    <Col >
                      <Form.Label>{utils.strings.inputFeild.empCode}</Form.Label>
                      <Form.Control type="text" name="empCode" placeholder="Enter Employee Code*"
                                    value={this.state.empCode}
                                    onChange={(e) => {
                                      let re = /^\d*$/;
                                      if (re.test(e.target.value)) {
                                        this.updateEmpCode(e)
                                      }
                                    }}
                      />
                      <div className='error-style'>{this.state.empCodeError}</div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalName">
                    <Col>
                      <Form.Label>{utils.strings.inputFeild.empName}</Form.Label>
                      <Form.Control type="text" name="firstname" placeholder="Enter Employee Name*"
                                    onChange={(e) => this.updateName(e)} />
                      <div className='error-style'>{this.state.nameError}</div>
                    </Col>
                    <Col>
                      <Form.Label>{utils.strings.inputFeild.gender}</Form.Label>
                      <Select
                          placeholder={utils.strings.inputFeild.selectGender}
                          onChange={(val) => { this.setState({ selectedGender: val }) }}
                          options={utils.constants.gender}
                      />
                      <div className='error-style'>{this.state.selectedGenderError}</div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalDesignation">
                    <Col>
                      <Form.Label> {utils.strings.inputFeild.designation}</Form.Label>
                      <Select
                          placeholder={utils.strings.inputFeild.selectDesignation}
                          onChange={(val) => { this.setState({ selectedDesignationOption: val }) }}
                          options={this.state.designationList}
                      />
                    </Col>
                    <Col>
                      <Form.Label>{utils.strings.inputFeild.category}</Form.Label>
                      <Select
                          placeholder={utils.strings.inputFeild.selectCategory}
                          onChange={(val) => {
                            this.setState({ selectedOptionsCategory: val })
                          }}
                          options={this.state.categoryList}
                      />
                      <div className='error-style'>{this.state.selectedOptionsCategoryError}</div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalPlantName">
                    <Col>
                      <Form.Label>{utils.strings.inputFeild.plantName}</Form.Label>
                      {
                        localStorage.getItem("adminType") === "SuperAdmin" ?
                            <Select
                                placeholder="Select Plant"
                                onChange={(val) => {
                                  // this.getShift(val)
                                  this.setState({
                                    selectedPlant: val,
                                  });
                                }}
                                options={this.state.plantDataList}
                            />
                            :
                            <Select
                                placeholder="Select Plant"
                                defaultValue={[{ value: this.state.defaultPlantID, label: this.state.defaultPlantID, Plant_Id: localStorage.getItem("plantId") }]}
                                isDisabled={true}
                            />
                      }
                      <div className='error-style'>{this.state.selectedPlantError}</div>
                    </Col>
                    <Col>
                      <Form.Label>Department</Form.Label>
                      <Select
                          placeholder="Select Deptartment*"
                          onChange={(val) => this.setState({ selectedDept: val })}
                          options={this.state.deptList}
                      />
                      <div className='error-style'>{this.state.selectedDeptError}</div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalSection">
                    <Col >
                      <Form.Label>Section</Form.Label>
                      <Select
                          placeholder="Select Section"
                          onChange={(val) => this.setState({ selectedSection: val })}
                          options={this.state.sectionList}
                      />
                      <div className='error-style'>{this.state.selectedSectionError}</div>
                    </Col>
                    <Col>
                      <Form.Label>Shift</Form.Label>
                      <Select
                          placeholder="Select Shift*"
                          onChange={(val) => this.setState({ selectedShift: val })}
                          options={this.state.shiftList}
                      />
                      <div className='error-style'>{this.state.selectedShiftError}</div>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalImages">
                    <Col md={{ span: 6 }} >
                      <Form.Label>Date of Birth</Form.Label>
                      <DatePicker
                          style={{ width: "300px" }}
                          className={'form-control datePicEditEmployee'}
                          popperPlacement="top-end"
                          selected={this.state.dob}
                          maxDate={min}
                          onChange={(date) => this.setState({
                            dob: date
                          })}
                          dateFormat="d MMMM, yyyy "
                          placeholderText="Select date of birth"
                      />
                      <div className='error-style'>{this.state.dobError}</div>
                    </Col>
                    <Col md={{ span: 6 }} >
                      <Form.Label>Date of Joining</Form.Label>
                      <DatePicker
                          className={'form-control datePicEditEmployee'}
                          popperPlacement="top-end"
                          selected={this.state.joinDate}
                          maxDate={new Date()}
                          onChange={(date) => this.setState({
                            joinDate: date
                          })}
                          dateFormat="d MMMM, yyyy "
                          placeholderText="Select date of joining"
                      />
                      <div className='error-style'>{this.state.joinDateError}</div>
                    </Col>
                  </Form.Group>
                  <div className='d-flex justify-content-end'>
                    <myComponent.HeroButton
                        isBtnLoading={this.state.isLoadingBtn}
                        btnText={"Add Employee"}
                        isGradient={true}
                        onBtnClick={() => {
                          this.setState({ isLoadingBtn: true, })
                          if (!this.state.isLoadingBtn) {
                            this.handleSubmit()
                          }
                        }}
                    />
                  </div>
                </Form>
              </Container>
            </Modal.Body>
          </Modal>
        </>
    )
  }
}
