import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import MyComponent from "../../components/index";

export default class policyModal extends Component {

    constructor(props) {
        super(props)
        this.props = props;
        this.state = {
            show: "false",
            newPlant: "",
            oldPlant: "",
        }
    }

    render() {
        return (
            <>
                <MyComponent.ReLoginPopUp
                    show={this.state.showPopUp}
                    newPlant={this.state.newPlant}
                    oldPlant={this.state.oldPlant}
                />
                <Modal
                    backdrop="static"
                    {...this.props}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton className="forHeader" >
                        <Modal.Title id="contained-modal-title-vcenter" >
                            <p className="forParagraph">Policy Configuration</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                </Modal>
            </>
        )
    }

}