import React, { Component } from 'react';
import { Container, Modal, Table } from 'react-bootstrap';
import "../appUserErrorPopUp/errorPopup.css";

export default class errorPopup extends Component {
  constructor(props) {
    super(props)
    this.props = props;
  }

  renderError(item) {
    return (
      <tr>
        <td style={{ textAlign: "center" }}>{item.row}</td>
        <td>{item.message}</td>
      </tr>
    )
  }

  render() {
    let itemArray = this.props.item
    let item = itemArray === null ? [] : this.props.item.errors
    return (
      <Modal
        backdrop="static"
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton
          className="forHeader"
        >
          <Modal.Title
            style={{ color: "white" }}
          >
            Error Log for Employee Extra hour
      </Modal.Title>
        </Modal.Header>
        <Modal.Body className='error-body-dimention' >
          <Container>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}> Row Number</th>
                  <th style={{ textAlign: "center" }}>Error Message</th>
                </tr>
              </thead>
              <tbody style={{ color: "red" }}>
                {
                  item.map((itemVal, index) =>
                    this.renderError(itemVal, index)
                  )
                }
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
      </Modal>

    )
  }

}